import invariant from "invariant";
import DescriptionList from "../../../components/DescriptionList";
import { Topic } from "../../../queries";

interface TopicStatsListProps {
  topic: Topic;
}

export default function TopicStatsList({ topic }: TopicStatsListProps) {
  invariant(topic.stats !== null, "Topic must have stats");

  return (
    <DescriptionList
      items={[
        { key: "ID", value: topic.id },
        { key: "Message Type", value: orDash(topic.messageType) },
        { key: "Message Count", value: topic.stats.messageCount },
        { key: "Timestamp Min", value: orDash(topic.stats.timestamp.min) },
        { key: "Timestamp Max", value: orDash(topic.stats.timestamp.max) },
        { key: "Timestamp Mean", value: orDash(topic.stats.timestamp.mean) },
        {
          key: "Timestamp Delta Min",
          value: orDash(topic.stats.timestampDelta.min),
        },
        {
          key: "Timestamp Delta Max",
          value: orDash(topic.stats.timestampDelta.max),
        },
        {
          key: "Timestamp Delta Mean",
          value: orDash(topic.stats.timestampDelta.mean),
        },
        {
          key: "Timestamp Delta Std",
          value: orDash(topic.stats.timestampDelta.stdDev),
        },
        { key: "Offset Min", value: orDash(topic.stats.offset.min) },
        { key: "Offset Max", value: orDash(topic.stats.offset.max) },
        { key: "Offset Mean", value: orDash(topic.stats.offset.mean) },
        { key: "Offset Delta Min", value: orDash(topic.stats.offsetDelta.min) },
        { key: "Offset Delta Max", value: orDash(topic.stats.offsetDelta.max) },
        {
          key: "Offset Delta Mean",
          value: orDash(topic.stats.offsetDelta.mean),
        },
        {
          key: "Offset Delta Std",
          value: orDash(topic.stats.offsetDelta.stdDev),
        },
        {
          key: "Data Length Total",
          value: orDash(topic.stats.dataLength.total),
        },
        { key: "Data Length Min", value: orDash(topic.stats.dataLength.min) },
        { key: "Data Length Max", value: orDash(topic.stats.dataLength.max) },
        { key: "Data Length Mean", value: orDash(topic.stats.dataLength.mean) },
        {
          key: "Data Length Std",
          value: orDash(topic.stats.dataLength.stdDev),
        },
      ]}
      layout="stacked"
    />
  );
}

function orDash(value: any) {
  return value ?? "-";
}
