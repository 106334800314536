import { Topic } from "../../../queries";

export function supportsImage(messageType: Topic["messageType"]) {
  return ["sensor_msgs/Image", "sensor_msgs/CompressedImage"].includes(
    messageType as any
  );
}

export function supportsMap(messageType: Topic["messageType"]) {
  return messageType === "sensor_msgs/NavSatFix";
}
