import { secondsToMilliseconds } from "date-fns";
import {
  RefreshTokenResponse1Data,
  Topic as ApiTopic,
  TopicDetails,
} from "racer-openapi-ts-sdk";
import { FromApi, RefreshedTokens, Topic } from "./types";

// Common selectors that are used in multiple files

export function topicFromApi({
  data,
}: FromApi<ApiTopic | TopicDetails>): Topic {
  let stats: Topic["stats"] = null;
  if ("stats" in data) {
    stats = {
      messageCount: data.stats.message_count,
      timestamp: {
        mean: data.stats.timestamp_mean,
        min: data.stats.timestamp_min,
        max: data.stats.timestamp_max,
      },
      timestampDelta: {
        mean: data.stats.timestamp_delta_mean,
        min: data.stats.timestamp_delta_min,
        max: data.stats.timestamp_delta_max,
        stdDev: data.stats.timestamp_delta_std,
      },
      offset: {
        mean: data.stats.offset_mean,
        min: data.stats.offset_min,
        max: data.stats.offset_max,
      },
      offsetDelta: {
        mean: data.stats.offset_delta_mean,
        min: data.stats.offset_delta_min,
        max: data.stats.offset_delta_max,
        stdDev: data.stats.offset_delta_std,
      },
      dataLength: {
        total: data.stats.data_length_total,
        mean: data.stats.data_length_mean,
        min: data.stats.data_length_min,
        max: data.stats.data_length_max,
        stdDev: data.stats.data_length_std,
      },
    };
  }

  return {
    id: data.id,
    logId: data.log_id,
    name: data.name,
    messageType: data.message_type_name,
    stats,
  };
}

export function refreshedTokensFromApi({
  data,
}: FromApi<RefreshTokenResponse1Data>): RefreshedTokens {
  return {
    idToken: data.id_token,
    accessToken: data.access_token,
    expiresIn: secondsToMilliseconds(Number(data.expires_in)),
  };
}
