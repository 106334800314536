import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  IconButton,
  MenuItem,
  Stack,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from "@mui/material";

export function Pagination({
  page,
  pageSize,
  pageSizeOptions,
  totalCount,
  onChange,
}) {
  function handlePageSizeChange(e) {
    onChange({ pageSize: e.target.value });
  }

  const showingLowerBound = page * pageSize + 1;
  const showingUpperBound = Math.min((page + 1) * pageSize, totalCount);

  return (
    <Stack direction="row" spacing={2} alignItems="center" my={2}>
      <MuiTextField
        sx={{ width: "15ch" }}
        id="page-size-options"
        label="Results per page"
        size="small"
        select
        value={pageSize}
        onChange={handlePageSizeChange}
      >
        {pageSizeOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </MuiTextField>
      <Typography>
        Showing {showingLowerBound} - {showingUpperBound} of {totalCount}
      </Typography>
      <Tooltip title="Previous page">
        <span>
          <IconButton
            aria-label="previous page"
            disabled={page === 0}
            size="small"
            onClick={() => onChange({ page: page - 1 })}
          >
            <ArrowBackIos />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Next page">
        <span>
          <IconButton
            aria-label="next page"
            disabled={showingUpperBound === totalCount}
            size="small"
            onClick={() => onChange({ page: page + 1 })}
          >
            <ArrowForwardIos />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  );
}
