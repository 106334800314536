import React from "react";
import DescriptionList from "../../../components/DescriptionList";
import TimeRange from "../../../components/labels/TimeRange";
import Tag from "../../../components/tags/Tag";

export default function LabelDetailView({ label }) {
  function tagForLabel(label) {
    return (
      <Tag
        type={label.tag.type}
        name={label.tag.name}
        ChipProps={{
          component: "span",
          size: "small",
        }}
      />
    );
  }

  return (
    <DescriptionList
      items={[
        {
          key: "Time",
          value: <TimeRange label={label} asLink keepExistingSearch />,
        },
        {
          key: "Tag",
          value: label.tag ? tagForLabel(label) : "-",
        },
        { key: "Description", value: label.description ?? "-" },
        {
          key: "Created at",
          value: label.createdAt.toUTCString().replace("GMT", "UTC"),
        },
      ]}
      layout="stacked"
    />
  );
}
