import React from "react";
import { Box, FormHelperText, TextField, Typography } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import _ from "lodash";

const filterTopics = createFilterOptions({
  stringify: (topic) => `${topic.name} ${topic.messageType}`,
});

export default function TopicSelect({
  isLoading = false,
  id = "topic-select",
  inputLabel = "Search for topic",
  helperTextId = "topic-select-helper-text",
  helperText = null,
  error = false,
  sortSelectedFirst = true,
  getTopicDisabled = () => false,
  limitTopics = -1,
  size = "medium",
  multiple = false,
  closeOnSelect = !multiple,
  width = "50ch",
  topics,
  selection,
  onSelectionChange,
}) {
  let selectedIds = [];
  if (_.isArray(selection)) {
    selectedIds = _.map(selection, "id");
  } else if (selection != null) {
    selectedIds = [selection.id];
  }

  let options = topics ?? [];
  if (sortSelectedFirst) {
    options = _.sortBy(options, (option) =>
      selectedIds.includes(option.id) ? 0 : 1
    );
  }

  return (
    <>
      <Autocomplete
        sx={{ width }}
        aria-describedby={helperText != null ? helperTextId : undefined}
        disabled={isLoading}
        disableCloseOnSelect={!closeOnSelect}
        autoComplete
        autoHighlight
        id={id}
        size={size}
        limitTags={limitTopics}
        multiple={multiple}
        options={options}
        value={selection}
        onChange={(e, newValue) => onSelectionChange(newValue)}
        getOptionDisabled={getTopicDisabled}
        getOptionLabel={(topic) => topic.name}
        isOptionEqualToValue={(topic) => selectedIds.includes(topic.id)}
        filterOptions={filterTopics}
        renderInput={(params) => (
          <TextField
            {...params}
            label={inputLabel}
            variant="outlined"
            error={error}
          />
        )}
        renderOption={(props, topic) => (
          <li {...props}>
            <Box maxWidth="100%">
              <Typography noWrap title={topic.name}>
                {topic.name}
              </Typography>
              <Typography noWrap variant="body2" color="textSecondary">
                {topic.messageType}
              </Typography>
            </Box>
          </li>
        )}
      />
      {helperText != null && (
        <FormHelperText
          id={helperTextId}
          component="div"
          error={error}
          variant="outlined"
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  );
}
