import { useEffect } from "react";
import { useFormikContext } from "formik";

export interface FormikEffectProps<TValues = unknown> {
  onChange: (values: TValues) => void;
}

export default function FormikEffect<TValues = unknown>({
  onChange,
}: FormikEffectProps<TValues>) {
  const { values } = useFormikContext<TValues>();

  useEffect(
    function handleValuesChange() {
      onChange(values);
    },
    [onChange, values]
  );

  return null;
}
