import React from "react";
import { Link, LinkProps } from "@mui/material";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

export type NavigationLinkProps = Pick<
  LinkProps,
  "children" | "color" | "variant" | "sx"
> &
  Pick<RouterLinkProps, "to">;

export default function NavigationLink(props: NavigationLinkProps) {
  return <Link {...props} component={RouterLink} />;
}
