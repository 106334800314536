import { LocationDescriptor, LocationDescriptorObject } from "history";
import { pick } from "lodash";
import queryString from "query-string";
import { ExtractRouteParams } from "react-router";
import {
  generatePath,
  matchPath,
  RouteProps,
  useLocation,
} from "react-router-dom";
import {
  Extraction,
  ExtractionSearchOptions,
  IngestionSearchOptions,
  Log,
  LogSearchOptions,
} from "../queries";

// Hook

export function useActivePath() {
  const { pathname } = useLocation();

  return function isActive(path: RouteProps["path"]): boolean {
    return matchPath(pathname, { path, exact: true }) !== null;
  };
}

// Home Page

export const home = "/";

export type HomeParams = ExtractRouteParams<typeof home, string>;

// Sign Out Page

export const signOut = "/sign-out";

export type SignOutParams = ExtractRouteParams<typeof signOut, string>;

// Sign Up Page

export const signUp = "/sign-up";

export type SignUpParams = ExtractRouteParams<typeof signUp, string>;

// Sign In Page

export const signIn = "/sign-in";

export type SignInParams = ExtractRouteParams<typeof signIn, string>;

// Profile Page

export const profile = "/profile";

export type ProfileParams = ExtractRouteParams<typeof profile, string>;

// Ingestion Search Page

export const ingestionSearch = "/ingestions";

export type IngestionSearchParams = ExtractRouteParams<
  typeof ingestionSearch,
  string
>;
export type IngestionSearchQuery = IngestionSearchOptions;

export function toIngestionSearch(
  search?: IngestionSearchQuery
): LocationDescriptor {
  return {
    pathname: generatePath(ingestionSearch),
    ...stringifySearch(search),
  };
}

// Log Search Page

export const logSearch = "/logs";

export type LogSearchParams = ExtractRouteParams<typeof logSearch, string>;
export type LogSearchQuery = LogSearchOptions;

const logSearchFieldMask: (keyof LogSearchQuery)[] = [
  "sort",
  "field",
  "page",
  "pageSize",
  "name",
  "status",
  "groupId",
];

export function toLogSearch(keepExistingSearch?: boolean): LocationDescriptor;
export function toLogSearch(
  search: LogSearchQuery,
  keepExistingSearch?: boolean
): LocationDescriptor;
export function toLogSearch(
  maybeSearchOrKeep?: boolean | LogSearchQuery,
  maybeKeep?: boolean
): LocationDescriptor {
  return {
    pathname: generatePath(logSearch),
    ...getSearchFromOptions(maybeSearchOrKeep, maybeKeep, logSearchFieldMask),
  };
}

// Log Page

export const log = "/logs/:logId";

export type LogParams = ExtractRouteParams<typeof log, string>;

export type LogRecordsQuery = { t?: number; templateId?: Extraction["id"] };

export function toLog(logId: Log["id"], tab?: "details"): LocationDescriptor;
export function toLog(
  logId: Log["id"],
  tab: "records",
  search?: LogRecordsQuery
): LocationDescriptor;
export function toLog(
  logId: Log["id"],
  tab: "details" | "records" = "details",
  search: LogRecordsQuery = {}
): LocationDescriptor {
  return {
    pathname: generatePath(log, { logId }),
    ...stringifySearch({ ...search, tab }),
  };
}

// Extraction Search Page

export const extractionSearch = "/extractions";

export type ExtractionSearchParams = ExtractRouteParams<
  typeof extractionSearch,
  string
>;
export type ExtractionSearchQuery = ExtractionSearchOptions;

const extractionSearchFieldMask: (keyof ExtractionSearchQuery)[] = [
  "sort",
  "field",
  "page",
  "pageSize",
  "logId",
  "name",
  "status",
  "createdBy",
];

export function toExtractionSearch(
  keepExistingSearch?: boolean
): LocationDescriptor;
export function toExtractionSearch(
  search: ExtractionSearchQuery,
  keepExistingSearch?: boolean
): LocationDescriptor;
export function toExtractionSearch(
  maybeSearchOrKeep?: boolean | ExtractionSearchQuery,
  maybeKeep?: boolean
): LocationDescriptor {
  return {
    pathname: generatePath(extractionSearch),
    ...getSearchFromOptions(
      maybeSearchOrKeep,
      maybeKeep,
      extractionSearchFieldMask
    ),
  };
}

// Extraction Details Page

export const extractionDetails =
  "/logs/:logId/extractions/:extractionId/details";

export type ExtractionDetailsParams = ExtractRouteParams<
  typeof extractionDetails,
  string
>;
export type ExtractionDetailsQuery = ExtractionSearchQuery;

export function toExtractionDetails(
  logId: Log["id"],
  extractionId: Extraction["id"],
  keepExistingSearch?: boolean
): LocationDescriptor;
export function toExtractionDetails(
  logId: Log["id"],
  extractionId: Extraction["id"],
  search: ExtractionDetailsQuery,
  keepExistingSearch?: boolean
): LocationDescriptor;
export function toExtractionDetails(
  logId: Log["id"],
  extractionId: Extraction["id"],
  maybeSearchOrKeep?: boolean | ExtractionDetailsQuery,
  maybeKeep?: boolean
): LocationDescriptor {
  return {
    pathname: generatePath(extractionDetails, {
      logId,
      extractionId,
    }),
    ...getSearchFromOptions(
      maybeSearchOrKeep,
      maybeKeep,
      extractionSearchFieldMask
    ),
  };
}

// Helpers

function getSearchFromOptions(
  searchOrKeep: boolean | object = false,
  keep: boolean = false,
  fieldMask: string[]
): LocationDescriptorObject | undefined {
  if (searchOrKeep === false) {
    return undefined;
  } else if (searchOrKeep === true) {
    return stringifySearch(getCurrentSearch(fieldMask));
  } else if (keep) {
    return mergeWithCurrentSearch(searchOrKeep, fieldMask);
  } else {
    return stringifySearch(searchOrKeep);
  }
}

function getCurrentSearch(fieldMask: string[]): queryString.ParsedQuery {
  return pick(queryString.parse(window.location.search), fieldMask);
}

function mergeWithCurrentSearch(
  newSearch: object,
  fieldMask: string[]
): LocationDescriptorObject {
  const currentSearch = getCurrentSearch(fieldMask);

  return stringifySearch({ ...currentSearch, ...newSearch });
}

function stringifySearch(search?: object): LocationDescriptorObject {
  return {
    search:
      search === undefined
        ? ""
        : `?${queryString.stringify(search, { skipEmptyString: true })}`,
  };
}
