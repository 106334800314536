import React, { useEffect } from "react";
import { Card, CardContent, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import NavigationLink from "../components/NavigationLink";
import config from "../config";
import { useSignOut } from "../queries";
import * as paths from "../views/paths";

export default function SignOut() {
  const { mutate } = useSignOut();

  useEffect(
    function signOutOnMount() {
      mutate();
    },
    [mutate]
  );

  return (
    <>
      <Helmet>
        <title>Sign Out &bull; {config.datastoreInstanceName} DataStore</title>
      </Helmet>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "75vh",
          "& .MuiCard-root": {
            m: "auto",
            width: 1,
          },
        }}
        maxWidth="sm"
      >
        <Card elevation={4}>
          <CardContent>
            <Typography variant="h6" component="h1" gutterBottom>
              Signed Out
            </Typography>
            <Typography paragraph>
              You've been signed out of the {config.datastoreInstanceName}{" "}
              DataStore.
            </Typography>
            <Typography align="center" paragraph>
              <NavigationLink to={paths.signIn}>Sign back in</NavigationLink>
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
