import React, { PropsWithChildren, useCallback, useEffect } from "react";
import invariant from "invariant";
import { find, remove } from "lodash";
import { DeepReadonly } from "ts-essentials";
import { useImmer } from "use-immer";
import { createSafeContext } from "../contexts";
import {
  Extraction,
  ExtractionTopic,
  Log,
  SampleRange,
  Topic,
  useCreateExtraction,
  useEstimateExtraction,
  useExtractionTopics,
  useLog,
  useLogTopics,
} from "../queries";
import { mergeAddRange } from "../utils";

export interface NewTopicRange {
  topic: Topic;
  range?: SampleRange;
}

export type TopicRange = Required<NewTopicRange>;

export interface DraftExtractionContextValue {
  isInitialized: boolean;
  templateId: Extraction["id"] | undefined;
  estimator: ReturnType<typeof useEstimateExtraction>;
  creator: ReturnType<typeof useCreateExtraction>;
  extractionTopics: DeepReadonly<ExtractionTopic[]>;
  addRanges: (ranges: NewTopicRange | NewTopicRange[]) => void;
  removeRange: (range: TopicRange) => void;
  removeTopic: (topic: Topic) => void;
  clear: () => void;
}

export type DraftExtractionProviderProps = PropsWithChildren<{
  /**
   * ID of the log whose topics are being used for this draft
   */
  logId: Log["id"];
  /**
   * (Optional) ID of an existing extraction to be used as the template
   * for this draft
   */
  templateId?: Extraction["id"] | null;
}>;

export const [useDraftExtraction, DraftExtractionContext] =
  createSafeContext<DraftExtractionContextValue>("DraftExtraction");

export default function DraftExtractionProvider({
  logId,
  templateId = null,
  children,
}: DraftExtractionProviderProps) {
  const [extractionTopics, setExtractionTopics] = useImmer<ExtractionTopic[]>(
    []
  );

  const logQuery = useLog(logId);

  const topicsQuery = useLogTopics(logId);

  const templateTopicsQuery = useExtractionTopics(logId, templateId);

  const { mutate: estimate, ...estimationMutation } =
    useEstimateExtraction(logId);

  const creationMutation = useCreateExtraction(logId);

  const boundsMs = logQuery.data?.boundsMs;

  const isInitialized =
    logQuery.isSuccess &&
    Array.isArray(boundsMs) &&
    topicsQuery.isSuccess &&
    (templateId == null ||
      templateTopicsQuery.isSuccess ||
      templateTopicsQuery.isError);

  const addRanges = useCallback(
    (maybeRanges: NewTopicRange | NewTopicRange[]) => {
      invariant(isInitialized, "Attempting to add range before initialized");

      setExtractionTopics((draftTopics) => {
        const ranges = Array.isArray(maybeRanges) ? maybeRanges : [maybeRanges];

        ranges.forEach(({ topic, range }) => {
          const existingTopic = find(draftTopics, ["topic.id", topic.id]);

          range ??= {
            startTimeMs: boundsMs[0],
            endTimeMs: boundsMs[1],
            sampleFrequency: null,
          };

          if (existingTopic === undefined) {
            draftTopics.push({
              topic,
              ranges: [range],
            });
          } else {
            existingTopic.ranges = mergeAddRange(range, existingTopic);
          }
        });
      });
    },
    [isInitialized, setExtractionTopics, boundsMs]
  );

  const removeRange = useCallback(
    ({ topic, range }: TopicRange) => {
      invariant(isInitialized, "Attempting to remove range before initialized");

      setExtractionTopics((draftTopics) => {
        const existingTopic = find(draftTopics, ["topic.id", topic.id]);

        if (existingTopic === undefined) {
          return;
        }

        remove(existingTopic.ranges, range);

        if (existingTopic.ranges.length === 0) {
          remove(draftTopics, ["topic.id", topic.id]);
        }
      });
    },
    [isInitialized, setExtractionTopics]
  );

  const removeTopic = useCallback(
    (topic: Topic) => {
      invariant(isInitialized, "Attempting to remove topic before initialized");

      setExtractionTopics((draftTopics) => {
        remove(draftTopics, ["topic.id", topic.id]);
      });
    },
    [isInitialized, setExtractionTopics]
  );

  const clear = useCallback(
    () => setExtractionTopics([]),
    [setExtractionTopics]
  );

  useEffect(() => {
    if (templateTopicsQuery.data === undefined) {
      return;
    }

    // Initialize the immer state with the template extraction's topics
    // once when they load
    setExtractionTopics(templateTopicsQuery.data);
  }, [templateTopicsQuery.data, setExtractionTopics]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    estimate(extractionTopics);
  }, [isInitialized, estimate, extractionTopics]);

  return (
    <DraftExtractionContext.Provider
      value={{
        isInitialized,
        templateId: templateId ?? undefined,
        estimator: {
          mutate: estimate,
          ...estimationMutation,
        },
        creator: creationMutation,
        extractionTopics,
        addRanges,
        removeRange,
        removeTopic,
        clear,
      }}
    >
      {children}
    </DraftExtractionContext.Provider>
  );
}
