import React from "react";
import { Card, CardContent, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import config from "../../config";
import SignUpForm from "./SignUpForm";

export default function SignUp() {
  return (
    <>
      <Helmet>
        <title>Sign Up &bull; {config.datastoreInstanceName} DataStore</title>
      </Helmet>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "75vh",
          "& .MuiCard-root": {
            m: "auto",
          },
        }}
        maxWidth="sm"
      >
        <Card elevation={4}>
          <CardContent>
            <SignUpForm />
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
