import React from "react";
import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import NavigationLink from "../components/NavigationLink";
import StatusChip from "../components/StatusChip";
import config from "../config";
import { Extraction, useExtractions, useUser } from "../queries";
import {
  renderAttr,
  renderEmpty,
  renderError,
  renderFieldOptions,
  SearchList,
} from "../search-list";
import * as paths from "../views/paths";
import { ExtractionDetailsQuery } from "./paths";

interface ExtractionListItemProps {
  extraction?: Extraction;
  searchOptions?: Required<ExtractionDetailsQuery>;
}

function ExtractionSearch() {
  const userQuery = useUser();

  return (
    <>
      <Helmet>
        <title>
          Search Extractions &bull; {config.datastoreInstanceName} DataStore
        </title>
      </Helmet>
      <SearchList
        title="Extractions"
        useSearchQuery={useExtractions}
        filterSchema={yup.object().shape({
          nameLike: yup.string().ensure(),
          createdBy: yup.lazy((val) =>
            val === "" || val == null
              ? yup.string().ensure()
              : yup.string().ensure().email()
          ),
          status: yup
            .string()
            .ensure()
            .oneOf(["", "processed", "processing", "error", "ready", "queued"]),
          logId: yup.lazy((val) =>
            val === "" || val == null
              ? yup.string().ensure()
              : yup.string().ensure().uuid()
          ),
        })}
        sortOptions={[
          { value: "createdAt", name: "Created at" },
          { value: "name", name: "Name" },
        ]}
        pageSizeOptions={[25, 50, 100]}
        render={{
          error() {
            return renderError("An error occurred searching for extractions");
          },
          loading() {
            return (
              <List>
                {[1, 2, 3].map((index) => (
                  <ExtractionListItem key={index} />
                ))}
              </List>
            );
          },
          items(extractions, searchModel) {
            return extractions.map((extraction) => (
              <ExtractionListItem
                key={extraction.id}
                extraction={extraction}
                searchOptions={searchModel}
              />
            ));
          },
          empty() {
            return renderEmpty("No extractions were found");
          },
          filters(formikBag) {
            return (
              <>
                <Field
                  component={TextField}
                  id="extraction-name-filter"
                  name="nameLike"
                  label="Name"
                  fullWidth
                  autoComplete="off"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={userQuery.data === undefined}
                      checked={Boolean(formikBag.values.createdBy)}
                      onChange={() =>
                        formikBag.setFieldValue(
                          "createdBy",
                          Boolean(formikBag.values.createdBy)
                            ? ""
                            : userQuery.data?.username ?? ""
                        )
                      }
                    />
                  }
                  label="Created by me"
                />
                <Field
                  component={TextField}
                  id="extraction-status-filter"
                  name="status"
                  label="Status"
                  fullWidth
                  select
                >
                  {renderFieldOptions([
                    { value: "", name: <i>All</i> },
                    { value: "processed", name: "Processed" },
                    { value: "processing", name: "Processing" },
                    { value: "error", name: "Error" },
                    { value: "ready", name: "Ready" },
                    { value: "queued", name: "Queued" },
                  ])}
                </Field>
                <Field
                  component={TextField}
                  id="log-id-filter"
                  name="logId"
                  label="Log ID"
                  fullWidth
                  autoComplete="off"
                />
              </>
            );
          },
        }}
      />
    </>
  );
}

ExtractionSearch.layoutOptions = SearchList.layoutOptions;

export default ExtractionSearch;

function ExtractionListItem({
  extraction,
  searchOptions,
}: ExtractionListItemProps) {
  let createdAt, displayName, status, createdBy;

  if (extraction === undefined || searchOptions === undefined) {
    createdAt = <Skeleton width="25ch" />;

    displayName = <Skeleton width="45ch" />;

    status = undefined;

    createdBy = <Skeleton width="25ch" />;
  } else {
    createdAt = extraction.createdAt.toUTCString().replace("GMT", "UTC");

    displayName = (
      <NavigationLink
        to={paths.toExtractionDetails(
          extraction.logId,
          extraction.id,
          searchOptions
        )}
      >
        {extraction.name || extraction.id}
      </NavigationLink>
    );

    if (extraction.status !== null) {
      status = <StatusChip status={extraction.status} />;
    }

    createdBy = extraction.createdBy ?? "-";
  }

  return (
    <ListItem sx={{ alignItems: "stretch" }} divider>
      <ListItemText disableTypography>
        <Typography>{createdAt}</Typography>
        <Stack direction="row" alignItems="center">
          <Typography variant="h6" component="p" mr={2}>
            {displayName}
          </Typography>
          {status}
        </Stack>
        <List
          sx={{
            display: "flex",
            alignItems: "center",
            "& .MuiListItem-root": {
              width: "auto",
              "&:first-of-type": {
                pl: 0,
              },
            },
          }}
        >
          {renderAttr("Created by", createdBy)}
        </List>
      </ListItemText>
    </ListItem>
  );
}
