import { Divider, List, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import Center from "../../../components/Center";
import Error from "../../../components/Error";
import NavigationLink from "../../../components/NavigationLink";
import { ListItem as IngestionListItem } from "../../../components/ingestions";
import { useIngestions } from "../../../queries";
import { pluralize } from "../../../utils";
import * as paths from "../../paths";

export default function IngestionList() {
  const { logId } = useParams<paths.LogParams>();

  const query = useIngestions({ logId, limit: 3 });

  if (query.isLoading || query.isIdle) {
    return (
      <List sx={{ py: 0 }}>
        {[1, 2, 3].map((index) => (
          <IngestionListItem key={index} />
        ))}
      </List>
    );
  } else if (query.isError) {
    return (
      <Error>
        <Typography variant="h6" component="p">
          Unable to get ingestions for this log
        </Typography>
      </Error>
    );
  } else if (query.data.count === 0) {
    return (
      <Center>
        <Typography variant="h6" component="p">
          No ingestion associated with this log
        </Typography>
      </Center>
    );
  } else {
    return (
      <>
        <List
          sx={{
            py: 0,
            "& > .MuiListItem-divider:last-of-type": {
              borderBottom: 0,
            },
          }}
        >
          {query.data.data.map((ingestion) => (
            <IngestionListItem key={ingestion.id} ingestion={ingestion} />
          ))}
        </List>
        <Divider sx={{ mb: 1 }} />
        <Typography>
          Showing {query.data.data.length} of{" "}
          {pluralize(query.data.count, "ingestion")}.{" "}
          <NavigationLink to={paths.toIngestionSearch({ logId })}>
            Search all of this log's ingestions
          </NavigationLink>
          .
        </Typography>
      </>
    );
  }
}
