import React from "react";
import {
  Check,
  Close,
  HorizontalSplit,
  MoreHoriz,
  VerticalSplit,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import invariant from "invariant";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { FileTreeOutline } from "mdi-material-ui";
import type { Orientation, Panel } from "../../../panel-layout";
import { PanelState, VisualizationPanelState } from "../../../queries";
import { supportsImage, supportsMap } from "./panels";

interface TopicPanelProps {
  id: Panel["id"];
  children: React.ReactNode;
  panelState: PanelState;
  onClearTopic: () => void;
  onSplit: (orientation: Orientation) => void;
  onClose: () => void;
  onChangeTab: (tab: VisualizationPanelState["tab"]) => void;
}

export default function ControlPanel({
  id,
  children,
  panelState,
  onClearTopic,
  onSplit,
  onClose,
  onChangeTab,
}: TopicPanelProps) {
  const popupState = usePopupState({
    variant: "popover",
    popupId: `panel-${id}-menu`,
  });

  function renderMenuVisItem(
    key: number,
    text: string,
    value: VisualizationPanelState["tab"],
    disabled: boolean = false
  ) {
    invariant(
      panelState.type === "vis",
      "Can only render vis items for vis panel"
    );

    const isCurrent = panelState.tab === value;

    return (
      <MenuItem
        key={key}
        disabled={disabled}
        onClick={() => onChangeTab(value)}
      >
        {isCurrent && (
          <ListItemIcon>
            <Check />
          </ListItemIcon>
        )}
        <ListItemText inset={!isCurrent}>{text}</ListItemText>
      </MenuItem>
    );
  }

  return (
    <Stack sx={{ height: 1, width: 1 }}>
      <Stack
        direction="row"
        alignItems="center"
        flexShrink={0}
        overflow="hidden"
        px={0.5}
        spacing={1}
        height={40}
        borderBottom={1}
        borderColor="divider"
      >
        <Tooltip title="Open panel settings">
          <IconButton {...bindTrigger(popupState)} size="small">
            <MoreHoriz />
          </IconButton>
        </Tooltip>
        {panelState.type === "vis" && (
          <Typography fontWeight="bold" noWrap>
            {panelState.name}
          </Typography>
        )}
        <Menu
          {...bindPopover(popupState)}
          PaperProps={{
            sx: {
              width: 300,
              "& .MuiDivider-root": {
                my: 1,
              },
            },
          }}
        >
          {/* MUI won't let you pass a fragment as a Menu's child so you
          have to use an array instead */}
          {panelState.type === "vis" && [
            <MenuItem key={0} onClick={onClearTopic}>
              <ListItemIcon>
                <FileTreeOutline />
              </ListItemIcon>
              <ListItemText>Choose new topic</ListItemText>
            </MenuItem>,
            <Divider key={1} component="li" />,
            renderMenuVisItem(2, "Timeline", "timeline"),
            renderMenuVisItem(
              3,
              "Chart",
              "chart",
              panelState.fields.length === 0
            ),
            renderMenuVisItem(
              4,
              "Image",
              "image",
              !supportsImage(panelState.messageType)
            ),
            renderMenuVisItem(
              5,
              "Map",
              "map",
              !supportsMap(panelState.messageType)
            ),
            <Divider key={6} component="li" />,
          ]}
          <MenuItem key={7} onClick={() => onSplit("vertical")}>
            <ListItemIcon>
              <VerticalSplit />
            </ListItemIcon>
            <ListItemText>Split panel right</ListItemText>
          </MenuItem>
          <MenuItem key={8} onClick={() => onSplit("horizontal")}>
            <ListItemIcon>
              <HorizontalSplit />
            </ListItemIcon>
            <ListItemText>Split panel down</ListItemText>
          </MenuItem>
          <Divider key={9} component="li" />
          <MenuItem key={10} onClick={onClose}>
            <ListItemIcon>
              <Close />
            </ListItemIcon>
            <ListItemText>Close panel</ListItemText>
          </MenuItem>
        </Menu>
      </Stack>
      <Box sx={{ flexGrow: 1, minHeight: 0, overflowY: "auto" }}>
        {children}
      </Box>
    </Stack>
  );
}
