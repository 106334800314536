import React, { useState } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import * as Yup from "yup";
import { FeedbackType, useCreateFeedback } from "../queries";

const feedbackSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf(["feat", "bug", "qol", "other"], "Unknown feedback type")
    .required("Feedback type is required"),
  info: Yup.string().required("A description is required"),
});

export default function FeedbackDialog({
  feedbackTitle = "Provide Feedback",
  initialFeedbackType = "feat",
  showFeedbackTypes = true,
  infoReadOnly = false,
  initialInfoValue = "",
  infoLabel = "Description",
  infoHelperText = "Describe your feedback here",
  errorAlertMessage = "Unable to send feedback",
  successAlertMessage = "Feedback sent!",
  onModalClose,
  children,
  ...modalProps
}) {
  const [alert, setAlert] = useState(null);

  const mutation = useCreateFeedback();

  async function handleSubmit(values, { resetForm }) {
    try {
      await mutation.mutateAsync(values, {
        onError() {
          setAlert({
            severity: "error",
            message: errorAlertMessage,
          });
        },
        onSuccess() {
          setAlert({
            severity: "success",
            message: successAlertMessage,
          });
        },
      });
      resetForm();
    } catch (e) {
      /* noop */
    }
  }

  function handleModalClose() {
    onModalClose();
    setAlert(null);
  }

  return (
    <Dialog
      aria-labelledby="feedback-modal-title"
      onClose={handleModalClose}
      fullWidth
      {...modalProps}
    >
      <DialogTitle id="feedback-modal-title">{feedbackTitle}</DialogTitle>
      {alert != null && (
        <DialogContent>
          <Alert
            variant="filled"
            severity={alert.severity}
            onClose={() => setAlert(null)}
          >
            {alert.message}
          </Alert>
        </DialogContent>
      )}
      <Formik
        initialValues={{
          info: initialInfoValue,
          type: initialFeedbackType,
        }}
        onSubmit={handleSubmit}
        validationSchema={feedbackSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContent>
              <DialogContentText>{children}</DialogContentText>
              {showFeedbackTypes && (
                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">Feedback Type</FormLabel>
                  <Field component={RadioGroup} id="feedbackType" name="type">
                    <FormControlLabel
                      value={FeedbackType.Feat}
                      control={<Radio disabled={isSubmitting} />}
                      label="Feature Request"
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value={FeedbackType.Bug}
                      control={<Radio disabled={isSubmitting} />}
                      label="Bug Report"
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value={FeedbackType.Qol}
                      control={<Radio disabled={isSubmitting} />}
                      label="Quality-of-Life Request"
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value={FeedbackType.Other}
                      control={<Radio disabled={isSubmitting} />}
                      label="Other"
                      disabled={isSubmitting}
                    />
                  </Field>
                </FormControl>
              )}
              <Field
                component={TextField}
                id="info"
                name="info"
                fullWidth
                multiline
                rows={3}
                margin="normal"
                variant="outlined"
                label={infoLabel}
                helperText={infoHelperText}
                InputProps={{
                  autoComplete: "off",
                  readOnly: infoReadOnly,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isSubmitting}
                variant="text"
                color="primary"
                onClick={handleModalClose}
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting}
                variant="text"
                color="primary"
                type="submit"
                startIcon={
                  isSubmitting && <CircularProgress color="inherit" size={20} />
                }
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
