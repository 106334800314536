import React, { PropsWithChildren, useMemo, useState } from "react";
import Cookie from "js-cookie";
import { Configuration, ConfigurationParameters } from "racer-openapi-ts-sdk";
import { ApiClient, TokenMiddleware } from "../apiClient";
import config from "../config";
import { createSafeContext } from "../contexts";
import { refreshedTokensFromApi } from "../queries";
import { StorageTokenStore, TokenManager, TokenStore } from "../token-manager";
import { ApiContext, ApiContextValue } from "./ApiProvider";

export type AuthProviderProps = PropsWithChildren<{}>;

export type AuthState = "authenticated" | "unauthenticated" | "expired";

export interface AuthContextValue {
  authState: AuthState;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  tokenStore: TokenStore;
}

export const [useAuth, AuthContext] =
  createSafeContext<AuthContextValue>("Auth");

const baseConfigParameters: ConfigurationParameters = {
  basePath: config.api.baseUrl,
};

export default function AuthProvider({ children }: AuthProviderProps) {
  const [{ unauthenticatedClient, tokenStore }] = useState(() => {
    const unauthenticatedClient = new ApiClient(
      new Configuration(baseConfigParameters)
    );

    const tokenStore = new StorageTokenStore(window.localStorage, "authTokens");

    return {
      unauthenticatedClient,
      tokenStore,
    };
  });

  const [authState, setAuthState] = useState<AuthState>(() =>
    tokenStore.get() === null ? "unauthenticated" : "authenticated"
  );

  const [authenticatedClient] = useState(() => {
    const tokenManager = new TokenManager({
      refreshFn: async (refreshToken) =>
        refreshedTokensFromApi(
          await unauthenticatedClient.refreshTokens(refreshToken)
        ),
      tokenStore,
      onError() {
        setAuthState("expired");
      },
      onRefresh({ idToken }) {
        setAuthCookie(idToken);
      },
    });

    const authenticatedClient = new ApiClient(
      new Configuration({
        ...baseConfigParameters,
        middleware: [new TokenMiddleware(tokenManager)],
      })
    );

    return authenticatedClient;
  });

  const authContextValue = useMemo<AuthContextValue>(
    () => ({
      authState,
      setAuthState,
      tokenStore,
    }),
    [authState, setAuthState, tokenStore]
  );

  const apiContextValue = useMemo<ApiContextValue>(
    () => ({
      unauthenticatedClient,
      authenticatedClient,
    }),
    [unauthenticatedClient, authenticatedClient]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      <ApiContext.Provider value={apiContextValue}>
        {children}
      </ApiContext.Provider>
    </AuthContext.Provider>
  );
}

export function setAuthCookie(token: string): void {
  // Set auth cookie for ICD docs site access if making a production
  // build and env var is properly set
  if (
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_SET_AUTH_COOKIE === "true"
  ) {
    Cookie.set(config.authCookieName, token, {
      secure: true,
      domain: window.location.hostname,
      sameSite: "lax",
    });
  }
}

export function removeAuthCookie(): void {
  // Remove auth cookie for ICD docs site access if making a production
  // build and env var is properly set
  if (
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_SET_AUTH_COOKIE === "true"
  ) {
    Cookie.remove(config.authCookieName, { domain: window.location.hostname });
  }
}
