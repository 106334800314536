import React, { useState } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { matchSorter } from "match-sorter";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import DividedCardHeader from "../../../components/DividedCardHeader";
import { useLog, useLogTopics, useTopic } from "../../../queries";
import { useTopicComparison } from "../queries";
import DistributionChart from "./DistributionChart";
import TopicStatsList from "./TopicStatsList";

export default function TopicListCard({ filter, setFilter }) {
  const [selectedTopicId, setSelectedTopicId] = useState(null);

  const { logId } = useParams();

  const logQuery = useLog(logId);

  const { enqueueSnackbar } = useSnackbar();

  const topicsQuery = useLogTopics(logId);

  const { isLoading: areTopicDetailsLoading, data: topicDetails } = useTopic(
    logId,
    selectedTopicId,
    {
      onError() {
        enqueueSnackbar("An error occurred trying to fetch the topic", {
          variant: "error",
        });
      },
    }
  );

  const { data: similarTopics = [] } = useTopicComparison(
    topicDetails?.name ?? null
  );

  let topicDetailsComponent;
  if (topicDetails) {
    topicDetailsComponent = <TopicStatsList topic={topicDetails} />;
  } else if (areTopicDetailsLoading) {
    topicDetailsComponent = <CircularProgress />;
  } else {
    topicDetailsComponent = (
      <Typography>Select a topic to view stats.</Typography>
    );
  }

  let content;
  if (logQuery.isSuccess && logQuery.data.status !== "processed") {
    content = (
      <Typography>
        Topics will be available when the log is ingested and has finished
        processing.
      </Typography>
    );
  } else {
    let listSubheader, listItems;
    if (!topicsQuery.isSuccess) {
      listItems = [1, 2, 3].map((val) => (
        <ListItem key={val}>
          <ListItemText secondary={<Skeleton width="10ch" />}>
            <Skeleton width="15ch" />
          </ListItemText>
        </ListItem>
      ));
    } else {
      const filteredTopics = matchSorter(topicsQuery.data, filter, {
        keys: ["name", "messageType"],
      });

      listSubheader = (
        <ListSubheader sx={{ backgroundColor: "inherit", pt: 2 }}>
          <TextField
            id="topic-list-search"
            fullWidth
            label="Search topics"
            variant="outlined"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          Topics: {filteredTopics.length} {Boolean(filter) && "(filtered)"}
          <Divider />
        </ListSubheader>
      );

      listItems = filteredTopics.map((topic) => (
        <ListItem
          title={topic.name}
          key={topic.id}
          button
          selected={selectedTopicId === topic.id}
          onClick={() => setSelectedTopicId(topic.id)}
        >
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            secondary={topic.messageType}
            secondaryTypographyProps={{ noWrap: true }}
          >
            {topic.name}
          </ListItemText>
        </ListItem>
      ));
    }

    content = (
      <Grid
        sx={{
          "& > .MuiGrid-item": {
            display: "flex",
            "& > .MuiList-root": {
              maxHeight: 500,
              overflowY: "auto",
              backgroundColor: "background.paper",
              flex: 1,
            },
          },
        }}
        container
        spacing={2}
      >
        <Grid item xs={4}>
          <List disablePadding subheader={listSubheader}>
            {listItems}
          </List>
        </Grid>
        <Grid item xs={4}>
          <List disablePadding>{topicDetailsComponent}</List>
        </Grid>
        <Grid item xs={4}>
          <List disablePadding>
            {topicDetails && (
              <>
                <ListItem>
                  <DistributionChart
                    metric={"timestamp"}
                    log={logQuery.data}
                    mainTopic={topicDetails}
                    auxTopics={similarTopics}
                  />
                </ListItem>
                <ListItem>
                  <DistributionChart
                    metric={"offset"}
                    log={logQuery.data}
                    mainTopic={topicDetails}
                    auxTopics={similarTopics}
                  />
                </ListItem>
              </>
            )}
          </List>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card elevation={4}>
      <DividedCardHeader
        height="2rem"
        title="Topics"
        titleTypographyProps={{
          variant: "h6",
          component: "h2",
        }}
      />
      <CardContent>{content}</CardContent>
    </Card>
  );
}
