import { fromUnixTime, isValid } from "date-fns";
import { Log as ApiLog, LogList } from "racer-openapi-ts-sdk";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { useApi } from "../providers/ApiProvider";
import {
  FromApi,
  Log,
  LogMetadata,
  LogSearchOptions,
  SearchResult,
} from "./types";

// Query key factory

export const logKeys = {
  all: ["logs"] as const,
  lists: () => [...logKeys.all, "list"] as const,
  list: (filters: LogSearchOptions) => [...logKeys.lists(), filters] as const,
  details: () => [...logKeys.all, "details"] as const,
  detail: (logId: Log["id"]) => [...logKeys.details(), { logId }] as const,
};

// Queries

export function useLogs(
  opts: LogSearchOptions,
  queryOpts?: Pick<
    UseQueryOptions,
    "keepPreviousData" | "staleTime" | "cacheTime"
  >
) {
  const { authenticatedClient } = useApi();

  return useQuery(
    logKeys.list(opts),
    () => authenticatedClient.listLogs(opts),
    {
      select: logsFromApi,
      ...queryOpts,
    }
  );
}

export function useLog(
  logId: Log["id"],
  callbacks?: Pick<UseQueryOptions<unknown, Response, Log>, "onError">
) {
  const { authenticatedClient } = useApi();

  const queryClient = useQueryClient();

  return useQuery(
    logKeys.detail(logId),
    () => authenticatedClient.getLog(logId),
    {
      select: logFromApi,
      initialData() {
        // There could be multiple log list queries in the cache if the
        // user was doing a lot of searching before doing going to a page
        // that uses this hook. The log of interest *might* be in some of
        // those search queries, meaning we need to search through all
        // of them looking for the log of interest
        const listQueries = queryClient.getQueriesData<LogList>(
          logKeys.lists()
        );

        for (const [, query] of listQueries) {
          for (const log of query.data) {
            if (log.id === logId) {
              return { data: log };
            }
          }
        }

        // TS requires an explicit 'undefined'
        return undefined;
      },
      ...(Boolean(callbacks) && callbacks),
    }
  );
}

// Mutations

export function useUpdateLog(logId: Log["id"]) {
  const { authenticatedClient } = useApi();

  const queryClient = useQueryClient();

  return useMutation(
    async (metadata: Partial<LogMetadata>) =>
      await authenticatedClient.updateLog(logId, metadata),
    {
      onSuccess(updatedLog) {
        queryClient.setQueryData(logKeys.detail(logId), updatedLog);
      },
    }
  );
}

// Selectors

function logFromApi({ data }: FromApi<ApiLog>): Log {
  let startDate: Date | null = null;
  if (data.start_time != null) {
    startDate = fromUnixTime(data.start_time);
  }

  let endDate: Date | null = null;
  if (data.end_time != null) {
    endDate = fromUnixTime(data.end_time);
  }

  let createdAt = new Date(data.created_at);

  let deletedAt: Date | null = null;
  if (data.deleted_at != null) {
    deletedAt = new Date(data.deleted_at);
  }

  let duration: number | null = null;
  if (isValid(startDate) && isValid(endDate)) {
    duration = Number(endDate) - Number(startDate);
  }

  return {
    id: data.id,
    groupId: data.group_id,
    startDate,
    endDate,
    boundsMs:
      startDate !== null && endDate !== null
        ? [Number(startDate), Number(endDate)]
        : null,
    status: data.status,
    duration,
    distance: data.distance,
    metadata: {
      name: data.name,
      note: data.note,
      operator: data.operator,
      vehicle: data.vehicle,
      testId: data.test_id,
      comments: data.comments,
      sensorsOnline: data.sensors_online,
      softwareConfig: data.software_config,
    },
    createdAt,
    deletedAt,
  };
}

function logsFromApi({ count, data }: LogList): SearchResult<Log[]> {
  return {
    count,
    data: data.map((apiLog) => logFromApi({ data: apiLog })),
  };
}
