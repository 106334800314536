import React, { useMemo, useState } from "react";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/lab";
import { Typography } from "@mui/material";
import _ from "lodash";

function reconstructPaths(pathParts, toIndex = pathParts.length - 1) {
  return pathParts
    .slice(0, toIndex + 1)
    .reduce(
      (result, part, index, parts) => [
        ...result,
        `/${parts.slice(0, index + 1).join("/")}`,
      ],
      []
    );
}

function treeifyTopics(topics) {
  const namespaces = [];
  const nodes = new Map();

  const cache = new Map([[null, namespaces]]);

  topics.forEach((topic) => {
    const { name, messageType } = topic;

    _.compact(name.split("/")).forEach((pathPart, index, pathParts) => {
      const reconstructedPaths = reconstructPaths(pathParts, index);
      const pathName = _.last(reconstructedPaths);
      const parentPath = _.nth(reconstructedPaths, -2) ?? null;

      if (!cache.has(pathName)) {
        const node = {
          name: pathPart,
          nodeId: pathName,
          children: [],
        };

        let isLeaf = false;
        if (index === pathParts.length - 1) {
          node.messageType = messageType;

          isLeaf = true;
        }

        nodes.set(pathName, {
          isLeaf,
          topic: isLeaf ? topic : undefined,
        });

        cache.get(parentPath).push(node);
        cache.set(pathName, node.children);
      }
    }, cache);
  });

  return {
    namespaces,
    nodes,
  };
}

function renderTree(node) {
  const isLeaf = node.children.length === 0;

  function formatNodeName(name) {
    return (
      <>
        /
        {name.split(/(_)/).map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {part === "_" && <wbr />}
          </React.Fragment>
        ))}
      </>
    );
  }

  function formatMessageType(messageType) {
    if (messageType != null) {
      return messageType.split(/([_/])/).map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {["_", "/"].includes(part) && <wbr />}
        </React.Fragment>
      ));
    }
  }

  let label;
  if (isLeaf) {
    label = (
      <>
        <Typography component="span">{formatNodeName(node.name)}</Typography>{" "}
        <Typography component="span" variant="subtitle2" color="textSecondary">
          {formatMessageType(node.messageType)}
        </Typography>
      </>
    );
  } else {
    label = <b>{formatNodeName(node.name)}</b>;
  }

  return (
    <TreeItem key={node.name} nodeId={node.nodeId} label={label}>
      {isLeaf ? null : node.children.map((child) => renderTree(child))}
    </TreeItem>
  );
}

export default function TopicTree({ topics, onSelect }) {
  const [expanded, setExpanded] = useState([]);

  const { namespaces: tree, nodes } = useMemo(
    () => treeifyTopics(topics),
    [topics]
  );

  function handleNodeToggle(e, nodeIds) {
    setExpanded(nodeIds);
  }

  function handleNodeSelect(e, nodeId) {
    const { isLeaf, topic } = nodes.get(nodeId);

    if (isLeaf) {
      onSelect(topic);
    }
  }

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      expanded={expanded}
      onNodeToggle={handleNodeToggle}
      onNodeSelect={handleNodeSelect}
    >
      {tree.map(renderTree)}
    </TreeView>
  );
}
