import React, { useState } from "react";
import { EdgeSidebar, SidebarContent } from "@mui-treasury/layout";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Divider, Tab } from "@mui/material";
import {
  ExtractionCheckoutFormValues,
  ExtractionFormState,
  LabelFormState,
} from "../Records";
import ExtractionCheckout from "./ExtractionCheckout";
import ExtractionForm from "./ExtractionForm";
import LabelForm from "./LabelForm";
import LabelListView from "./LabelListView";

type Task = "extractions" | "labels";

export interface TaskDrawerProps {
  extractionFormState: ExtractionFormState;
  setExtractionFormState: React.Dispatch<
    React.SetStateAction<ExtractionFormState>
  >;
  extractionCheckoutFormValuesRef: React.MutableRefObject<ExtractionCheckoutFormValues>;
  labelFormState: LabelFormState;
  setLabelFormState: React.Dispatch<React.SetStateAction<LabelFormState>>;
}

export default function TaskDrawer({
  extractionFormState,
  setExtractionFormState,
  extractionCheckoutFormValuesRef,
  labelFormState,
  setLabelFormState,
}: TaskDrawerProps) {
  const [task, setTask] = useState<Task>("extractions");

  return (
    <EdgeSidebar
      anchor="right"
      sx={{
        "&.MuiDrawer-modal": { zIndex: "modal" },
        "& .EdgeHeaderOffset": { flexShrink: 0 },
      }}
    >
      <SidebarContent>
        <TabContext value={task}>
          <TabList
            sx={{ px: 3, py: 2, flexShrink: 0 }}
            variant="scrollable"
            onChange={(e, newTask) => setTask(newTask)}
          >
            <Tab label="Extractions" value="extractions" />
            <Tab label="Labels" value="labels" />
          </TabList>
          <TabPanel value="extractions">
            <ExtractionForm
              extractionFormState={extractionFormState}
              setExtractionFormState={setExtractionFormState}
            />
            <Divider sx={{ my: 2 }} />
            <ExtractionCheckout
              extractionCheckoutFormValuesRef={extractionCheckoutFormValuesRef}
            />
          </TabPanel>
          <TabPanel value="labels">
            <LabelForm
              labelFormState={labelFormState}
              setLabelFormState={setLabelFormState}
            />
            <Divider sx={{ my: 2 }} />
            <LabelListView />
          </TabPanel>
        </TabContext>
      </SidebarContent>
    </EdgeSidebar>
  );
}
