import { PropsWithChildren } from "react";
import invariant from "invariant";
import { get, has } from "lodash";
import { DeepRequired } from "ts-essentials";
import { createSafeContext } from "../contexts";

export interface DisabledFeatureBehavior {
  disabled: true;
  reason: string;
}

export interface EnabledFeatureBehavior {
  disabled?: false;
  reason?: string;
}

export type FeatureBehavior = DisabledFeatureBehavior | EnabledFeatureBehavior;

export interface AppFeatures {
  extractions?: {
    create?: FeatureBehavior;
    edit?: FeatureBehavior;
  };
  labels?: {
    create?: FeatureBehavior;
    delete?: FeatureBehavior;
  };
  logs?: {
    edit?: FeatureBehavior;
  };
}

export const [useFeatures, FeaturesContext] =
  createSafeContext<AppFeatures>("Features");

const defaultBehavior: Required<FeatureBehavior> = {
  disabled: false,
  reason: "",
};

const defaultFeatures: DeepRequired<AppFeatures> = {
  extractions: {
    create: defaultBehavior,
    edit: defaultBehavior,
  },
  labels: {
    create: defaultBehavior,
    delete: defaultBehavior,
  },
  logs: {
    edit: defaultBehavior,
  },
};

export type FeaturesProviderProps = PropsWithChildren<{
  features?: AppFeatures;
}>;

export default function FeaturesProvider({
  children,
  features = {},
}: FeaturesProviderProps) {
  return (
    <FeaturesContext.Provider value={features}>
      {children}
    </FeaturesContext.Provider>
  );
}

export function useFeature(feature: string): FeatureBehavior {
  const features = useFeatures();

  invariant(has(defaultFeatures, feature), `Path ${feature} does not exist`);

  // Try to get the feature if it's in the user-supplied features. If not,
  // default to the behavior specified in the defaults
  return get(features, feature, get(defaultFeatures, feature));
}
