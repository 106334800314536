import React from "react";
import { Box } from "@mui/material";

export default function Center({ children }) {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Box>
  );
}
