import React from "react";
import { HourglassEmpty } from "@mui/icons-material";
import { Card, CardContent, Typography } from "@mui/material";
import { TimerSand } from "mdi-material-ui";
import DividedCardHeader from "../../components/DividedCardHeader";
import JsonTree from "../../components/JsonTree";
import FileList from "./FileList";
import MergeDialog from "./MergeDialog";

const PRE_FILE_STATES = ["ready", "preprocessing", "preprocessed"];

export default function ArtifactsCard({
  isExtractionLoading,
  areFilesLoading,
  extraction,
  extractionLastUpdated,
  extractionFiles,
  expirationStatus,
}) {
  let content;
  if (isExtractionLoading) {
    content = <FileList isLoading />;
  } else if (extraction.status === "error") {
    content = (
      <>
        <Typography paragraph>
          An error prevented this extraction from completing successfully.
          Additional details can be found below.
        </Typography>
        <JsonTree src={{ error: extraction.error }} />
      </>
    );
  } else if (
    PRE_FILE_STATES.includes(extraction.status) ||
    extractionFiles?.length === 0
  ) {
    content = (
      <>
        <Typography paragraph>Waiting for files</Typography>
        <Typography variant="body2" color="textSecondary">
          Last updated:{" "}
          <b>{new Date(extractionLastUpdated).toLocaleTimeString()}</b>
        </Typography>
      </>
    );
  } else {
    let expirationMessage;
    if (expirationStatus.expired) {
      expirationMessage = (
        <>
          <HourglassEmpty fontSize="small" />{" "}
          <span>
            Expired <b>{expirationStatus.when}</b>
          </span>
        </>
      );
    } else {
      expirationMessage = (
        <>
          <TimerSand fontSize="small" />{" "}
          <span>
            Expires <b>{expirationStatus.when}</b>
          </span>
        </>
      );
    }

    content = (
      <>
        {expirationStatus.expired && (
          <Typography paragraph>
            Extraction expired. Under <b>Topics</b> select <b>Recreate</b> to
            make a copy of this extraction using the same topics.
          </Typography>
        )}
        <FileList
          isLoading={areFilesLoading}
          isExpired={expirationStatus.expired}
          files={extractionFiles}
        />
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
          }}
          variant="body2"
          color="textSecondary"
        >
          {expirationMessage}
        </Typography>
        <MergeDialog extractionId={extraction.id} />
      </>
    );
  }

  return (
    <Card elevation={4}>
      <DividedCardHeader
        height="2rem"
        title="Artifacts"
        titleTypographyProps={{
          variant: "h6",
          component: "h2",
        }}
      />
      <CardContent>{content}</CardContent>
    </Card>
  );
}
