import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import Loading from "../../components/Loading";
import config from "../../config";
import { useExternalSignIn } from "../../queries";

export default function ExternalSignIn() {
  // This view is only rendered if the external auth env vars exist
  const externalAuth = config.externalAuth!;

  const { mutate, ...mutation } = useExternalSignIn();

  const { enqueueSnackbar } = useSnackbar();

  const [code] = useState(() => {
    // The 'code' param might not have been in the URL at all, in which
    // case we want to default to null to work nicely with this library's
    // TS types
    const { code: parsedCode = null } = queryString.parse(
      window.location.search
    );

    return Array.isArray(parsedCode) ? parsedCode[0] : parsedCode;
  });

  useEffect(() => {
    if (code === null) {
      return;
    }

    mutate(code, {
      onError() {
        enqueueSnackbar(
          "Unable to log you in. Visit your external sign in service again",
          {
            variant: "error",
          }
        );
      },
    });
  }, [code, mutate, enqueueSnackbar]);

  if (code === null || mutation.isError) {
    return (
      <Box>
        <Typography variant="h6" component="h1" gutterBottom>
          Sign In
        </Typography>
        <Typography paragraph>
          Select <b>Visit External Sign In</b> below to visit your external
          sign-in service. You will be redirected here once finished.
        </Typography>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          href={externalAuth.signInUrl}
        >
          Visit External Sign In
        </Button>
      </Box>
    );
  } else {
    return (
      <Box>
        <Typography variant="h6" component="h1" gutterBottom>
          Sign In
        </Typography>
        <Loading type="circular">
          <Typography paragraph>
            Fetching your info using external sign-in information
          </Typography>
        </Loading>
        <Button disabled fullWidth color="primary" variant="contained">
          Visit External Sign In
        </Button>
      </Box>
    );
  }
}
