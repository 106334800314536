import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  Content,
  EdgeSidebar,
  EdgeTrigger,
  SidebarContent,
} from "@mui-treasury/layout";
import {
  ArrowDownward,
  ArrowUpward,
  Error as ErrorIcon,
  FilterAlt,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { isMatch, omit } from "lodash";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { UseQueryOptions, UseQueryResult } from "react-query";
import { useHistory } from "react-router-dom";
import type { SchemaOf } from "yup";
import * as yup from "yup";
import { ValidationError } from "yup";
import Center from "../components/Center";
import Header from "../components/Header";
import type { BaseSearchOptions } from "../queries";
import { SearchResult } from "../queries";
import { LayoutOptions } from "../views/types";
import { Pagination } from "./Pagination";

export type FilterModel<TSearchModel extends BaseSearchOptions> = Omit<
  TSearchModel,
  keyof BaseSearchOptions
>;

export interface FieldOption {
  value: string;
  name: ReactNode;
}

export type UseSearchQuery<
  TSearchModel extends BaseSearchOptions,
  TData = unknown
> = (
  model: TSearchModel,
  queryOptions: Pick<
    UseQueryOptions,
    "keepPreviousData" | "staleTime" | "cacheTime"
  >
) => UseQueryResult<SearchResult<TData>>;

export interface SearchListProps<
  TSearchModel extends BaseSearchOptions,
  TData = unknown
> {
  title: string;
  useSearchQuery: UseSearchQuery<TSearchModel, TData>;
  /* A yup object schema for validating the search filters */
  filterSchema: any;
  sortOptions: FieldOption[];
  defaultSortField?: FieldOption["value"];
  defaultSortDirection?: NonNullable<BaseSearchOptions["sort"]>;
  pageSizeOptions: number[];
  defaultPageSize?: number;
  render: {
    error: () => ReactNode;
    loading: () => ReactNode;
    items: (data: TData, searchModel: Required<TSearchModel>) => ReactNode;
    empty: () => ReactNode;
    filters: (
      formikBag: FormikProps<Required<FilterModel<TSearchModel>>>
    ) => ReactNode;
  };
}

function SearchList<TSearchModel extends BaseSearchOptions, TData = unknown>({
  title,
  useSearchQuery,
  filterSchema,
  sortOptions,
  defaultSortField = sortOptions[0].value,
  defaultSortDirection = "desc",
  pageSizeOptions,
  defaultPageSize = pageSizeOptions[0],
  render,
}: SearchListProps<TSearchModel, TData>) {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const contentRef = useRef<HTMLElement>(null);

  const searchSchema: SchemaOf<TSearchModel> = filterSchema
    .shape({
      page: yup.number().min(0).default(0),
      pageSize: yup.number().oneOf(pageSizeOptions).default(defaultPageSize),
      field: yup
        .string()
        .oneOf(sortOptions.map((opt) => opt.value))
        .default(defaultSortField),
      sort: yup.string().oneOf(["asc", "desc"]).default(defaultSortDirection),
    })
    .noUnknown();

  const [searchModelStatus, setSearchModelStatus] = useState(() => {
    const queryParams = queryString.parse(window.location.search);

    try {
      return {
        searchModel: searchSchema.validateSync(
          queryParams
        ) as Required<TSearchModel>,
        initialError: false,
      };
    } catch (e) {
      if (!(e instanceof ValidationError)) {
        throw e;
      }

      return {
        searchModel: searchSchema.getDefault() as Required<TSearchModel>,
        initialError: true,
      };
    }
  });

  const { searchModel, initialError } = searchModelStatus;

  const query = useSearchQuery(searchModel, {
    keepPreviousData: true,
    // Disable stale-while-revalidate functionality, otherwise you get
    // several rapid UI changes that don't look good
    staleTime: 0,
    cacheTime: 0,
  });

  useEffect(() => {
    if (initialError) {
      enqueueSnackbar("Invalid search params provided. Using default values", {
        variant: "error",
      });
    }
  }, [initialError, enqueueSnackbar]);

  useEffect(
    function resetScrollWhenDataChanges() {
      // Issue #121: Whenever we get new data, scroll the content area
      // back to the top
      if (contentRef.current !== null) {
        contentRef.current.scrollTop = 0;
      }
    },
    [query.dataUpdatedAt]
  );

  useEffect(() => {
    const search = queryString.stringify(searchModel, {
      skipNull: true,
      skipEmptyString: true,
    });

    history.replace({ search });
  }, [searchModel, history]);

  function updateSearchModel(updates: Partial<TSearchModel>) {
    if (isMatch(searchModel, updates)) {
      // The updates make no changes to the model so we should refetch
      // the existing query. Updating the search model won't result
      // in a refetch due to react-query's key hashing
      query.refetch();
    } else {
      // User changed the search model in some way, go ahead and update
      // the state and react-query will fire off the new query on next render
      setSearchModelStatus({
        initialError,
        searchModel: {
          ...searchModel,
          // Automatically reset the page back to 0 when changing the page size,
          // sort, or filters as it makes the most sense to start them over if
          // they made those changes. However, still spread their updates *after*
          // this point so if they did happen to just change the page number their
          // change will still take place
          page: 0,
          ...updates,
        },
      });
    }
  }

  let content: ReactNode;
  if (query.isLoading || query.isIdle) {
    content = <Box flexGrow={1}>{render.loading()}</Box>;
  } else if (query.isError) {
    content = <Box flexGrow={1}>{render.error()}</Box>;
  } else if (query.data.count === 0) {
    content = <Box flexGrow={1}>{render.empty()}</Box>;
  } else {
    content = (
      <>
        <Stack
          direction="row"
          justifyContent="flex-end"
          display={{
            xs: "flex",
            lg: "none",
          }}
          p={2}
        >
          <EdgeTrigger target={{ anchor: "right", field: "open" }}>
            {(open, setOpen) => (
              <Button
                variant="outlined"
                onClick={() => setOpen(!open)}
                startIcon={<FilterAlt />}
              >
                Filters
              </Button>
            )}
          </EdgeTrigger>
        </Stack>
        <List sx={{ py: 0 }}>{render.items(query.data.data, searchModel)}</List>
        <Box p={2} mt="auto">
          <Pagination
            page={searchModel.page}
            pageSize={searchModel.pageSize}
            pageSizeOptions={pageSizeOptions}
            totalCount={query.data.count}
            onChange={updateSearchModel}
          />
        </Box>
      </>
    );
  }

  return (
    <>
      <Header title={title} />
      <Content sx={{ flexDirection: "column" }}>
        <Box
          ref={contentRef}
          sx={{
            flexGrow: 1,
            minHeight: 0,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {content}
        </Box>
      </Content>
      <EdgeSidebar
        anchor="right"
        sx={{ "&.MuiDrawer-modal": { zIndex: "modal" } }}
      >
        <SidebarContent>
          <Box px={3} py={2} overflow="auto">
            <Typography variant="h6" component="h2" mb={3}>
              Sort and Filter
            </Typography>
            <Stack spacing={3}>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  select
                  fullWidth
                  id="sort-field"
                  name="field"
                  label="Sort by"
                  value={searchModel.field}
                  onChange={(e) =>
                    updateSearchModel({
                      field: e.target.value,
                    } as Partial<TSearchModel>)
                  }
                >
                  {renderFieldOptions(sortOptions)}
                </TextField>
                <div>
                  <Tooltip title="Change sort direction">
                    <IconButton
                      aria-label={
                        searchModel.sort === "desc"
                          ? "Change sort to ascending"
                          : "Change sort to descending"
                      }
                      onClick={() =>
                        updateSearchModel({
                          sort: searchModel.sort === "desc" ? "asc" : "desc",
                        } as Partial<TSearchModel>)
                      }
                    >
                      {searchModel.sort === "desc" ? (
                        <ArrowDownward />
                      ) : (
                        <ArrowUpward />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </Stack>
              <Divider />
              <Formik
                initialValues={omit(searchModel, [
                  "sort",
                  "field",
                  "page",
                  "pageSize",
                ])}
                onSubmit={(values, { setSubmitting }) => {
                  updateSearchModel(values as Partial<TSearchModel>);

                  setSubmitting(false);
                }}
                validationSchema={searchSchema}
              >
                {(formikBag) => (
                  <Form>
                    <Stack spacing={3}>
                      {render.filters(formikBag)}
                      <LoadingButton
                        type="submit"
                        disabled={!formikBag.isValid}
                        loading={query.isRefetching}
                        variant="outlined"
                      >
                        Apply Filters
                      </LoadingButton>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Stack>
          </Box>
        </SidebarContent>
      </EdgeSidebar>
    </>
  );
}

const layoutOptions: LayoutOptions = {
  scheme: {
    rightEdgeSidebar: {
      config: {
        xs: {
          variant: "temporary",
          width: "min(80%, 400px)",
        },
        lg: {
          variant: "permanent",
          width: 400,
        },
      },
    },
  },
};
SearchList.layoutOptions = layoutOptions;

export default SearchList;

export function renderFieldOptions(options: FieldOption[]) {
  return options.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.name}
    </MenuItem>
  ));
}

export function renderAttr(name: ReactNode, val: ReactNode) {
  return (
    <ListItem>
      <ListItemText
        secondaryTypographyProps={{ fontWeight: "bold" }}
        secondary={name}
      >
        {val}
      </ListItemText>
    </ListItem>
  );
}

export function renderError(message: string) {
  return (
    <Center>
      <ErrorIcon color="error" fontSize="large" />
      <Typography variant="h5" component="p">
        {message}
      </Typography>
    </Center>
  );
}

export function renderEmpty(message: string) {
  return (
    <Center>
      <Typography variant="h5" component="p">
        {message}
      </Typography>
      <Typography>Try changing your criteria and searching again</Typography>
    </Center>
  );
}
