import React from "react";
import { Box, Button, Chip, Divider, Stack, Typography } from "@mui/material";
import { minutesToMilliseconds, secondsToMilliseconds } from "date-fns";
import _ from "lodash";
import { TimerSand } from "mdi-material-ui";
import { useParams } from "react-router-dom";
import {
  VictoryAxis,
  VictoryChart,
  VictoryClipContainer,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryZoomContainer,
} from "victory";
import Center from "../../../components/Center";
import Error from "../../../components/Error";
import JsonTree from "../../../components/JsonTree";
import Loading from "../../../components/Loading";
import NavigationLink from "../../../components/NavigationLink";
import { formatTimestamp } from "../../../new-utils";
import { SampleFreq, useLog, VisualizationPanelState } from "../../../queries";
import * as paths from "../../paths";
import { usePlayback } from "../contexts";
import useFirstMessage from "../hooks/useFirstMessage";
import useWindow from "../hooks/useWindow";

interface TimelinePanelProps {
  topic: any;
  fields: VisualizationPanelState["fields"];
  onChangeFields: (fields: VisualizationPanelState["fields"]) => void;
  onGraph: () => void;
  width: number;
}

type Field = VisualizationPanelState["fields"][number];

export default function TimelinePanel({
  topic,
  fields,
  onChangeFields,
  onGraph,
  width,
}: TimelinePanelProps) {
  const { logId } = useParams<paths.LogParams>();

  const logQuery = useLog(logId);

  const firstMessageQuery = useFirstMessage(logId, topic.id);

  const { boundsMs, timestampMs } = usePlayback();

  const recordsWindow = useWindow({
    logId,
    topicId: topic.id,
    sampleFrequency: SampleFreq.Decisecond,
    bufferBehindMs: secondsToMilliseconds(30),
    bufferAheadMs: minutesToMilliseconds(1.5),
    windowSizeMs: secondsToMilliseconds(30),
    chunkSizeMs: secondsToMilliseconds(15),
  });

  function selectField(field: Field, type: string) {
    if (fields.length >= 3) {
      return;
    }

    if (fields.includes(field)) {
      return;
    }

    if (type !== "number") {
      return;
    }

    onChangeFields([...fields, field]);
  }

  function removeField(field: Field) {
    onChangeFields(_.without(fields, field));
  }

  if (
    logQuery.isLoading ||
    recordsWindow.query.isLoading ||
    timestampMs === undefined ||
    boundsMs === undefined
  ) {
    return <Loading type="circular" />;
  } else if (logQuery.isError || recordsWindow.query.isError) {
    return (
      <Error>
        <Typography variant="h5" component="p" color="error">
          An error occurred. Couldn't get timeline data
        </Typography>
      </Error>
    );
  } else {
    // The last item that was published during the current window's
    // time range or the item whose timestamp matches the current
    // playback timestamp
    const currentItem = _.findLast(
      recordsWindow.query.data!,
      (datum) => datum.timestampMs <= timestampMs
    );

    let body: React.ReactNode;
    if (currentItem === undefined) {
      body = (
        <Center>
          <TimerSand fontSize="large" />
          <Typography variant="h5" component="p">
            No recent message
          </Typography>
          {firstMessageQuery.data != null &&
            timestampMs < firstMessageQuery.data && (
              <NavigationLink
                sx={{ mt: 4 }}
                to={paths.toLog(logId, "records", {
                  t: firstMessageQuery.data,
                })}
                variant="h5"
              >
                Jump to first message
              </NavigationLink>
            )}
        </Center>
      );
    } else {
      let selection: React.ReactNode;
      if (fields.length > 0) {
        selection = (
          <Stack direction="row" spacing={1}>
            {fields.map((field) => (
              <Chip
                key={field}
                label={field}
                onDelete={() => removeField(field)}
              />
            ))}
          </Stack>
        );
      } else {
        selection = (
          <Typography paragraph>
            <i>No fields selected</i>
          </Typography>
        );
      }

      body = (
        <Box
          sx={{
            px: 2,
            "& *": {
              // Until I get the time to add MUI's CSS baseline
              // to the app and confirm things look well, this
              // will have to be here since the TreeItem assumes
              // everything has 'box-sizing: "border-box"' applied
              // and without it they overflow their container
              boxSizing: "border-box",
            },
          }}
        >
          <Box mb={2}>
            <Typography paragraph>
              {fields.length} / 3 fields selected
            </Typography>
            <Box mb={2}>{selection}</Box>
            <Button
              disabled={fields.length === 0}
              color="primary"
              variant="contained"
              onClick={onGraph}
            >
              Graph Fields
            </Button>
          </Box>
          <JsonTree src={currentItem.message_data} handleSelect={selectField} />
        </Box>
      );
    }

    const isActive = ({ timestampMs }: { timestampMs: number }) =>
      timestampMs === currentItem?.timestampMs;

    return (
      <Stack spacing={2} height={1} width={1} overflow="hidden">
        <VictoryChart
          height={75}
          width={width}
          domain={{ x: [0, logQuery.data!.duration!], y: [0, 1] }}
          containerComponent={
            <VictoryZoomContainer
              allowPan={false}
              allowZoom={false}
              zoomDimension="x"
              zoomDomain={{ x: recordsWindow.windowBoundsMs }}
              responsive={false}
              clipContainerComponent={
                <VictoryClipContainer clipPadding={{ left: 1, right: 1 }} />
              }
            />
          }
          padding={{ left: 0, top: 30, bottom: 0, right: 0 }}
          theme={VictoryTheme.material}
        >
          <VictoryAxis
            orientation="top"
            tickFormat={(t) =>
              formatTimestamp(t, { precision: 1, relativeToMs: boundsMs[0] })
            }
          />
          <VictoryScatter
            data={recordsWindow.query.data}
            x="timestampMs"
            y={(datum) => (isActive(datum) ? 0.8 : 0.4)}
            size={7}
            style={{
              data: {
                fill: ({ datum }) => (isActive(datum) ? "tomato" : "darkgrey"),
                stroke: "white",
                strokeWidth: 1,
              },
            }}
          />
          <VictoryLine x={() => timestampMs} />
        </VictoryChart>
        <Divider />
        <Box sx={{ flex: 1, minHeight: 0, overflowY: "auto" }}>{body}</Box>
      </Stack>
    );
  }
}
