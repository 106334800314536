import React from "react";
import { Skeleton, Typography } from "@mui/material";
import { useCurrentLog } from "../../hooks";
import { formatTimestamp } from "../../new-utils";
import { Label, Log } from "../../queries";
import * as paths from "../../views/paths";
import NavigationLink from "../NavigationLink";

export interface TimeRangeProps {
  label: Label;
  asLink?: boolean;
  keepExistingSearch?: boolean;
  useTypography?: boolean;
}

interface EdgeData {
  text: string;
  value: number;
}

export default function TimeRange({
  label,
  asLink = false,
  keepExistingSearch = false,
  useTypography = true,
}: TimeRangeProps) {
  const logQuery = useCurrentLog();

  if (!logQuery.isSuccess) {
    return <Skeleton component="span" width="20ch" />;
  }

  const log = logQuery.data;

  const { start, end } = calculateLabelRangeText(label, log);

  function formatAsLink({ text, value }: EdgeData) {
    return (
      <NavigationLink to={paths.toLog(log.id, "records", { t: value })}>
        {text}
      </NavigationLink>
    );
  }

  function renderEdge(edge: EdgeData) {
    const node = asLink ? formatAsLink(edge) : edge.text;

    if (useTypography) {
      return <Typography component="span">{node}</Typography>;
    } else {
      return node;
    }
  }

  return (
    <>
      {renderEdge(start)}
      {end !== null && (
        <>
          {" - "}
          {renderEdge(end)}
        </>
      )}
    </>
  );
}

function calculateLabelRangeText(label: Label, log: Log) {
  const logStartMs = Number(log.startDate);

  let start: EdgeData;
  let end: EdgeData | null;

  if (label.endTimeMs === null) {
    start = {
      text: formatTimestamp(label.startTimeMs, { relativeToMs: logStartMs }),
      value: label.startTimeMs,
    };

    end = null;
  } else {
    start = {
      text: formatTimestamp(label.startTimeMs, {
        relativeToMs: logStartMs,
      }),
      value: label.startTimeMs,
    };

    end = {
      text: formatTimestamp(label.endTimeMs, { relativeToMs: logStartMs }),
      value: label.endTimeMs,
    };
  }

  return { start, end };
}
