import React, { useState } from "react";
import { Box } from "@mui/material";
import _ from "lodash";
import "maplibre-gl/dist/maplibre-gl.css";
import ReactMapGL, {
  Layer,
  NavigationControl,
  Source,
  WebMercatorViewport,
} from "react-map-gl";

export default function TrajectoryMap({
  height,
  width,
  transformRequest,
  mapStyle,
  trajectoryData,
  timestampMs = null,
}) {
  const [viewport, setViewport] = useState(() =>
    new WebMercatorViewport({ height, width }).fitBounds(
      [
        [trajectoryData.minLon, trajectoryData.minLat],
        [trajectoryData.maxLon, trajectoryData.maxLat],
      ],
      { padding: 20 }
    )
  );

  let pathData, playbackData;
  if (timestampMs != null) {
    // For a given timestamp, binary search through the ordered list of
    // timestamps and find the index below which every timestamp in the
    // array will be less than or equal to the current timestamp. This
    // can be used to determine what points should be included in the
    // playback path based on current playback time.
    const playbackIndex = _.sortedLastIndex(
      trajectoryData.timestamps,
      timestampMs
    );

    playbackData = trajectoryData.positions.slice(0, playbackIndex);
    pathData = trajectoryData.positions.slice(Math.max(0, playbackIndex - 1));
  } else {
    pathData = trajectoryData.positions;
  }

  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      height="100%"
      transformRequest={transformRequest}
      mapStyle={mapStyle}
      onViewportChange={setViewport}
    >
      <Box position="absolute" left={20} top={20}>
        <NavigationControl showCompass={false} />
      </Box>
      <Source
        id="path"
        type="geojson"
        data={{
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: pathData,
          },
        }}
      >
        <Layer
          type="line"
          paint={{
            "line-color": "blue",
            "line-width": 3,
          }}
        />
      </Source>
      {playbackData != null && (
        <>
          <Source
            id="playback-path"
            type="geojson"
            data={{
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: playbackData,
              },
            }}
          >
            <Layer
              type="line"
              paint={{
                "line-color": "red",
                "line-width": 3,
              }}
            />
          </Source>
          {playbackData.length > 0 && (
            <Source
              id="playback-point"
              type="geojson"
              data={{
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Point",
                  coordinates: _.last(playbackData),
                },
              }}
            >
              <Layer
                type="circle"
                paint={{
                  "circle-color": "red",
                }}
              />
            </Source>
          )}
        </>
      )}
    </ReactMapGL>
  );
}
