import { secondsToMilliseconds } from "date-fns";
import { floor } from "lodash";
import { Log, Topic, useRecordsQueries } from "../../../queries";

export default function useFirstMessage(
  logId: Log["id"],
  topicId: Topic["id"]
) {
  const [query] = useRecordsQueries([
    {
      searchOpts: {
        logId,
        topicId,
        pageSize: 1,
        sort: "asc",
        field: "timestamp",
      },
      queryOpts: {
        staleTime: Infinity,
        cacheTime: 1_000 * 20,
        select(recordList) {
          const [firstMessage] = recordList.data;

          return floor(secondsToMilliseconds(firstMessage.timestamp), 1);
        },
      },
    },
  ]);

  return query;
}
