import { areIntervalsOverlapping } from "date-fns";
import _ from "lodash";
import { formatTimestamp } from "./new-utils";

const LON_MAX = 180;
const LON_MIN = -180;
// Min and max latitude need to be clamped between [-85, 85] as opposed to
// [-90, 90]. See link with reference to these values:
// https://math.gl/modules/web-mercator/docs/developer-guide/about-coordinates#lnglat-coordinates
const LAT_MAX = 85;
const LAT_MIN = -85;

export const logDistanceFormatter = new Intl.NumberFormat(
  navigator.language ?? undefined,
  {
    style: "unit",
    unit: "kilometer",
    unitDisplay: "short",
    maximumFractionDigits: 2,
  }
);

export function px(num) {
  return `${num}px`;
}

export function trajectoryMessagesToPath(messages) {
  // These two arrays are linked to other: for a given timestamp located at index
  // 'i' in the 'timestamps' array, the corresponding (lon, lat) pair will be
  // located at index 'i' in the 'positions' array. For quick operations and
  // varied use cases, these need to be stored separately in their own arrays,
  // not in an object or Map
  const positions = [],
    timestamps = [];

  // Min values are purposely being assigned to max variables here and vice versa.
  // They just serve as default values to be supplied to Math.min and Math.max later.
  // That are almost guaranteed to be replaced by a lat or lon value from the
  // supplied data set.
  let maxLon = LON_MIN,
    minLon = LON_MAX,
    maxLat = LAT_MIN,
    minLat = LAT_MAX;

  if (_.some(messages, (message) => message.message_data == null)) {
    // This topic has bad messages with missing data, so we should
    // just throw it away instead of trying to use broken data
    return null;
  }

  messages.forEach((message) => {
    const data = message.message_data;

    // Three tasks for each trajectory point:
    // 1. There can sometimes be junk points in the log that screw with the
    //    calculations and display. They tend to be around values of 0 for
    //    latitude or longitude which is way out of the way for what we can
    //    expect (unless the jeep went swimming to the equator). Remove those
    //    from the set entirely
    // 2. Push the next (lon, lat) pair of points onto the path being generated.
    //    This path is expected to be used for map generation and assumes the
    //    incoming trajectory points are already ordered in time.
    // 3. For all valid points (points whose lon and lat values are within
    //    the allowed value ranges), recalculate the lon and lat extremes for
    //    this set of points. After all points are iterated, the max/min
    //    lon/lat variables will contain the extremes for the set and can be
    //    used for calculating the geographic center of the set.
    if (data.longitude === 0 || data.latitude === 0) {
      return;
    }

    const lon = data.longitude,
      lat = data.latitude,
      timestampMs = message.timestampMs;

    positions.push([lon, lat]);
    timestamps.push(timestampMs);

    if (lon >= LON_MIN && lon <= LON_MAX && lat >= LAT_MIN && lat <= LAT_MAX) {
      maxLon = Math.max(maxLon, lon);
      minLon = Math.min(minLon, lon);

      maxLat = Math.max(maxLat, lat);
      minLat = Math.min(minLat, lat);
    }
  });

  return {
    positions,
    timestamps,
    maxLon,
    minLon,
    maxLat,
    minLat,
    center: {
      lon: (maxLon + minLon) / 2,
      lat: (maxLat + minLat) / 2,
    },
  };
}

export function sToMs(s, { precisionModifier = (num) => num } = {}) {
  return Math.trunc(precisionModifier(s) * 1000);
}

export function msToS(ms, { precision = 1 } = {}) {
  return _.round(ms / 1000, precision);
}

export function getRangeOverlaps(newRange, topicRanges) {
  function doRangesOverlap(r1, r2) {
    return areIntervalsOverlapping(
      { start: r1.startTimeMs, end: r1.endTimeMs },
      { start: r2.startTimeMs, end: r2.endTimeMs }
    );
  }

  return _.filter(topicRanges, (existingRange) =>
    doRangesOverlap(newRange, existingRange)
  );
}

export function mergeAddRange(newRange, topic) {
  const overlaps = getRangeOverlaps(newRange, topic.ranges);

  let filteredRanges = topic.ranges;
  let rangeToAdd = newRange;
  if (overlaps.length > 0) {
    filteredRanges = _.differenceWith(filteredRanges, overlaps, _.isEqual);

    const earliestRange = _.minBy([...overlaps, newRange], "startTimeMs");
    const latestRange = _.maxBy([...overlaps, newRange], "endTimeMs");

    rangeToAdd = {
      ...newRange,
      startTimeMs: earliestRange.startTimeMs,
      endTimeMs: latestRange.endTimeMs,
    };
  }

  return [...filteredRanges, rangeToAdd];
}

export function formatTimeRange(rangeStartMs, rangeEndMs, logStartDate) {
  return `${formatTimestamp(rangeStartMs, {
    relativeToMs: Number(logStartDate),
    precision: 1,
  })} - ${formatTimestamp(rangeEndMs, {
    relativeToMs: Number(logStartDate),
    precision: 1,
  })}`;
}

export function formatTimePoint(
  timePointMs,
  logDurationMs,
  { replaceNullWith = "Start" } = {}
) {
  if (timePointMs == null) {
    return replaceNullWith;
  } else if (timePointMs === 0) {
    return "Start";
  } else if (timePointMs === logDurationMs) {
    return "End";
  } else {
    return formatTimestamp(timePointMs);
  }
}

export function pluralize(num, singular, plural = `${singular}s`) {
  return `${num} ${num === 1 ? singular : plural}`;
}
