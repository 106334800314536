import React from "react";
import { ContentCopy } from "@mui/icons-material";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import prettyBytes from "pretty-bytes";
import CopyToClipboard from "react-copy-to-clipboard";
import { Ingestion } from "../../queries";
import { renderAttr } from "../../search-list";
import * as paths from "../../views/paths";
import NavigationLink from "../NavigationLink";
import StatusChip from "../StatusChip";

export interface IngestionListItemProps {
  ingestion?: Ingestion;
  showLinks?: boolean;
}

export default function IngestionListItem({
  ingestion,
  showLinks = false,
}: IngestionListItemProps) {
  const { enqueueSnackbar } = useSnackbar();

  let date, id, status, format, size, bucket, link;
  if (ingestion === undefined) {
    date = <Skeleton width="25ch" />;

    id = <Skeleton width="36ch" />;

    status = undefined;

    format = <Skeleton width="6ch" />;

    size = <Skeleton width="6ch" />;

    bucket = <Skeleton width="15ch" />;

    link = undefined;
  } else {
    const s3Uri = `s3://${ingestion.s3.bucket}/${ingestion.s3.key}`;

    date = ingestion.createdAt.toUTCString().replace("GMT", "UTC");

    id = ingestion.id;

    status = <StatusChip status={ingestion.status} />;

    format = ingestion.format === null ? "-" : ingestion.format.toUpperCase();

    size =
      ingestion.size === null
        ? "-"
        : prettyBytes(ingestion.size, { maximumFractionDigits: 1 });

    bucket = (
      <Box position="relative">
        <span title={s3Uri}>{ingestion.s3.bucket}</span>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            transform: "translate(100%, -50%)",
          }}
        >
          <CopyToClipboard
            text={s3Uri}
            onCopy={(_, result) => {
              if (result) {
                enqueueSnackbar("S3 URI copied", {
                  variant: "success",
                });
              } else {
                enqueueSnackbar("Unable to copy S3 URI", {
                  variant: "error",
                });
              }
            }}
          >
            <Tooltip title="Copy S3 URI to clipboard">
              <IconButton>
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </CopyToClipboard>
        </Box>
      </Box>
    );

    link = (
      <NavigationLink to={paths.toLog(ingestion.logId)}>
        View log details
      </NavigationLink>
    );
  }

  return (
    <ListItem sx={{ alignItems: "stretch" }} divider>
      <ListItemText disableTypography>
        <Typography>{date}</Typography>
        <Stack direction="row" alignItems="center">
          <Typography variant="h6" component="p" mr={2}>
            {id}
          </Typography>
          {status}
        </Stack>
        <List
          sx={{
            display: "flex",
            alignItems: "center",
            "& .MuiListItem-root": {
              width: "auto",
              "&:first-of-type": {
                pl: 0,
              },
            },
          }}
        >
          {renderAttr("Format", format)}
          {renderAttr("Size", size)}
          {renderAttr("S3 Bucket", bucket)}
        </List>
        {showLinks && link}
      </ListItemText>
    </ListItem>
  );
}
