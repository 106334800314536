import React, { useEffect } from "react";
import {
  Content,
  EdgeTrigger,
  Subheader,
  useLayoutCtx,
} from "@mui-treasury/layout";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { TabContext, TabList } from "@mui/lab";
import { IconButton, Skeleton, Tab, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";
import Header from "../../components/Header";
import config from "../../config";
import { useLog } from "../../queries";
import * as paths from "../paths";
import { LayoutOptions } from "../types";
import Details from "./Details";
import Records from "./Records";

const TabEnumParam = withDefault(
  createEnumParam(["details", "records"]),
  // Reason for 'as const' on default:
  // https://github.com/pbeshai/use-query-params/issues/200#issuecomment-984017032
  "details" as const,
  true
);

function Log() {
  const [tab, setTab] = useQueryParam("tab", TabEnumParam);

  const { enqueueSnackbar } = useSnackbar();

  const { logId } = useParams<paths.LogParams>();
  const logQuery = useLog(logId, {
    onError() {
      enqueueSnackbar("An error occurred trying to fetch the log", {
        variant: "error",
      });
    },
  });

  const { state, setOpen } = useLayoutCtx();

  useEffect(function closeSidebarWhenLeavingRecordsTab() {
    // The 'setOpen' function isn't memoized so there's no point trying
    // to use a dependency array
    if (tab !== "records" && state.rightEdgeSidebar?.open) {
      setOpen("rightEdgeSidebar", false);
    }
  });

  return (
    <TabContext value={tab}>
      <Helmet>
        <title>Log &bull; {config.datastoreInstanceName} DataStore</title>
      </Helmet>
      <Header
        title={
          !logQuery.isSuccess ? (
            <Skeleton width="30ch" />
          ) : (
            logQuery.data.metadata.name
          )
        }
      />
      <Subheader>
        <TabList
          sx={{ height: 48, borderBottom: 1, borderColor: "divider" }}
          onChange={(_, newTab) => setTab(newTab, "replaceIn")}
          aria-label="log tabs"
          variant="scrollable"
          scrollButtons
        >
          <Tab value="details" label="Details" />
          <Tab value="records" label="Records" />
        </TabList>
        {tab === "records" && (
          <EdgeTrigger
            sx={{
              position: "absolute",
              right: (theme) => theme.spacing(0.5),
              top: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            target={{ anchor: "right", field: "open" }}
          >
            {(open, setOpen) => (
              <Tooltip title="Extractions and labels">
                <IconButton onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
              </Tooltip>
            )}
          </EdgeTrigger>
        )}
      </Subheader>
      <Content
        sx={{
          "& > .MuiTabPanel-root": {
            height: 1,
            width: 1,
            p: 0,
            overflowY: "auto",
          },
        }}
      >
        <Details />
        <Records />
      </Content>
    </TabContext>
  );
}

const layoutOptions: LayoutOptions = {
  scheme: {
    subheader: {
      config: {
        xs: {
          position: "relative",
          height: 48,
        },
      },
    },
    rightEdgeSidebar: {
      config: {
        xs: {
          variant: "temporary",
          width: "min(80%, 400px)",
        },
        lg: {
          variant: "persistent",
          persistentBehavior: "fit",
          width: 400,
        },
      },
    },
  },
};
Log.layoutOptions = layoutOptions;

export default Log;
