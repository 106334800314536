import React, { PropsWithChildren, useCallback, useReducer } from "react";
import invariant from "invariant";
import { clamp } from "lodash";
import { createSafeContext } from "../../../contexts";
import { PlaybackSpeed, Timestep } from "../types";

type PlaybackSpeedValue = typeof PlaybackSpeed[keyof typeof PlaybackSpeed];
type TimestepValue = typeof Timestep[keyof typeof Timestep];

type SeekValue = number | "prev" | "next";

interface LoadingPlaybackContextValue {
  isLoading: true;
  boundsMs: undefined;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  timestampMs: undefined;
  isInitialTime: boolean;
  setTimestampMs: (to: SeekValue, isPlaying?: boolean) => void;
  playbackSpeed: PlaybackSpeedValue;
  setPlaybackSpeed: (speed: PlaybackSpeedValue) => void;
  timestep: TimestepValue;
  setTimestep: (step: TimestepValue) => void;
}

interface LoadedPlaybackContextValue {
  isLoading: false;
  boundsMs: [number, number];
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  timestampMs: number;
  isInitialTime: boolean;
  setTimestampMs: (to: SeekValue, isPlaying?: boolean) => void;
  playbackSpeed: PlaybackSpeedValue;
  setPlaybackSpeed: (speed: PlaybackSpeedValue) => void;
  timestep: TimestepValue;
  setTimestep: (step: TimestepValue) => void;
}

type PlaybackContextValue =
  | LoadingPlaybackContextValue
  | LoadedPlaybackContextValue;

export type PlaybackProviderProps = PropsWithChildren<{
  /**
   * UTC timestamps representing the upper and lower playback bounds in
   * milliseconds. All timestamps will be clamped to within these bounds.
   * Conceptually, the lower bound can be considered t = 0 and can be used for
   * displaying UTC timestamps relative to how long after this time they
   * occurred. Set this value to undefined if it needs to be fetched
   * asynchronously. Trying to perform playback operations while this is
   * undefined will result in an error.
   */
  boundsMs?: [number, number];
  /**
   * A UTC timestamp in milliseconds representing the default time at which
   * playback should start. Useful if loading a time from an external source
   * like a URL query param. If not given the default time will be the
   * lower playback bound. Will be clamped within `boundMs` before being
   * passed through the context
   */
  initialTimeMs?: number;
}>;

type PlaybackReducerState = Pick<
  PlaybackContextValue,
  "isPlaying" | "timestampMs" | "playbackSpeed" | "timestep"
>;

type PlaybackAction =
  | { type: "set-playing"; payload: boolean }
  | {
      type: "seek";
      payload: {
        to: SeekValue;
        isPlaying?: boolean;
      };
    }
  | { type: "set-speed"; payload: PlaybackSpeedValue }
  | { type: "set-step"; payload: TimestepValue };

export const [usePlayback, PlaybackContext] =
  createSafeContext<PlaybackContextValue>("Playback");

const initialState: PlaybackReducerState = {
  isPlaying: false,
  timestampMs: undefined,
  playbackSpeed: PlaybackSpeed.TimesTen,
  timestep: Timestep.Second,
};

export function PlaybackProvider({
  boundsMs,
  initialTimeMs,
  children,
}: PlaybackProviderProps) {
  const clampedInitialTimeMs =
    boundsMs !== undefined
      ? // Since initial time comes from an outside source like a URL, there's
        // no guarantee it's within playback bounds
        clamp(initialTimeMs ?? boundsMs[0], ...boundsMs)
      : undefined;

  const [playbackState, dispatch] = useReducer(
    makeReducer(boundsMs, clampedInitialTimeMs),
    initialState
  );

  const setIsPlaying = useCallback(
    (isPlaying: boolean) =>
      dispatch({
        type: "set-playing",
        payload: isPlaying,
      }),
    []
  );

  const setTimestampMs = useCallback(
    (to: SeekValue, isPlaying?: boolean) =>
      dispatch({
        type: "seek",
        payload: { to, isPlaying },
      }),
    []
  );

  const setPlaybackSpeed = useCallback(
    (speed: PlaybackSpeedValue) =>
      dispatch({
        type: "set-speed",
        payload: speed,
      }),
    []
  );

  const setTimestep = useCallback(
    (step: TimestepValue) =>
      dispatch({
        type: "set-step",
        payload: step,
      }),
    []
  );

  const timestampMs = playbackState.timestampMs ?? clampedInitialTimeMs;

  return (
    <PlaybackContext.Provider
      value={
        {
          isLoading: boundsMs === undefined || timestampMs === undefined,
          boundsMs,
          isPlaying: playbackState.isPlaying,
          setIsPlaying,
          timestampMs,
          isInitialTime: playbackState.timestampMs === undefined,
          setTimestampMs,
          playbackSpeed: playbackState.playbackSpeed,
          setPlaybackSpeed,
          timestep: playbackState.timestep,
          setTimestep,
        } as PlaybackContextValue
      }
    >
      {children}
    </PlaybackContext.Provider>
  );
}

function makeReducer(
  boundsMs: PlaybackProviderProps["boundsMs"],
  initialTimeMs: PlaybackProviderProps["initialTimeMs"]
) {
  return function reducer(state: PlaybackReducerState, action: PlaybackAction) {
    invariant(
      boundsMs !== undefined && initialTimeMs !== undefined,
      "Bounds and initial time must be defined to use reducer"
    );

    switch (action.type) {
      case "set-playing":
        return {
          ...state,
          isPlaying: action.payload,
        };
      case "seek":
        if (action.payload.to === state.timestampMs) {
          // Bail out of state update if timestamp would otherwise
          // stay the same. This does ignore the 'isPlaying' param
          return state;
        }

        let unboundedTimestampMs: number;
        if (typeof action.payload.to === "number") {
          unboundedTimestampMs = action.payload.to;
        } else {
          const currentTimestampMs = state.timestampMs ?? initialTimeMs;

          const offsetMs = state.timestep === Timestep.Second ? 1_000 : 100;

          if (action.payload.to === "prev") {
            unboundedTimestampMs = currentTimestampMs - offsetMs;
          } else {
            unboundedTimestampMs = currentTimestampMs + offsetMs;
          }
        }

        const [lowerBoundMs, upperBoundMs] = boundsMs;

        const timestampMs = clamp(
          unboundedTimestampMs,
          lowerBoundMs,
          upperBoundMs
        );

        let isPlaying: boolean;
        if (timestampMs === upperBoundMs) {
          isPlaying = false;
        } else if (action.payload.isPlaying !== undefined) {
          isPlaying = action.payload.isPlaying;
        } else {
          isPlaying = state.isPlaying;
        }

        return {
          ...state,
          isPlaying,
          timestampMs,
        };
      case "set-speed":
        return {
          ...state,
          playbackSpeed: action.payload,
        };
      case "set-step":
        return {
          ...state,
          timestep: action.payload,
        };
      default:
        const _exhaustiveCheck: never = action;
        throw new Error(`Unknown playback action type: ${_exhaustiveCheck}`);
    }
  };
}
