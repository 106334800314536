import { useCallback } from "react";
import invariant from "invariant";
import { useImmer } from "use-immer";

export default function usePanelLayout(initialLayoutOrState, isState = false) {
  const [layoutState, setLayoutState] = useImmer(() => {
    let calculatedLayout;
    if (initialLayoutOrState === undefined || isState) {
      calculatedLayout = {
        type: "panel",
        parentId: null,
        id: 0,
        flex: 1,
        state: isState ? initialLayoutOrState : null,
      };
    } else {
      calculatedLayout = initialLayoutOrState;
    }

    const nextId = nextIdFromLayout(calculatedLayout);

    return {
      root: calculatedLayout,
      nextId,
    };
  });

  const split = useCallback(
    (panelId, orientation, newPanelState = null) =>
      setLayoutState((draft) => {
        const node = findById(draft.root, panelId);

        invariant(node.type === "panel", "Only panel nodes can be split");

        invariant(
          ["horizontal", "vertical"].includes(orientation),
          `Unknown split orientation: '${orientation}'. 
            Allowed values are 'horizontal' and 'vertical'`
        );

        const newPanel = {
          type: "panel",
          parentId: null,
          id: draft.nextId++,
          flex: 0.5,
          state: newPanelState,
        };

        const newContainer = {
          type: "container",
          parentId: node.parentId,
          id: draft.nextId++,
          flex: node.flex,
          orientation,
          firstChild: node,
          secondChild: newPanel,
        };

        // Replace the node in the tree *before* reassigning IDs
        replaceNode(draft, node, newContainer);

        node.parentId = newContainer.id;
        newPanel.parentId = newContainer.id;

        // When a node is split and replaced in the tree by a container,
        // that container should get the node's old flex value to preserve
        // the relative position the node had with its old sibling. The
        // node and its new sibling (both inside the container now) should
        // each have a flex of 0.5 so they'll start out splitting the
        // available space evenly
        node.flex = 0.5;
      }),
    [setLayoutState]
  );

  const remove = useCallback(
    (panelId, newPanelState = null) =>
      setLayoutState((draft) => {
        const node = findById(draft.root, panelId);

        invariant(node.type === "panel", "Only panel nodes can be removed");

        if (node.parentId === null) {
          // Node is the root of the tree and the only element in the tree.
          // Start over with a fresh panel with optional state
          draft.root = {
            type: "panel",
            parentId: null,
            id: draft.nextId++,
            flex: 1,
            state: newPanelState,
          };
        } else {
          // The node is somewhere deeper in the tree so we need to replace its
          // parent container with its sibling. If its parent is the root,
          // we replace the entire tree with the node's sibling. If its parent
          // is not the root, then we go to the base node's grandparent,
          // replacing the base node's parent with the base node's sibling.
          const parent = findById(draft.root, node.parentId);
          const remainingSibling =
            node === parent.firstChild ? parent.secondChild : parent.firstChild;

          replaceNode(draft, parent, remainingSibling);

          remainingSibling.parentId = parent.parentId;

          if (parent.parentId === null) {
            // The remaining sibling is going to be the last node in the tree
            // so it should have a flex value of 1, meaning it'll take up all
            // the space
            remainingSibling.flex = 1;
          } else {
            // Since the remaining sibling will be taking the place of its
            // parent container, it's flex value (if it has one) needs to be
            // replaced with it's parent so it'll take up the same space as the
            // parent did
            remainingSibling.flex = parent.flex;
          }
        }
      }),
    [setLayoutState]
  );

  const resize = useCallback(
    (nodeId, flex) =>
      setLayoutState((draft) => {
        findById(draft.root, nodeId).flex = flex;
      }),
    [setLayoutState]
  );

  const update = useCallback(
    (panelId, state) =>
      setLayoutState((draft) => {
        const node = findById(draft.root, panelId);

        invariant(
          node.type === "panel",
          "Only panel nodes can have state updated"
        );

        node.state = state;
      }),
    [setLayoutState]
  );

  const setLayout = useCallback(
    (newLayout) =>
      setLayoutState((draft) => {
        const nextId = nextIdFromLayout(newLayout);

        draft.root = newLayout;
        draft.nextId = nextId;
      }),
    [setLayoutState]
  );

  return {
    layout: layoutState.root,
    split,
    remove,
    resize,
    update,
    setLayout,
  };
}

// Adapted from https://stackoverflow.com/a/50590586
/**
 * Performs a breadth-first traversal of the tree starting from the root,
 * calling the visitor function for each node as it is reached. To cancel
 * the traversal before every node is visited, the visitor can return
 * false; otherwise, the visitor should not return anything
 *
 * @param root the root of the tree to traverse
 * @param visitor a function to be called on each node found during the
 *        traversal. Can return false to stop traversal early
 */
function walk(root, visitor) {
  const queue = [root];

  while (queue.length > 0) {
    const currNode = queue.shift();

    if (visitor(currNode) === false) {
      break;
    }

    if (currNode.type === "container") {
      // Push children onto queue to continue with traversal
      queue.push(currNode.firstChild, currNode.secondChild);
    }
  }
}

/**
 * Traverses the tree starting at root looking for a node with the given ID.
 * Returns the node if found, otherwise throws an error
 *
 * @param root the root of the tree to search
 * @param id the ID of the node to search for
 * @return the node matching the ID if found
 * @throws {Error} if no node with the ID could be found
 */
function findById(root, id) {
  let maybeNode = undefined;

  function visit(node) {
    if (node.id === id) {
      maybeNode = node;
      return false;
    }
  }

  walk(root, visit);

  invariant(maybeNode !== undefined, `No node with ID ${id} could be found`);

  return maybeNode;
}

function nextIdFromLayout(node) {
  let maxId = node.id;

  function visitor(node) {
    maxId = Math.max(maxId, node.id);
  }

  walk(node, visitor);

  return maxId + 1;
}

function replaceNode(draft, node, withNode) {
  if (node.parentId === null) {
    draft.root = withNode;
  } else {
    const parent = findById(draft.root, node.parentId);

    if (node === parent.firstChild) {
      parent.firstChild = withNode;
    } else {
      parent.secondChild = withNode;
    }
  }
}
