import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { AccessTime, DoubleArrow, FolderSpecial } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import invariant from "invariant";
import { difference, filter, find, map, some, sortBy } from "lodash";
import { bindTrigger } from "material-ui-popup-state/hooks";
import { ClockEnd, ClockStart } from "mdi-material-ui";
import { useParams } from "react-router-dom";
import { formatTimestamp } from "../../../new-utils";
import { useDraftExtraction } from "../../../providers/DraftExtractionProvider";
import { Topic, useLogTopics } from "../../../queries";
import * as paths from "../../paths";
import { ExtractionFormState, TfStaticState } from "../Records";
import { usePlayback } from "../contexts";
import { ProfileManager, TopicSelect } from "./index";

type ExtractionAction =
  | "from-start"
  | "from-now"
  | "shift-end"
  | "to-now"
  | "to-end";

export interface ExtractionFormProps {
  extractionFormState: ExtractionFormState;
  setExtractionFormState: React.Dispatch<
    React.SetStateAction<ExtractionFormState>
  >;
}

export default function ExtractionForm({
  extractionFormState,
  setExtractionFormState,
}: ExtractionFormProps) {
  const { extractionRange, tfStaticState, selectedTopics } =
    extractionFormState;

  const setTfStaticState = useCallback(
    (newTfStaticState: TfStaticState) =>
      setExtractionFormState((oldFormState) => ({
        ...oldFormState,
        tfStaticState: newTfStaticState,
      })),
    [setExtractionFormState]
  );

  const [missingProfileTopics, setMissingProfileTopics] = useState<
    Topic["name"][]
  >([]);

  const { logId } = useParams<paths.LogParams>();

  const topicsQuery = useLogTopics(logId);

  const { boundsMs, timestampMs } = usePlayback();

  const { isInitialized, extractionTopics, addRanges, removeTopic } =
    useDraftExtraction();

  const topics = topicsQuery.data;

  const computedExtractionRange = extractionRange ?? boundsMs;

  const rangeReady = computedExtractionRange !== undefined;
  const playbackReady = boundsMs !== undefined && timestampMs !== undefined;
  const actionsDisabled =
    !isInitialized || !rangeReady || !playbackReady || topics === undefined;

  useEffect(() => {
    if (!actionsDisabled && tfStaticState === undefined) {
      const logTfStatic = find(topics, { name: "/tf_static" });

      if (logTfStatic === undefined) {
        // No /tf_static topic in this log
        setTfStaticState("unavailable");
      } else {
        // /tf_static topic exists. Default behavior is to automatically
        // add it to possible extraction since it's such a common and
        // necessary topic
        addRanges({ topic: logTfStatic });
        setTfStaticState("added-automatically");
      }
    }
  }, [actionsDisabled, tfStaticState, topics, addRanges, setTfStaticState]);

  useEffect(() => {
    if (isInitialized && tfStaticState !== undefined) {
      const hasTfStatic = some(extractionTopics, ["topic.name", "/tf_static"]);

      if (
        hasTfStatic &&
        ["removed-automatically", "removed-manually"].includes(tfStaticState)
      ) {
        // /tf_static was added to draft extraction and the last action
        // was a removal (as opposed to being automatically added) which means
        // it was added manually
        setTfStaticState("added-manually");
      }

      if (
        !hasTfStatic &&
        ["added-automatically", "added-manually"].includes(tfStaticState)
      ) {
        // /tf_static is no longer in draft and the last action was adding
        // it (as opposed to being removed automatically) which means it
        // was removed manually
        setTfStaticState("removed-manually");
      }
    }
  }, [isInitialized, tfStaticState, extractionTopics, setTfStaticState]);

  function toggleIncludeTfStatic(e: ChangeEvent<HTMLInputElement>) {
    const shouldAdd = e.target.checked;

    const logTfStatic = find(topics, { name: "/tf_static" });

    invariant(
      logTfStatic !== undefined,
      "No /tf_static topic in this log so checkbox value should not change"
    );

    invariant(
      playbackReady,
      "Checkbox value changing before playback values known"
    );

    if (shouldAdd) {
      addRanges({ topic: logTfStatic });

      setTfStaticState("added-automatically");
    } else {
      removeTopic(logTfStatic);

      setTfStaticState("removed-automatically");
    }
  }

  function handleExtractionRangeChange(extractionAction: ExtractionAction) {
    invariant(rangeReady, "Extraction range changing before being initialized");

    invariant(
      playbackReady,
      "Extraction range changing before playback values known"
    );

    const [currentStartMs, currentEndMs] = computedExtractionRange;

    let newRange: [number, number];
    switch (extractionAction) {
      case "from-start":
        newRange = [boundsMs[0], currentEndMs];
        break;
      case "from-now":
        newRange = [timestampMs, currentEndMs];
        break;
      case "shift-end":
        newRange = [currentEndMs, boundsMs[1]];
        break;
      case "to-now":
        newRange = [currentStartMs, timestampMs];
        break;
      case "to-end":
        newRange = [currentStartMs, boundsMs[1]];
        break;
      default:
        const _exhaustiveCheck: never = extractionAction;
        throw new Error(`Unknown extraction action: ${_exhaustiveCheck}`);
    }

    setExtractionFormState({
      ...extractionFormState,
      extractionRange: newRange,
    });
    setMissingProfileTopics([]);
  }

  function handleTopicsChange(topics: Topic[]) {
    setExtractionFormState({
      ...extractionFormState,
      selectedTopics: topics,
    });
    setMissingProfileTopics([]);
  }

  function loadTopics(names: Topic["name"][]) {
    const foundTopics = filter(topics, (topic) => names.includes(topic.name));

    setExtractionFormState({
      ...extractionFormState,
      selectedTopics: foundTopics,
    });
    setMissingProfileTopics(difference(names, map(foundTopics, "name")));
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    invariant(
      !actionsDisabled,
      "Attempting to add topics while actions disabled"
    );

    addRanges(
      selectedTopics.map((topic) => ({
        topic,
        range: {
          startTimeMs: computedExtractionRange[0],
          endTimeMs: computedExtractionRange[1],
          sampleFrequency: null,
        },
      }))
    );
  }

  let helperText;
  if (missingProfileTopics?.length > 0) {
    helperText = (
      <div>
        <p>
          The following topics were in your profile but not present in this log:
        </p>
        <ul>
          {sortBy(missingProfileTopics).map((topicName) => (
            <li key={topicName}>{topicName}</li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid sx={{ position: "relative" }} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={actionsDisabled}
                  fullWidth
                  label="From"
                  variant="outlined"
                  value={
                    actionsDisabled
                      ? ""
                      : formatTimestamp(computedExtractionRange[0], {
                          precision: 1,
                          relativeToMs: boundsMs[0],
                        })
                  }
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <>
                        <Tooltip title="Log start">
                          <IconButton
                            disabled={actionsDisabled}
                            size="small"
                            aria-label="Start extracting topics at the start of the log"
                            onClick={() =>
                              handleExtractionRangeChange("from-start")
                            }
                          >
                            <ClockStart />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Current time">
                          <span>
                            <IconButton
                              disabled={
                                actionsDisabled ||
                                timestampMs === boundsMs[1] ||
                                timestampMs >= computedExtractionRange[1]
                              }
                              size="small"
                              aria-label="Start extracting topics at the current playback time"
                              onClick={() =>
                                handleExtractionRangeChange("from-now")
                              }
                            >
                              <AccessTime />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </>
                    ),
                  }}
                />
              </Grid>
              <Tooltip title="Shift end time">
                <Box
                  sx={{
                    zIndex: 1,
                    backgroundColor: "background.paper",
                    borderRadius: "50%",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%) rotate(-90deg)",
                    "& .MuiIconButton-root": {
                      border: 1,
                      borderColor: "rgba(0, 0, 0, 0.23)",
                      "&:hover": {
                        borderColor: "initial",
                      },
                      "&:focus": {
                        borderColor: "primary.main",
                        borderWidth: 2,
                      },
                    },
                  }}
                >
                  <IconButton
                    disabled={
                      actionsDisabled ||
                      computedExtractionRange[1] === boundsMs[1]
                    }
                    aria-label="Shift end time to start time"
                    onClick={() => handleExtractionRangeChange("shift-end")}
                    size="large"
                  >
                    <DoubleArrow />
                  </IconButton>
                </Box>
              </Tooltip>
              <Grid item xs={12}>
                <TextField
                  disabled={actionsDisabled}
                  fullWidth
                  label="To"
                  variant="outlined"
                  value={
                    actionsDisabled
                      ? ""
                      : formatTimestamp(computedExtractionRange[1], {
                          precision: 1,
                          relativeToMs: boundsMs[0],
                        })
                  }
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <>
                        <Tooltip title="Current time">
                          <span>
                            <IconButton
                              disabled={
                                actionsDisabled ||
                                timestampMs === 0 ||
                                timestampMs <= computedExtractionRange[0]
                              }
                              size="small"
                              aria-label="Finish extracting topics at the current playback time"
                              onClick={() =>
                                handleExtractionRangeChange("to-now")
                              }
                            >
                              <AccessTime />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Log end">
                          <IconButton
                            disabled={actionsDisabled}
                            size="small"
                            aria-label="Finish extracting topics at the end of the log"
                            onClick={() =>
                              handleExtractionRangeChange("to-end")
                            }
                          >
                            <ClockEnd />
                          </IconButton>
                        </Tooltip>
                      </>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs zeroMinWidth>
                <TopicSelect
                  width="100%"
                  multiple
                  limitTopics={1}
                  inputLabel="Topics"
                  // TODO: Eventually remove this cast
                  helperText={helperText as any}
                  topics={topics}
                  selection={selectedTopics}
                  onSelectionChange={handleTopicsChange}
                />
              </Grid>
              <Grid item xs="auto">
                <ProfileManager
                  popupId="extraction-profiles-menu"
                  createDescription={
                    "Save your current topic selection to quickly extract in the future"
                  }
                  favoriteDescription={
                    "Its topics will be automatically selected when you go to make an extraction"
                  }
                  profileType="extraction"
                  onSelect={({ names }) => loadTopics(names)}
                  onCreate={() => ({
                    names: selectedTopics.map((topic: Topic) => topic.name),
                  })}
                >
                  {(profiles, popupState) => (
                    <>
                      <Tooltip title="Extraction profiles">
                        <IconButton
                          disabled={actionsDisabled}
                          aria-label="Open extraction profiles menu"
                          {...bindTrigger(popupState)}
                          size="large"
                        >
                          <FolderSpecial />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </ProfileManager>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={actionsDisabled || selectedTopics.length === 0}
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
            >
              Add Topics
            </Button>
          </Grid>
        </Grid>
      </form>
      <Divider sx={{ my: 2 }} />
      <FormControlLabel
        disabled={
          actionsDisabled ||
          tfStaticState === undefined ||
          ["unavailable", "added-manually"].includes(tfStaticState)
        }
        control={
          <Checkbox
            id="include-tf-checkbox"
            checked={tfStaticState === "added-automatically"}
            onChange={toggleIncludeTfStatic}
          />
        }
        label={
          <>
            Automatically include <code>/tf_static</code>
          </>
        }
      />
    </>
  );
}
