import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Settings, Star, StarOutline } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { Checkbox as FormikCheckbox, TextField } from "formik-mui";
import invariant from "invariant";
import {
  capitalize,
  filter,
  find,
  kebabCase,
  map,
  reject,
  sortBy,
  without,
} from "lodash";
import { PopupState } from "material-ui-popup-state/core";
import { bindMenu, usePopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { Profile, useUpdateUser, useUser } from "../../../queries";

type GetProfile<TProfileType extends Profile["type"]> = Extract<
  Profile,
  { type: TProfileType }
>;

export interface ProfileManagerProps<TProfileType extends Profile["type"]> {
  popupId: string;
  createDescription: string;
  favoriteDescription: string;
  profileType: TProfileType;
  onSelect: (
    data: GetProfile<TProfileType>["data"],
    autoloaded: boolean
  ) => void;
  disabled?: boolean;
  onCreate: () => GetProfile<TProfileType>["data"];
  children: (
    profiles: GetProfile<TProfileType>[],
    popupState: PopupState
  ) => React.ReactNode;
}

interface FormikValues {
  name: string;
  isFavorite: boolean;
}

export default function ProfileManager<TProfileType extends Profile["type"]>({
  popupId,
  createDescription,
  favoriteDescription,
  profileType,
  onSelect,
  disabled = false,
  onCreate,
  children,
}: ProfileManagerProps<TProfileType>) {
  const [open, setOpen] = useState(false);
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const onSelectRef = useRef<ProfileManagerProps<TProfileType>["onSelect"]>();
  const favoriteAppliedRef = useRef(false);

  const { enqueueSnackbar } = useSnackbar();

  const userQuery = useUser();

  // 3 instances of the mutation to handle different loading states
  const createProfile = useUpdateUser();
  const favoriteProfile = useUpdateUser();
  const deleteProfile = useUpdateUser();

  const popupState = usePopupState({ variant: "popover", popupId });

  useEffect(() => {
    onSelectRef.current = onSelect;
  }, [onSelect]);

  useEffect(() => {
    if (favoriteAppliedRef.current) {
      return;
    }

    if (disabled) {
      return;
    }

    if (userQuery.data === undefined) {
      return;
    }

    // Even if there's no favorite, this code should only run once when the
    // data first loads. This prevents the favorite from being applied later
    // when changes are made to the profiles
    favoriteAppliedRef.current = true;

    const favoriteProfile = find(userQuery.data.config.profiles, {
      type: profileType,
      isFavorite: true,
    });

    if (favoriteProfile !== undefined) {
      invariant(
        onSelectRef.current !== undefined,
        "onSelectRef.current is undefined. Ensure it is set prior to this effect running"
      );

      onSelectRef.current(
        favoriteProfile.data as GetProfile<TProfileType>["data"],
        true
      );
    }
  }, [disabled, userQuery.data, profileType]);

  const allProfiles = userQuery.data?.config.profiles ?? [];
  const managedProfiles = sortBy(
    filter(allProfiles, {
      type: profileType,
    }),
    "name"
  ) as GetProfile<TProfileType>[];

  const newProfileSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .notOneOf(
        map(managedProfiles, "name"),
        "Profile name taken. Delete the existing one first"
      )
      .required("Profile name is required"),
    isFavorite: yup.boolean().required(),
  });

  function handleClose() {
    setOpen(false);
  }

  async function handleSubmit(
    values: FormikValues,
    { resetForm }: FormikHelpers<FormikValues>
  ) {
    // Formik only validates the values but doesn't give you the cast object
    // returned by yup's validator. That object is needed since it has a
    // 'trim' transformation on the name field. This is safe since the
    // submit handler wouldn't be called unless validation had already passed
    const { name, isFavorite } = newProfileSchema.validateSync(values);

    await handleCreate(name, isFavorite);

    resetForm();
  }

  function handleCreate(
    name: Profile["name"],
    isFavorite: Profile["isFavorite"]
  ) {
    const newProfile = {
      type: profileType,
      name,
      isFavorite,
      data: onCreate(),
    } as unknown as GetProfile<TProfileType>;

    const updatedProfiles = allProfiles.map((profile) => {
      if (profile.type !== profileType) {
        // Ignore profiles of other type
        return profile;
      }

      // If new profile is the favorite, unfavorite others
      return newProfile.isFavorite
        ? { ...profile, isFavorite: false }
        : profile;
    });

    return createProfile.mutateAsync(
      { profiles: [...updatedProfiles, newProfile] },
      {
        onError() {
          enqueueSnackbar("Unable to create profile", {
            variant: "error",
          });
        },
      }
    );
  }

  function handleFavorite(profileToFavorite: GetProfile<TProfileType>) {
    return () => {
      const updatedProfiles = allProfiles.map((profile) => {
        if (profile.type !== profileType) {
          // Ignore profiles of other type
          return profile;
        }

        if (profile.name !== profileToFavorite.name) {
          // Any other profile besides the favorite should not be favorites
          return {
            ...profile,
            isFavorite: false,
          };
        }

        // Toggle this profiles favorite status
        return {
          ...profile,
          isFavorite: !profileToFavorite.isFavorite,
        };
      });

      favoriteProfile.mutate(
        { profiles: updatedProfiles },
        {
          onError() {
            enqueueSnackbar("Unable to update favorite profile", {
              variant: "error",
            });
          },
        }
      );
    };
  }

  function handleToggle(profile: GetProfile<TProfileType>) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelectedNames([...selectedNames, profile.name]);
      } else {
        setSelectedNames(without(selectedNames, profile.name));
      }
    };
  }

  function handleDelete() {
    const updatedProfiles = reject(
      allProfiles,
      (profile) =>
        profile.type === profileType && selectedNames.includes(profile.name)
    );

    deleteProfile.mutate(
      { profiles: updatedProfiles },
      {
        onSuccess() {
          setSelectedNames([]);
        },
        onError() {
          enqueueSnackbar("Unable to delete profile", {
            variant: "error",
          });
        },
      }
    );
  }

  function handleOpen() {
    popupState.close();

    setOpen(true);
  }

  function handleSelect(profile: GetProfile<TProfileType>) {
    return () => {
      popupState.close();

      onSelect(profile.data as GetProfile<TProfileType>["data"], false);
    };
  }

  return (
    <>
      {children(managedProfiles, popupState)}
      <Menu {...bindMenu(popupState)} sx={{ zIndex: "drawer" }}>
        {managedProfiles.map((profile) => (
          <MenuItem key={profile.name} onClick={handleSelect(profile)}>
            {profile.name}
          </MenuItem>
        ))}
        {managedProfiles.length > 0 && (
          <Divider sx={{ my: 1 }} component="li" />
        )}
        <MenuItem disabled={disabled} onClick={handleOpen}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText>Manage profiles...</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        fullWidth
        aria-labelledby="profile-mgmt-dialog"
        open={open}
        onClose={handleClose}
        TransitionProps={{
          onExited() {
            setSelectedNames([]);
            createProfile.reset();
            favoriteProfile.reset();
            deleteProfile.reset();
          },
        }}
      >
        <DialogTitle id="profile-mgmt-dialog">
          Manage {capitalize(profileType)} Profiles
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6" component="p" gutterBottom>
            Create new profile
          </Typography>
          <Typography paragraph>{createDescription}</Typography>
          <Formik<FormikValues>
            initialValues={{ name: "", isFavorite: false }}
            onSubmit={handleSubmit}
            validationSchema={newProfileSchema}
          >
            {() => (
              <Form>
                <Stack direction="row">
                  <Field
                    component={TextField}
                    name="name"
                    fullWidth
                    label="Name"
                    helperText="Profile names are case-insensitive"
                    variant="outlined"
                    autoComplete="off"
                  />
                  <span>
                    <Tooltip title="Set as favorite">
                      <span>
                        <Field
                          component={FormikCheckbox}
                          type="checkbox"
                          name="isFavorite"
                          inputProps={{ "aria-label": "Set as favorite" }}
                          icon={<StarOutline fontSize="large" />}
                          checkedIcon={<Star fontSize="large" />}
                          sx={{ ml: 2 }}
                        />
                      </span>
                    </Tooltip>
                  </span>
                </Stack>
                <LoadingButton
                  sx={{ display: "block", mt: 2 }}
                  loading={createProfile.isLoading}
                  type="submit"
                  variant="outlined"
                  color="primary"
                >
                  Create Profile
                </LoadingButton>
              </Form>
            )}
          </Formik>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" component="p" gutterBottom>
            Your Profiles
            {(favoriteProfile.isLoading || deleteProfile.isLoading) && (
              <CircularProgress size="1rem" sx={{ ml: 2 }} />
            )}
          </Typography>
          {managedProfiles.length > 0 ? (
            <>
              <Typography paragraph>
                Favorite a profile by selecting it below. {favoriteDescription}
              </Typography>
              <List>
                {managedProfiles.map((profile) => {
                  const labelId = `profile-select-${kebabCase(profile.name)}`;

                  return (
                    <ListItem
                      key={profile.name}
                      disablePadding
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(profile)}
                          checked={selectedNames.includes(profile.name)}
                          inputProps={{ "aria-labelledby": labelId }}
                          disabled={
                            favoriteProfile.isLoading || deleteProfile.isLoading
                          }
                        />
                      }
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={handleFavorite(profile)}
                        disabled={
                          favoriteProfile.isLoading || deleteProfile.isLoading
                        }
                      >
                        {profile.isFavorite && (
                          <ListItemIcon>
                            <Star />
                          </ListItemIcon>
                        )}
                        <ListItemText id={labelId} inset={!profile.isFavorite}>
                          {profile.name}
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              <LoadingButton
                disabled={selectedNames.length === 0}
                loading={deleteProfile.isLoading}
                variant="outlined"
                color="error"
                onClick={handleDelete}
              >
                Delete Profiles
              </LoadingButton>
            </>
          ) : (
            <Typography>
              You have no profiles. You can create one above.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
