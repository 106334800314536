import React from "react";
import { Content } from "@mui-treasury/layout";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { LocationDescriptor } from "history";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import Header from "../components/Header";
import config from "../config";
import App from "../views/App";
import * as paths from "../views/paths";

interface MakeCardOptions {
  title: string;
  description?: string | null;
  to: LocationDescriptor;
  external?: boolean;
  buttonText?: string;
}

export default function Racer() {
  return <App homepage={<Homepage />} />;
}

function Homepage() {
  function makeCard({
    title,
    description = null,
    to,
    external = false,
    buttonText = `View ${title}`,
  }: MakeCardOptions) {
    let buttonProps: any;
    if (external) {
      buttonProps = {
        component: "a",
        href: to,
        target: "_blank",
        rel: "noreferrer noopener external",
      };
    } else {
      buttonProps = {
        component: RouterLink,
        to,
      };
    }

    return (
      <Grid item xs={10} md={5}>
        <Card elevation={4}>
          <CardContent>
            <Typography variant="h6" component="h2" gutterBottom>
              {title}
            </Typography>
            {description && <Typography paragraph>{description}</Typography>}
          </CardContent>
          <CardActions>
            <Button {...buttonProps} color="primary">
              {buttonText}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>Home &bull; RACER DataStore</title>
      </Helmet>
      <Header title="Home" />
      <Content sx={{ flexDirection: "column", overflowY: "auto" }}>
        <Box
          sx={{
            p: 4,
            flexGrow: 1,
            backgroundImage: 'url("/ds_bg_0.jpg")',
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Container>
            <Box
              sx={{
                p: 3,
                background:
                  "radial-gradient(circle at 20% 50%, black 0, transparent 100%)",
                borderRadius: 2,
                color: "white",
                fontWeight: 400,
              }}
            >
              <Typography fontWeight="inherit" variant="h1" gutterBottom>
                RACER Data
                <wbr />
                Store
              </Typography>
              <Typography fontWeight="inherit" variant="h4" component="p">
                Welcome to the RACER DataStore!
              </Typography>
            </Box>
            <Grid
              sx={{
                mt: 4,
                flexWrap: "wrap",
              }}
              container
              justifyContent="space-evenly"
              spacing={4}
            >
              {makeCard({
                title: "Logs",
                to: paths.toLogSearch({ status: "processed" }),
              })}
              {makeCard({
                title: "Extractions",
                to: paths.toExtractionSearch(),
              })}
              {makeCard({
                title: "Guide",
                external: true,
                to: config.home.guide.href,
              })}
              {makeCard({
                title: "API Docs",
                external: true,
                to: config.home.api.href,
              })}
              {makeCard({
                title: "ICD",
                external: true,
                to: config.home.icd.href,
              })}
              {makeCard({
                title: "RACER Fleet Vehicle Docs / Troubleshooting",
                external: true,
                to: config.home.fleet.href,
                buttonText: "View Docs",
              })}
            </Grid>
          </Container>
        </Box>
        <Box
          width={300}
          component="a"
          href="https://carnegierobotics.com/"
          target="_blank"
          rel="noreferrer noopener external"
        >
          <Box
            sx={{
              objectFit: "contain",
              width: "inherit",
              height: "auto",
            }}
            component="img"
            src="/crl_logo_0.png"
            alt="Carnegie Robotics logo"
          />
        </Box>
      </Content>
    </>
  );
}
