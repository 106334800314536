import { useMutation } from "react-query";
import { useApi } from "../providers/ApiProvider";
import { NewFeedback } from "./types";

export function useCreateFeedback() {
  const { authenticatedClient } = useApi();

  return useMutation(async (feedback: NewFeedback) => {
    await authenticatedClient.createFeedback(feedback);

    // Explicitly not returning anything since we currently don't
    // use feedback items once they're in the DB
    return;
  });
}
