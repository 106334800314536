import React from "react";
import { List } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import { ListItem as IngestionListItem } from "../components/ingestions";
import config from "../config";
import { useIngestions } from "../queries";
import {
  renderEmpty,
  renderError,
  renderFieldOptions,
  SearchList,
} from "../search-list";

function IngestionSearch() {
  return (
    <>
      <Helmet>
        <title>
          Search Ingestions &bull; {config.datastoreInstanceName} DataStore
        </title>
      </Helmet>
      <SearchList
        title="Ingestions"
        useSearchQuery={useIngestions}
        filterSchema={yup.object().shape({
          logId: yup.lazy((val) =>
            val === "" || val == null
              ? yup.string().ensure()
              : yup.string().ensure().uuid()
          ),
          status: yup
            .string()
            .ensure()
            .oneOf(["", "ready", "processing", "processed", "error"]),
        })}
        sortOptions={[{ value: "createdAt", name: "Created at" }]}
        pageSizeOptions={[15, 25, 50]}
        render={{
          error() {
            return renderError("An error occurred searching for ingestions");
          },
          loading() {
            return (
              <List>
                {[1, 2, 3].map((index) => (
                  <IngestionListItem key={index} />
                ))}
              </List>
            );
          },
          items(ingestions) {
            return ingestions.map((ingestion) => (
              <IngestionListItem
                key={ingestion.id}
                ingestion={ingestion}
                showLinks
              />
            ));
          },
          empty() {
            return renderEmpty("No ingestions were found");
          },
          filters() {
            return (
              <>
                <Field
                  component={TextField}
                  id="log-id-filter"
                  name="logId"
                  label="Log ID"
                  fullWidth
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  id="extraction-status-filter"
                  name="status"
                  label="Status"
                  fullWidth
                  select
                >
                  {renderFieldOptions([
                    { value: "", name: <i>All</i> },
                    { value: "ready", name: "Ready" },
                    { value: "processing", name: "Processing" },
                    { value: "processed", name: "Processed" },
                    { value: "error", name: "Error" },
                  ])}
                </Field>
              </>
            );
          },
        }}
      />
    </>
  );
}

IngestionSearch.layoutOptions = SearchList.layoutOptions;

export default IngestionSearch;
