import React from "react";
import { Box, Typography } from "@mui/material";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTheme,
} from "victory";
import { msToS } from "../../../utils";

function stdNormalDistribution(x, mu, sigma) {
  return (
    Math.pow(Math.E, -(Math.pow(x - mu, 2) / (2 * Math.pow(sigma, 2)))) /
    (sigma * Math.sqrt(2 * Math.PI))
  );
}

function linspace(start, stop, num, endpoint = true) {
  const div = endpoint ? num - 1 : num;
  const step = (stop - start) / div;
  return Array.from({ length: num }, (_, i) => start + step * i);
}

export default function DistributionChart({
  metric,
  log,
  mainTopic,
  auxTopics,
}) {
  const startTimeS = msToS(Number(log.startDate));
  const endTimeS = msToS(Number(log.endDate));

  const mainStats = mainTopic.stats;

  let mean, std, xMin, xMax, strokeDash;
  if (metric === "timestamp") {
    mean = mainStats.timestampDelta.mean;
    std = mainStats.timestampDelta.stdDev;
    xMin = mainStats.timestampDelta.min;
    xMax = mainStats.timestampDelta.max;
    strokeDash = `2, ${Math.min(Math.max(mean * 100, 1), 100)}`;
  } else {
    mean = mainStats.offsetDelta.mean;
    std = mainStats.offsetDelta.stdDev;
    xMin = mainStats.offsetDelta.min;
    xMax = mainStats.offsetDelta.max;
    strokeDash = `2, ${Math.min(Math.max(mean ** 0.001, 1), 100)}`;
  }

  const adjLen = Math.abs(xMax - xMin);
  const adjXMin = xMin - adjLen * 0.9;
  const adjXMax = xMax + adjLen * 0.9;

  const data = [];
  for (let x of linspace(adjXMin, adjXMax, 100)) {
    const y = stdNormalDistribution(x, mean, std);
    data.push({ x, y });
  }

  let content = (
    <>
      <VictoryChart
        height={300}
        theme={VictoryTheme.material}
        domain={{
          x: [adjXMin, adjXMax],
        }}
      >
        <VictoryLabel text={`${metric} Delta Dist.`} x={100} y={30} />
        <VictoryLine
          style={{
            data: { stroke: "#c43a31", strokeWidth: "5" },
            parent: { border: "1px solid #ccc" },
          }}
          data={data}
        />
        {auxTopics.map((auxTopic, idx) => {
          const auxStats = auxTopic.stats;
          let auxMean, auxStd;
          if (metric === "timestamp") {
            auxMean = auxStats.timestampDelta.mean;
            auxStd = auxStats.timestampDelta.stdDev;
          } else {
            auxMean = auxStats.offsetDelta.mean;
            auxStd = auxStats.offsetDelta.stdDev;
          }
          const auxData = [];

          for (let x of linspace(adjXMin, adjXMax, 100)) {
            const y = stdNormalDistribution(x, auxMean, auxStd);
            auxData.push({ x, y });
          }
          const color = ["green", "yellow", "blue"][idx % 3];
          return (
            <VictoryLine
              key={idx}
              style={{
                data: { stroke: color, strokeDasharray: "5" },
                parent: { border: "1px solid #ccc" },
              }}
              data={auxData}
            />
          );
        })}
      </VictoryChart>
      <VictoryChart
        height={150}
        domain={{
          x: [startTimeS, endTimeS],
        }}
      >
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fill: "transparent" },
          }}
        />
        <VictoryLabel text={`Approx. ${metric} Density`} x={155} y={30} />
        <VictoryLine
          style={{
            data: { stroke: "#c43a31", strokeWidth: "15" },
            parent: { border: "1px solid #ccc" },
          }}
          data={
            metric === "timestamp"
              ? [
                  { x: startTimeS, y: 1 },
                  { x: endTimeS, y: 1 },
                ]
              : [
                  { x: 0, y: 1 },
                  { x: log.size, y: 1 },
                ]
          }
        />
        <VictoryLine
          style={{
            data: {
              stroke: "black",
              strokeWidth: "3",
              strokeDasharray: strokeDash,
            },
            parent: { border: "1px solid #ccc" },
          }}
          data={
            metric === "timestamp"
              ? [
                  { x: mainStats.timestamp.min, y: 1 },
                  { x: mainStats.timestamp.max, y: 1 },
                ]
              : [
                  { x: mainStats.offset.min, y: 1 },
                  { x: mainStats.offset.max, y: 1 },
                ]
          }
        />
      </VictoryChart>
    </>
  );

  return (
    <Box m="auto">
      {mainStats.messageCount > 1 ? (
        content
      ) : (
        <Typography>Can't plot distribution with message count</Typography>
      )}
    </Box>
  );
}
