import {
  CreateFeedbackRequestFeedbackTypeEnum as FeedbackType,
  GetTopicResponse,
  Record as ApiRecord,
  RecordList,
  SampleFreq,
} from "racer-openapi-ts-sdk";
import { UseQueryOptions } from "react-query";
import * as rt from "runtypes";
import { Node } from "../panel-layout";

export { FeedbackType, SampleFreq };

// TODO: Once yup v1 is out (with better TS support), consider using it
//  instead of runtypes

export const RTEmptyPanelState = rt.Record({
  type: rt.Literal("empty"),
  name: rt.String.nullable(),
  messageType: rt.String.nullable(),
});
export type EmptyPanelState = rt.Static<typeof RTEmptyPanelState>;

export const RTVisType = rt.Union(
  rt.Literal("timeline"),
  rt.Literal("chart"),
  rt.Literal("image"),
  rt.Literal("map")
);
export type VisType = rt.Static<typeof RTVisType>;

export const RTVisualizationPanelState = rt.Record({
  type: rt.Literal("vis"),
  name: rt.String,
  messageType: rt.String.nullable(),
  fields: rt.Array(rt.String),
  tab: RTVisType,
});
export type VisualizationPanelState = rt.Static<
  typeof RTVisualizationPanelState
>;

export const RTPanelState = rt.Union(
  RTEmptyPanelState,
  RTVisualizationPanelState
);
export type PanelState = rt.Static<typeof RTPanelState>;

// runtypes implementation of panel-layout types to validate
// incoming API data

export const RTOrientation = rt.Union(
  rt.Literal("horizontal"),
  rt.Literal("vertical")
);

export const RTLayoutPanel = rt.Record({
  type: rt.Literal("panel"),
  id: rt.Number,
  parentId: rt.Number.nullable(),
  flex: rt.Number,
  state: RTPanelState,
});

export const RTLayoutContainer: rt.Runtype<Node<PanelState>> = rt.Lazy(() =>
  rt.Record({
    type: rt.Literal("container"),
    id: rt.Number,
    parentId: rt.Number.nullable(),
    flex: rt.Number,
    orientation: RTOrientation,
    firstChild: RTLayoutNode,
    secondChild: RTLayoutNode,
  })
);

export const RTLayoutNode = rt.Union(RTLayoutPanel, RTLayoutContainer);

// App types

export interface Tokens {
  refreshToken: string;
  idToken: string;
  accessToken: string;
  expiresIn: number;
}

export type RefreshedTokens = Omit<Tokens, "refreshToken">;

export interface Credentials {
  email: string;
  password: string;
}

export interface Challenge {
  name: string;
  session: string;
}

export type SignInResponse = Challenge | Tokens;

export interface NewPasswordChallengeArgs {
  email: string;
  newPassword: string;
}

// This will expand as a union type if/when we support more challenges
export type ChallengeArgs = NewPasswordChallengeArgs;

// This is needed because react-query only lets you supply a single
// argument to the useMutation function so these two fields need
// to be wrapped in a single object
export interface UserChallengeResponse {
  challenge: Challenge;
  challengeArgs: ChallengeArgs;
}

export interface UserAuth {
  username: string;
  groups: string[];
}

export interface Group {
  id: string;
  name: string;
}

export const RTLayoutProfile = rt.Record({
  type: rt.Literal("layout"),
  name: rt.String,
  data: RTLayoutNode,
  isFavorite: rt.Boolean,
});
export type LayoutProfile = rt.Static<typeof RTLayoutProfile>;

export const RTExtractionProfile = rt.Record({
  type: rt.Literal("extraction"),
  name: rt.String,
  data: rt.Record({
    names: rt.Array(rt.String),
  }),
  isFavorite: rt.Boolean,
});
export type ExtractionProfile = rt.Static<typeof RTExtractionProfile>;

export const RTProfile = rt.Union(RTLayoutProfile, RTExtractionProfile);
export type Profile = rt.Static<typeof RTProfile>;

export const RTUserConfig = rt.Record({
  profiles: rt.Array(RTProfile),
});
export type UserConfig = rt.Static<typeof RTUserConfig>;

export const RTUser = rt.Record({
  username: rt.String,
  config: RTUserConfig,
});
export type User = rt.Static<typeof RTUser>;

export interface LogMetadata {
  name: string | null;
  note: string | null;
  operator: string | null;
  vehicle: string | null;
  testId: string | null;
  comments: string | null;
  sensorsOnline: string | null;
  softwareConfig: string | null;
}

export interface Log {
  id: string;
  groupId: string | null;
  startDate: Date | null;
  endDate: Date | null;
  boundsMs: [number, number] | null;
  status: string;
  duration: number | null;
  distance: number | null;
  metadata: LogMetadata;
  createdAt: Date;
  deletedAt: Date | null;
}

export interface Ingestion {
  id: string;
  logId: Log["id"];
  format: string | null;
  s3: {
    bucket: string;
    key: string;
  };
  size: number | null;
  status: string;
  createdAt: Date;
}

export interface TopicStats {
  messageCount: number;
  timestamp: {
    mean: number | null;
    min: number | null;
    max: number | null;
  };
  timestampDelta: {
    mean: number | null;
    min: number | null;
    max: number | null;
    stdDev: number | null;
  };
  offset: {
    mean: number | null;
    min: number | null;
    max: number | null;
  };
  offsetDelta: {
    mean: number | null;
    min: number | null;
    max: number | null;
    stdDev: number | null;
  };
  dataLength: {
    total: number | null;
    mean: number | null;
    min: number | null;
    max: number | null;
    stdDev: number | null;
  };
}

export interface Topic {
  id: string;
  logId: Log["id"];
  name: string;
  messageType: string | null;
  stats: TopicStats | null;
}

export interface Record extends ApiRecord {
  timestampMs: number;
}

export interface MOTD {
  title: string | null;
  message: string | null;
  createdAt: Date | null;
  expiresAt: Date | null;
}

export interface Tag {
  name: string;
  type: string;
}

export interface Label {
  id: string;
  logId: string | null;
  topicId: string | null;
  startTimeMs: number;
  endTimeMs: number | null;
  tag: Tag | null;
  description: string | null;
  createdAt: Date;
  createdBy: string;
}

export type NewLabel = Pick<
  Label,
  "startTimeMs" | "endTimeMs" | "tag" | "description"
>;

export interface NewFeedback {
  info: string;
  type: FeedbackType;
}

export interface SampleRange {
  startTimeMs: number;
  endTimeMs: number;
  sampleFrequency: SampleFreq | null;
}

export interface ExtractionTopic {
  topic: Topic;
  ranges: SampleRange[];
}

export interface Extraction {
  id: string;
  logId: Log["id"];
  name: string;
  createdAt: Date;
  createdBy: string;
  lifespan: { weeks: 1 };
  status: string | null;
  error: object | null;
}

export interface ExtractionUpdates {
  name: Extraction["name"];
}

export interface NewExtraction {
  name: Extraction["name"];
  topics: ExtractionTopic[];
}

export interface ExtractionFile {
  id: number;
  extraction_id: string;
  url: string | null;
  size: number | null;
  status: string;
}

export interface BaseSearchOptions {
  sort?: "asc" | "desc";
  field?: string;
  page?: number;
  pageSize?: number;
}

export interface BaseListOptions {
  sort?: "asc" | "desc";
  field?: string;
  limit?: number;
  offset?: number;
}

export interface LogSearchOptions extends BaseSearchOptions {
  name?: NonNullable<LogMetadata["name"]>;
  nameLike?: NonNullable<LogMetadata["name"]>;
  status?: Log["status"];
  groupId?: Group["id"];
}

export interface ExtractionSearchOptions extends BaseSearchOptions {
  logId?: Log["id"];
  name?: Extraction["name"];
  nameLike?: Extraction["name"];
  status?: NonNullable<Extraction["status"]>;
  createdBy?: Extraction["createdBy"];
}

export interface ListTopicsOptions extends BaseSearchOptions {
  name?: Topic["name"];
}

export interface ListLogTopicsOptions extends BaseSearchOptions {
  logId: Log["id"];
}

export interface TopicSearchOptions extends BaseSearchOptions {
  logId?: Log["id"];
  name?: Topic["name"] | null;
}

export interface UseTopicQueriesOptions<TData = GetTopicResponse> {
  logId: Log["id"];
  topicId: Topic["id"] | null;
  queryOpts?: Pick<
    UseQueryOptions<GetTopicResponse, Response, TData>,
    "select" | "onError"
  >;
}

export interface ListTopicRecordsOptions extends BaseSearchOptions {
  logId: Log["id"];
  topicId: Topic["id"];
  startTimeMs?: number;
  endTimeMs?: number;
  freq?: SampleFreq.Second | SampleFreq.Decisecond;
  includeImage?: boolean;
}

export interface RecordSearchOptions extends BaseSearchOptions {
  logId: Log["id"];
  topicId?: Topic["id"] | null;
  startTimeMs?: number;
  endTimeMs?: number;
  freq?: SampleFreq.Second | SampleFreq.Decisecond;
  includeImage?: boolean;
}

export interface UseRecordsQueriesOptions<TData = RecordList> {
  searchOpts: RecordSearchOptions;
  queryOpts?: Pick<
    UseQueryOptions<RecordList, Response, TData>,
    "enabled" | "staleTime" | "cacheTime" | "select"
  >;
}

export interface SearchResult<TData> {
  count: number;
  data: TData;
}

// Utility types

export interface FromApi<TResponse> {
  data: TResponse;
}
