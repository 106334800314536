import invariant from "invariant";
import {
  GetTopicResponse,
  RecordList,
  Topic as ApiTopic,
} from "racer-openapi-ts-sdk";
import { useQueries, useQuery, UseQueryOptions } from "react-query";
import { useApi } from "../providers/ApiProvider";
import { topicFromApi } from "./selectors";
import {
  FromApi,
  Log,
  RecordSearchOptions,
  Topic,
  TopicSearchOptions,
  UseRecordsQueriesOptions,
  UseTopicQueriesOptions,
} from "./types";

// Query key factories

export const topicKeys = {
  all: ["topics"] as const,
  lists: () => [...topicKeys.all, "list"] as const,
  list: (filters: TopicSearchOptions) => [...topicKeys.lists(), filters],
  details: () => [...topicKeys.all, "details"] as const,
  detail: (logId: Log["id"], topicId: Topic["id"] | null | undefined) =>
    [...topicKeys.details(), { logId, topicId }] as const,
};

export const recordKeys = {
  all: ["records"] as const,
  lists: () => [...recordKeys.all, "list"] as const,
  list: (filters: RecordSearchOptions) =>
    [...recordKeys.lists(), filters] as const,
};

// Queries

export function useTopicQueries<TData = GetTopicResponse>(
  opts: UseTopicQueriesOptions<TData>[]
) {
  const { authenticatedClient } = useApi();

  const queries = opts.map(({ logId, topicId, queryOpts }) => ({
    queryKey: topicKeys.detail(logId, topicId),
    queryFn() {
      invariant(topicId != null, "Topic ID cannot be null");

      return authenticatedClient.getLogTopic(logId, topicId);
    },
    enabled: topicId != null,
    ...(Boolean(queryOpts) && queryOpts),
  }));

  return useQueries(queries);
}

export function useTopic(
  logId: Log["id"],
  topicId: Topic["id"] | null,
  callbacks?: Pick<UseQueryOptions<unknown, Response, Topic>, "onError">
) {
  const [query] = useTopicQueries([
    {
      logId,
      topicId,
      queryOpts: {
        select: topicFromApi,
        ...(Boolean(callbacks) && callbacks),
      },
    },
  ]);

  return query;
}

export function useTopics(
  searchOpts: TopicSearchOptions,
  queryOpts?: Pick<UseQueryOptions<unknown, Response, Topic[]>, "onSuccess">
) {
  const { authenticatedClient } = useApi();

  let enabled: boolean = true;
  if (searchOpts.logId === undefined) {
    // This is one of the few places where the distinction between null
    // and undefined is useful. It's possible the user wants to list
    // topics from all logs without filtering by name, in which case
    // the name option should be undefined. However, it's also common
    // throughout the app for topic IDs or names given to a query to be
    // null as topics are often queried in response to an event like a user
    // selecting a topic from a list. Because of this, there are times where
    // it's sensible for topic name to be null because we'll eventually
    // need to filter topics by name but currently can't because the name
    // is null, in which case the query should be disabled
    enabled = searchOpts.name !== null;
  }

  return useQuery(
    topicKeys.list(searchOpts),
    () => {
      if (searchOpts.logId === undefined) {
        invariant(
          searchOpts.name !== null,
          "A topic name cannot explicitly be null when listing topics"
        );

        // Name won't be null because of the invariant check above
        return authenticatedClient.listTopics(searchOpts as any);
      } else {
        // Log ID won't be null because of the wrapping `if` clause
        return authenticatedClient.listLogTopics(searchOpts as any);
      }
    },
    {
      select: topicsFromApi,
      enabled,
      ...(Boolean(queryOpts) && queryOpts),
    }
  );
}

export function useLogTopics(logId: Log["id"]) {
  return useTopics({
    logId,
    pageSize: 1_000,
    sort: "asc",
    field: "name",
  });
}

export function useRecordsQueries<TData = RecordList>(
  opts: UseRecordsQueriesOptions<TData>[]
) {
  const { authenticatedClient } = useApi();

  const queries = opts.map(({ searchOpts, queryOpts }) => ({
    queryKey: recordKeys.list(searchOpts),
    queryFn() {
      invariant(searchOpts.topicId != null, "Topic ID must be a valid string");

      // Invariant check above ensures topic ID is a string
      return authenticatedClient.listTopicRecords(searchOpts as any);
    },
    ...(Boolean(queryOpts) && queryOpts),
    enabled: searchOpts.topicId != null && (queryOpts?.enabled ?? true),
  }));

  return useQueries(queries);
}

// Selectors

function topicsFromApi({ data }: FromApi<ApiTopic[]>): Topic[] {
  return data.map((topic) => topicFromApi({ data: topic }));
}
