import React from "react";
import { Box, CardHeader, Divider } from "@mui/material";

export default function DividedCardHeader({
  height = "auto",
  ...cardHeaderProps
}) {
  return (
    <Box
      sx={{
        "& .MuiCardHeader-root": {
          height,
          boxSizing: "content-box",
        },
        "& .MuiCardHeader-action": {
          mt: 0,
          mr: 0,
          alignSelf: "center",
        },
      }}
    >
      <CardHeader {...cardHeaderProps} />
      <Divider />
    </Box>
  );
}
