import React from "react";
import { Alert, Button, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import NavigationLink from "../../components/NavigationLink";
import { renderAdminEmailLink } from "../../components/auth";
import config from "../../config";
import { useSignUp } from "../../queries";
import * as paths from "../paths";

const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("The provided email is invalid")
    .required("An email is required"),
});

export default function SignUpForm({ titleComponent = "h2" }) {
  const signUp = useSignUp();

  async function handleSignUp(values, { setSubmitting }) {
    try {
      await signUp.mutateAsync(values.email);
    } catch {
      /* noop */
    }

    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={handleSignUp}
      validationSchema={signUpSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <Typography variant="h6" component={titleComponent} gutterBottom>
            Sign Up for {config.datastoreInstanceName} DataStore
          </Typography>
          <Typography paragraph>
            Enter your organizational email address below. If you are authorized
            to sign up for this site you will be emailed a temporary password to
            sign in with.
          </Typography>
          <Field
            component={TextField}
            id="email"
            name="email"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            label="Email"
            InputProps={{
              autoComplete: "email",
            }}
          />
          <Button
            sx={{ my: 2 }}
            disabled={isSubmitting}
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
          >
            Sign Up
          </Button>
          <Typography variant="body2" align="center">
            Have an account?{" "}
            <NavigationLink to={paths.signIn}>Sign in</NavigationLink>
          </Typography>
          {signUp.isSuccess && (
            <Alert sx={{ mt: 4 }} variant="filled" severity="success">
              Sign-up successful! You should receive an email from{" "}
              {config.tempPasswordEmail} with a temporary password. You can use
              it and the email you provided to finish creating your account.
            </Alert>
          )}
          {signUp.isError && (
            <Alert sx={{ mt: 4 }} variant="filled" severity="error">
              Unable to sign up with that email! If you believe this is an
              error, email the site admin at {renderAdminEmailLink()}
            </Alert>
          )}
        </Form>
      )}
    </Formik>
  );
}
