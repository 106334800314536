import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import _ from "lodash";

const LAYOUT = {
  STACKED: "stacked",
  SPREAD: "spread",
};

export default function DescriptionList({
  items,
  layout = LAYOUT.SPREAD,
  align = "auto",
}) {
  let actualLayout = layout;
  if (!_.values(LAYOUT).includes(layout)) {
    actualLayout = LAYOUT.SPREAD;
    console.warn(
      `Unknown layout item provided: ${layout}. Defaulting to 'spread'`
    );
  }

  let computedAlign = align;
  if (align === "auto") {
    computedAlign = actualLayout === LAYOUT.STACKED ? "left" : "right";
  }

  return (
    <Box
      sx={{
        marginBlockStart: 0,
        marginBlockEnd: 0,
        "& > :not(:last-of-type)": {
          mb: 3,
        },
      }}
      component="dl"
    >
      {items.map((item) => (
        <Grid key={item.key} container>
          <Grid
            item
            xs={actualLayout === LAYOUT.STACKED ? 12 : 3}
            component="dt"
          >
            <Typography
              color="textSecondary"
              gutterBottom={actualLayout === LAYOUT.STACKED}
            >
              <b>{item.key}</b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={actualLayout === LAYOUT.STACKED ? 12 : 9}
            component="dd"
          >
            <Typography align={computedAlign}>{item.value}</Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
}
