import invariant from "invariant";

export interface ExternalAuth {
  signInUrl: string;
  clientId: string;
  clientSecret: string;
  redirectUri: string;
  tokenEndpoint: string;
}

let externalAuth: ExternalAuth | null = null;
if ("REACT_APP_EXTERNAL_AUTH_SIGN_IN_ENDPOINT" in process.env) {
  // All external auth env vars are required at this point
  externalAuth = {
    signInUrl: required("REACT_APP_EXTERNAL_AUTH_SIGN_IN_ENDPOINT"),
    clientId: required("REACT_APP_EXTERNAL_AUTH_CLIENT_ID"),
    clientSecret: required("REACT_APP_EXTERNAL_AUTH_CLIENT_SECRET"),
    redirectUri: required("REACT_APP_EXTERNAL_AUTH_REDIRECT_URI"),
    tokenEndpoint: required("REACT_APP_EXTERNAL_AUTH_TOKEN_ENDPOINT"),
  };
}

const config = {
  datastoreInstanceName: required("REACT_APP_DATASTORE_INSTANCE_NAME"),
  navbarStateStorageKey: "sidebar-state",
  motdStorageKey: "last-motd",
  useHashRouter: process.env.REACT_APP_USE_HASH_ROUTER === "true",
  api: {
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  externalAuth,
  adminEmail: process.env.REACT_APP_ADMIN_EMAIL,
  tempPasswordEmail: process.env.REACT_APP_TEMP_PASSWORD_EMAIL,
  setAuthCookie:
    // It's common to run code locally against the prod endpoints so
    // the auth cookie should **only** be set if we're *building* for prod
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_SET_AUTH_COOKIE === "true",
  authCookieName: "token",
  home: {
    guide: {
      href: "https://guide.racer-datastore.com/",
    },
    api: {
      href: "https://docs.racer-datastore.com/",
    },
    icd: {
      href: "https://icd.racer-datastore.com/",
    },
    fleet: {
      href: "https://icd.racer-datastore.com/assets/documentation",
    },
  },
};

export default config;

function required(envVarName: string): string {
  const envVar = process.env[envVarName];

  invariant(
    envVar !== undefined,
    `Env var "${envVarName}" is required but wasn't set`
  );

  return envVar;
}
