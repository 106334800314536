import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import invariant from "invariant";
import { filter, find, groupBy, keys, orderBy, sortBy } from "lodash";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import Error from "../../../components/Error";
import TimeRange from "../../../components/labels/TimeRange";
import Tag from "../../../components/tags/Tag";
import { useFeature } from "../../../providers/Features";
import { useDeleteLabel, useLabels, useUser } from "../../../queries";
import * as paths from "../../paths";
import { LabelDetailView } from "./index";

export default function LabelListView() {
  const [selectedLabelId, setSelectedLabelId] = useState<string | null>(null);

  const deleteBehavior = useFeature("labels.delete");

  const { logId } = useParams<paths.LogParams>();

  const labelsQuery = useLabels(logId);

  const mutation = useDeleteLabel(logId);

  const userQuery = useUser();

  const { enqueueSnackbar } = useSnackbar();

  const confirm = useConfirm();

  function handleSelectLabel(labelId: string) {
    return () => setSelectedLabelId(labelId);
  }

  function handleReturnToList() {
    setSelectedLabelId(null);
  }

  async function handleDeleteClick() {
    invariant(selectedLabelId !== null, "No label ID is selected");

    try {
      await confirm({
        title: "Delete label?",
        description: "This action is permanent",
        confirmationText: "Delete",
        cancellationButtonProps: { color: "primary" },
      });

      mutation.mutate(selectedLabelId, {
        onSuccess() {
          setSelectedLabelId(null);
        },
        onError() {
          enqueueSnackbar("Unable to delete label", {
            variant: "error",
          });
        },
      });
    } catch (e) {
      /* noop */
    }
  }

  if (labelsQuery.isLoading || labelsQuery.isIdle) {
    return (
      <List>
        {[1, 2, 3].map((index) => (
          <ListItem key={index}>
            <Skeleton width="100%" />
          </ListItem>
        ))}
      </List>
    );
  } else if (labelsQuery.isError) {
    return (
      <Error>
        <Typography>Unable to fetch labels</Typography>
      </Error>
    );
  } else if (labelsQuery.data.length === 0) {
    return <Typography>No labels</Typography>;
  } else {
    // It's possible for the selected label ID to be non-null but
    // for the label to no longer be in the list. This is due to
    // the timing of the labels refetching and when the mutation's
    // onSuccess callback fires and sets the selected ID to null:
    // the refetching triggered by useDeleteLabel invalidating
    // the label list query will finish first, meaning we'd have
    // a selected label ID but no corresponding label in the list.
    // The general use case here is just to always search the list
    // and assume if we couldn't find a label then we should show
    // the list view instead
    const label = find(labelsQuery.data, { id: selectedLabelId as any });
    if (label !== undefined) {
      const canDelete = userQuery.data?.username === label.createdBy;

      return (
        <div>
          <Box position="relative" mb={2}>
            <Tooltip title="Return to list">
              <IconButton
                sx={{
                  position: "absolute",
                  left: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                aria-label="Return to list"
                onClick={handleReturnToList}
                size="large"
              >
                <ChevronLeft />
              </IconButton>
            </Tooltip>
            <Typography
              fontWeight={500}
              variant="body1"
              component="h3"
              align="center"
            >
              Label Details
            </Typography>
          </Box>
          <LabelDetailView label={label} />
          <Box mt={4}>
            <Tooltip
              title={
                deleteBehavior.disabled
                  ? deleteBehavior.reason
                  : !canDelete
                  ? "You can only delete labels you created"
                  : ""
              }
            >
              <span>
                <LoadingButton
                  disabled={deleteBehavior.disabled || !canDelete}
                  loading={mutation.isLoading}
                  fullWidth
                  color="error"
                  variant="outlined"
                  onClick={handleDeleteClick}
                >
                  Delete
                </LoadingButton>
              </span>
            </Tooltip>
          </Box>
        </div>
      );
    } else {
      const tagTypeGroups = groupBy(
        filter(labelsQuery.data, "tag.type"),
        "tag.type"
      );

      return (
        <List
          sx={{
            backgroundColor: "background.paper",
            "& > li": {
              backgroundColor: "inherit",
              "& > ul": {
                listStyleType: "none",
                backgroundColor: "inherit",
                p: 0,
              },
            },
          }}
          disablePadding
        >
          {sortBy(keys(tagTypeGroups)).map((tagType, index, { length }) => {
            const sortedLabels = orderBy(
              tagTypeGroups[tagType],
              [
                (label) => label.startTimeMs,
                (label) => label.endTimeMs ?? label.startTimeMs,
              ],
              ["asc", "asc"]
            );

            return (
              <li key={tagType}>
                <ul>
                  <ListSubheader>{tagType}</ListSubheader>
                  {sortedLabels.map((label) => (
                    <ListItem key={label.id}>
                      <ListItemText
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        secondary={
                          label.tag ? (
                            <Tag
                              // TODO: Eventually remove this cast
                              name={label.tag.name as any}
                              ChipProps={{
                                sx: { mx: 0.5 },
                                component: "span",
                                size: "small",
                              }}
                            />
                          ) : undefined
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                        }}
                      >
                        <TimeRange
                          label={label}
                          asLink
                          keepExistingSearch
                          useTypography={false}
                        />
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <Tooltip title="Show details">
                          <IconButton
                            aria-label="Show label details"
                            onClick={handleSelectLabel(label.id)}
                            size="large"
                          >
                            <ChevronRight />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                  {index !== length - 1 && <Divider />}
                </ul>
              </li>
            );
          })}
        </List>
      );
    }
  }
}
