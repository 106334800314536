import React, { useEffect, useState } from "react";
import { Content } from "@mui-treasury/layout";
import { Container, Grid, Skeleton } from "@mui/material";
import { add, formatDistanceToNowStrict, isPast } from "date-fns";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import config from "../../config";
import { FILE_FETCH_STATES } from "../../constants";
import DraftExtractionProvider from "../../providers/DraftExtractionProvider";
import {
  Extraction,
  useExtraction,
  useExtractionFiles,
  useUser,
} from "../../queries";
import * as paths from "../paths";
import ArtifactsCard from "./ArtifactsCard";
import ExtractionListCard from "./ExtractionListCard";
import SummaryCard from "./SummaryCard";

interface ExpirationStatus {
  expired: boolean;
  when: string;
}

function calculateExpirationStatus(extraction: Extraction): ExpirationStatus {
  const expirationDate = add(extraction.createdAt, extraction.lifespan);
  return {
    expired: isPast(expirationDate),
    when: formatDistanceToNowStrict(expirationDate, { addSuffix: true }),
  };
}

export default function ExtractionDetails() {
  const [expirationStatus, setExpirationStatus] =
    useState<ExpirationStatus | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const userQuery = useUser();

  const { logId, extractionId } = useParams<paths.ExtractionDetailsParams>();

  const {
    isIdle: isExtractionIdle,
    isLoading: isExtractionLoading,
    isError: isExtractionError,
    isSuccess: isExtractionSuccess,
    data: extraction,
    dataUpdatedAt: extractionLastUpdated,
  } = useExtraction(logId, extractionId, {
    onError() {
      enqueueSnackbar("Unable to load extraction", {
        variant: "error",
      });
    },
  });

  const { isSuccess: areFilesSuccess, data: extractionFiles } =
    useExtractionFiles(logId, extractionId);

  useEffect(() => {
    if (
      extraction !== undefined &&
      FILE_FETCH_STATES.includes(extraction.status ?? "") &&
      !expirationStatus?.expired
    ) {
      const timeoutId = setTimeout(
        () => setExpirationStatus(calculateExpirationStatus(extraction)),
        5000
      );

      return () => clearTimeout(timeoutId);
    }
  }, [extraction, expirationStatus]);

  const calculatedExpirationStatus =
    extraction !== undefined &&
    expirationStatus === null &&
    FILE_FETCH_STATES.includes(extraction.status ?? "")
      ? calculateExpirationStatus(extraction)
      : expirationStatus;

  return (
    <>
      <Helmet>
        <title>
          Extraction Details &bull; {config.datastoreInstanceName} DataStore
        </title>
      </Helmet>
      <Header
        title={
          extraction === undefined ? (
            <Skeleton width="30ch" />
          ) : (
            extraction.name ?? extraction.id
          )
        }
      />
      <Content sx={{ py: 4, flexDirection: "column", overflowY: "auto" }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DraftExtractionProvider
                    logId={logId}
                    templateId={extractionId}
                  >
                    <ExtractionListCard />
                  </DraftExtractionProvider>
                </Grid>
                <Grid item xs={12}>
                  <ArtifactsCard
                    isExtractionLoading={!isExtractionSuccess}
                    areFilesLoading={!areFilesSuccess}
                    extraction={extraction}
                    extractionLastUpdated={extractionLastUpdated}
                    extractionFiles={extractionFiles}
                    expirationStatus={calculatedExpirationStatus}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SummaryCard
                username={userQuery.data?.username ?? ""}
                isLoading={
                  isExtractionIdle || isExtractionLoading || isExtractionError
                }
                extraction={extraction}
              />
            </Grid>
          </Grid>
        </Container>
      </Content>
    </>
  );
}
