import React from "react";
import { Link } from "@mui/material";
import config from "../../config";

export function renderAdminEmailLink() {
  return (
    <Link
      href={`mailto:${config.adminEmail}`}
      color="inherit"
      underline="always"
    >
      {config.adminEmail}
    </Link>
  );
}
