import React, { useReducer } from "react";
import { Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { startCase } from "lodash";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import DescriptionList from "../../components/DescriptionList";
import DividedCardHeader from "../../components/DividedCardHeader";
import NavigationLink from "../../components/NavigationLink";
import { useFeature } from "../../providers/Features";
import { useUpdateExtraction } from "../../queries";
import * as paths from "../paths";

const validationSchema = yup.object().shape({
  name: yup.string().trim().required("A name is required"),
});

export default function SummaryCard({ username, extraction, isLoading }) {
  const [editMode, toggleEditMode] = useReducer((x) => !x, false);

  const editBehavior = useFeature("extractions.edit");

  const { logId, extractionId } = useParams();

  const updateExtraction = useUpdateExtraction(logId, extractionId);

  const { enqueueSnackbar } = useSnackbar();

  let content;
  if (isLoading) {
    content = (
      <DescriptionList
        items={[
          {
            key: "ID",
            value: <Skeleton width="36ch" />,
          },
          {
            key: "Log ID",
            value: <Skeleton width="36ch" />,
          },
          {
            key: "Status",
            value: <Skeleton width="15%" />,
          },
          {
            key: "Created By",
            value: <Skeleton width="25ch" />,
          },
          {
            key: "Created At",
            value: <Skeleton width="30%" />,
          },
          {
            key: "Name",
            value: <Skeleton width="15ch" />,
          },
        ]}
        align="left"
      />
    );
  } else if (editMode) {
    function handleSubmit(values, { setFieldError, setSubmitting }) {
      updateExtraction.mutate(values, {
        onSuccess() {
          // Toggling edit mode unmounts the form so don't need to call
          // `setSubmitting`
          toggleEditMode();
        },
        async onError(e) {
          if (e instanceof Response && e.status === 422) {
            const { errorMessage } = await e.json();

            setFieldError(
              "name",
              errorMessage.replace("[Unprocessable Entity] ", "")
            );
          } else {
            enqueueSnackbar("Unable to update extraction", {
              variant: "error",
            });
          }

          setSubmitting(false);
        },
      });
    }

    const canEdit = username === extraction.createdBy;

    content = (
      <Formik
        initialValues={{ name: extraction.name.trim() }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Grid container direction="column" spacing={2} component={Form}>
            <Grid item>
              <Field
                component={TextField}
                name="name"
                variant="outlined"
                label="Name"
                InputProps={{ autoComplete: "off" }}
              />
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Tooltip
                    title={
                      editBehavior.disabled
                        ? editBehavior.reason
                        : !canEdit
                        ? "Cannot edit an extraction you didn't create"
                        : ""
                    }
                  >
                    <span>
                      <LoadingButton
                        disabled={editBehavior.disabled || !canEdit}
                        loading={isSubmitting}
                        type="submit"
                        variant="outlined"
                        color="primary"
                      >
                        Save Changes
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Button
                    disabled={isSubmitting}
                    onClick={toggleEditMode}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    );
  } else {
    content = (
      <DescriptionList
        items={[
          {
            key: "ID",
            value: extraction.id,
          },
          {
            key: "Log ID",
            value: (
              <NavigationLink to={paths.toLog(extraction.logId)}>
                {extraction.logId}
              </NavigationLink>
            ),
          },
          {
            key: "Status",
            value: startCase(extraction.status),
          },
          {
            key: "Created By",
            value: extraction.createdBy ?? "-",
          },
          {
            key: "Created At",
            value: extraction.createdAt.toUTCString().replace("GMT", "UTC"),
          },
          {
            key: "Name",
            value: extraction.name ?? "-",
          },
        ]}
        align="left"
      />
    );
  }

  return (
    <Card elevation={4}>
      <DividedCardHeader
        size="2rem"
        title="Summary"
        titleTypographyProps={{
          variant: "h6",
          component: "h2",
        }}
        action={
          <Tooltip title="Edit">
            <span>
              <IconButton
                disabled={isLoading || editMode}
                aria-label="Enter edit mode"
                onClick={toggleEditMode}
                size="large"
              >
                <Edit />
              </IconButton>
            </span>
          </Tooltip>
        }
      />
      <CardContent>{content}</CardContent>
    </Card>
  );
}
