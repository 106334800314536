import queryString from "query-string";
import { UseQueryResult } from "react-query";
import { reduceQueries } from "../../new-utils";
import { Topic } from "../../queries";

export function reduceTopicCompQueries(
  listQuery: UseQueryResult<Topic[]>,
  detailQueries: UseQueryResult<Topic>[]
): Pick<
  UseQueryResult<Topic[]>,
  "isIdle" | "isError" | "isLoading" | "isSuccess" | "data"
> {
  if (!listQuery.isSuccess) {
    return listQuery;
  } else {
    return reduceQueries(detailQueries);
  }
}

export function timestampFromSearch(search: string) {
  const { t } = queryString.parse(search, { parseNumbers: true });

  return typeof t === "number" ? t : undefined;
}
