import React, { ReactNode, useState } from "react";
import { Content } from "@mui-treasury/layout";
import { ContentCopy, Error as ErrorIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";
import { Helmet } from "react-helmet";
import Error from "../components/Error";
import Header from "../components/Header";
import Loading from "../components/Loading";
import config from "../config";
import { useApiKey, useGroups, useUser } from "../queries";

export default function Profile() {
  const [showKey, setShowKey] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const userQuery = useUser();
  const groupsQuery = useGroups();
  const apiKeyQuery = useApiKey();

  function handleOnCopy(text: string, result: boolean) {
    if (result) {
      enqueueSnackbar("Key copied to your clipboard", { variant: "success" });
    } else {
      enqueueSnackbar(
        "Unable to copy API key. If the issue persists, try manually copying the key",
        {
          variant: "error",
        }
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>Profile &bull; {config.datastoreInstanceName} DataStore</title>
      </Helmet>
      <Header title="Profile" />
      <Content sx={{ py: 4, flexDirection: "column", overflowY: "auto" }}>
        {userQuery.isError ? (
          <Error>
            <Typography variant="h6" component="p">
              Unable to fetch your profile information
            </Typography>
          </Error>
        ) : userQuery.data === undefined ? (
          <Loading type="circular">
            <Typography variant="h6" component="p">
              Fetching your profile information
            </Typography>
          </Loading>
        ) : (
          <Container>
            <Typography paragraph>
              Your are signed in as <b>{userQuery.data.username}</b>
            </Typography>
            <Stack spacing={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="h2" gutterBottom>
                    Groups
                  </Typography>
                  {groupsQuery.isError ? (
                    renderStatusStack(
                      "error",
                      <Typography color="error">
                        Unable to get your group information
                      </Typography>
                    )
                  ) : groupsQuery.isLoading || groupsQuery.isIdle ? (
                    renderStatusStack(
                      "loading",
                      <Typography>Fetching your groups</Typography>
                    )
                  ) : (
                    <>
                      <Typography>
                        You are a member of the following groups:
                      </Typography>
                      <ul>
                        {groupsQuery.data.map((group) => (
                          <li key={group.id}>
                            <Typography component="span">
                              {group.name}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </CardContent>
              </Card>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="h2" gutterBottom>
                    API Key
                  </Typography>
                  {apiKeyQuery.isError ? (
                    renderStatusStack(
                      "error",
                      <Typography color="error">
                        Unable to get your API key
                      </Typography>
                    )
                  ) : apiKeyQuery.isLoading || apiKeyQuery.isIdle ? (
                    renderStatusStack(
                      "loading",
                      <Typography>Fetching your API key</Typography>
                    )
                  ) : apiKeyQuery.data === null ? (
                    <Typography>You don't have any API keys</Typography>
                  ) : (
                    <>
                      <Typography paragraph>
                        Below is your personal API key. You can use it for
                        programmatic access to the DataStore.{" "}
                        <b>Make sure you keep it safe!</b>
                      </Typography>
                      <Stack direction="row" spacing={3} alignItems="center">
                        <Box flex={1}>
                          {showKey ? (
                            <TextField
                              sx={{ mt: 0.5 }}
                              fullWidth
                              label="API Key"
                              value={apiKeyQuery.data}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <CopyToClipboard
                                    text={apiKeyQuery.data}
                                    onCopy={handleOnCopy}
                                  >
                                    <InputAdornment position="end">
                                      <Tooltip title="Copy to clipboard">
                                        <IconButton
                                          aria-label="copy to clipboard"
                                          size="large"
                                        >
                                          <ContentCopy fontSize="inherit" />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  </CopyToClipboard>
                                ),
                              }}
                            />
                          ) : (
                            <CopyToClipboard
                              text={apiKeyQuery.data}
                              onCopy={handleOnCopy}
                            >
                              <Button variant="contained" color="primary">
                                Copy to Clipboard
                              </Button>
                            </CopyToClipboard>
                          )}
                        </Box>
                        <FormControlLabel
                          sx={{ flexShrink: 0 }}
                          control={
                            <Switch
                              checked={showKey}
                              onChange={(_, value) => setShowKey(value)}
                            />
                          }
                          label="Show key"
                          labelPlacement="start"
                        />
                      </Stack>
                    </>
                  )}
                </CardContent>
              </Card>
            </Stack>
          </Container>
        )}
      </Content>
    </>
  );
}

function renderStatusStack(status: "error" | "loading", text: ReactNode) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {status === "error" ? (
        <ErrorIcon fontSize="large" color="error" />
      ) : (
        <CircularProgress size={20} />
      )}
      {text}
    </Stack>
  );
}
