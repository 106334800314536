import { ReactNode } from "react";
import { EdgeTrigger, Header as LayoutHeader } from "@mui-treasury/layout";
import { Campaign, Menu } from "@mui/icons-material";
import {
  Badge,
  Box,
  IconButton,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useLocalStorage } from "react-use";
import config from "../config";
import { useMotd } from "../queries";

export interface LocalNavigationProps {
  title: ReactNode;
}

export default function Header({ title }: LocalNavigationProps) {
  const [lastMotd, setLastMotd] = useLocalStorage<string>(
    config.motdStorageKey
  );

  const motdQuery = useMotd();

  const notificationPopupState = usePopupState({
    variant: "popover",
    popupId: "notification-popover",
  });

  // Identify if the message of the day is new by comparing its `createdAt`
  // field (or `message` if `createdAt` is `null`) to what's in local storage,
  // if anything
  const storageComparisonValue = String(
    motdQuery.data?.createdAt ?? motdQuery.data?.message
  );
  const isAvailable = motdQuery.isSuccess && motdQuery.data.message !== null;
  const isNew = isAvailable && storageComparisonValue !== lastMotd;

  return (
    <LayoutHeader color="primary">
      <Toolbar
        variant="dense"
        sx={{
          "& *": {
            color: "white",
          },
        }}
      >
        <EdgeTrigger target={{ anchor: "left", field: "open" }}>
          {(open, setOpen) => (
            <Tooltip title={open ? "Close navigation" : "Open navigation"}>
              <IconButton onClick={() => setOpen(!open)}>
                <Menu />
              </IconButton>
            </Tooltip>
          )}
        </EdgeTrigger>
        <Typography variant="h6" component="p" ml={2} flexGrow={1} noWrap>
          {title}
        </Typography>
        <Tooltip title="Message of the day">
          <IconButton {...bindTrigger(notificationPopupState)}>
            <Badge
              color="warning"
              overlap="circular"
              variant="dot"
              invisible={!isNew}
            >
              <Campaign />
            </Badge>
          </IconButton>
        </Tooltip>
        <Popover
          {...bindPopover(notificationPopupState)}
          TransitionProps={{
            onEntered() {
              if (isAvailable) {
                setLastMotd(storageComparisonValue);
              }
            },
          }}
        >
          <Box p={2} width={300}>
            {motdQuery.isSuccess && motdQuery.data.message !== null ? (
              <>
                {motdQuery.data.title !== null && (
                  <Typography
                    variant="h6"
                    component="p"
                    fontWeight="bold"
                    gutterBottom
                  >
                    {motdQuery.data.title}
                  </Typography>
                )}
                <Typography>{motdQuery.data.message}</Typography>
              </>
            ) : (
              <Typography>Nothing new here</Typography>
            )}
          </Box>
        </Popover>
      </Toolbar>
    </LayoutHeader>
  );
}
