import React from "react";
import { CloudDownload, CloudOutlined } from "@mui/icons-material";
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import _ from "lodash";
import prettyBytes from "pretty-bytes";
import { pluralize } from "../../utils";

export default function FileList({ isLoading, isExpired, files }) {
  function getTotalSizeMessage() {
    if (_.some(files, { size: null })) {
      return "Total size unknown";
    } else {
      const totalSizeBytes = _.sumBy(files ?? [], "size");
      const formattedSize = prettyBytes(totalSizeBytes, {
        binary: true,
        maximumFractionDigits: 2,
      });

      return `${formattedSize} total`;
    }
  }

  let subheader;
  if (isLoading) {
    subheader = <Skeleton width="15ch" />;
  } else if (isExpired) {
    subheader = (
      <>
        {pluralize(files.length, "file")} &bull; {getTotalSizeMessage()}
      </>
    );
  } else {
    subheader = (
      <>
        {_.filter(files, { status: "uploaded" }).length} / {files.length} files
        ready &bull; {getTotalSizeMessage()}
      </>
    );
  }

  let items;
  if (isLoading) {
    items = [1, 2, 3].map((key) => (
      <ListItem key={key}>
        <ListItemText secondary={<Skeleton width="10ch" />}>
          <Skeleton width="45ch" />
        </ListItemText>
      </ListItem>
    ));
  } else {
    items = files.map((file) => {
      const fileName = `${file.extraction_id}_${file.id}.bag`;

      let textProps, icon, secondary;
      if (isExpired) {
        textProps = {};

        if (file.size != null) {
          secondary = prettyBytes(file.size, { binary: true });
        } else {
          secondary = "Unknown size";
        }
      } else if (file.status === "uploaded") {
        textProps = {
          sx: {
            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 1,
              pointerEvents: "auto",
              backgroundColor: "transparent",
            },
          },
          component: Link,
          href: file.url,
          underline: "always",
        };

        icon = <CloudDownload color="primary" />;

        if (file.size != null) {
          secondary = prettyBytes(file.size, { binary: true });
        } else {
          secondary = "Unknown size";
        }
      } else {
        textProps = {};

        icon = <CloudOutlined />;

        secondary = "File processing...";
      }

      return (
        <ListItem key={file.id}>
          {icon != null && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText
            primaryTypographyProps={textProps}
            secondary={secondary}
          >
            {fileName}
          </ListItemText>
        </ListItem>
      );
    });
  }

  return (
    <List
      sx={{
        maxHeight: 500,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        backgroundColor: "background.paper",
        mb: 2,
        "& .MuiListSubheader-root": {
          alignSelf: "stretch",
          backgroundColor: "inherit",
        },
        "& .MuiListItem-root": {
          display: "inline-flex",
          width: "auto",
          "& .MuiListItemText-root": {
            flexGrow: 0,
          },
        },
      }}
      subheader={<ListSubheader>{subheader}</ListSubheader>}
    >
      {items}
    </List>
  );
}
