import invariant from "invariant";
import {
  GetAuthedUserResponse,
  GroupList,
  User as ApiUser,
} from "racer-openapi-ts-sdk";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { useApi } from "../providers/ApiProvider";
import { FromApi, Group, RTUserConfig, User, UserConfig } from "./types";

// Query key factories

export const userKeys = {
  all: ["user"] as const,
  detail: () => [...userKeys.all, "details"] as const,
};

export const groupKeys = {
  all: ["groups"] as const,
  list: () => [...groupKeys.all, "list"] as const,
};

// Love the redundancy
export const apiKeyKeys = {
  all: ["apiKeys"] as const,
  list: () => [...apiKeyKeys.all, "list"] as const,
};

// Queries

export function useUser(
  options?: Pick<
    UseQueryOptions<GetAuthedUserResponse, unknown, User, string[]>,
    "onSuccess"
  >
) {
  const { authenticatedClient } = useApi();

  return useQuery(userKeys.detail(), authenticatedClient.getUser, {
    select: userFromApi,
    ...options,
  });
}

export function useGroups() {
  const { authenticatedClient } = useApi();

  return useQuery(groupKeys.list(), authenticatedClient.listGroups, {
    select: selectGroups,
  });
}

export function useApiKey() {
  const { authenticatedClient } = useApi();

  return useQuery(apiKeyKeys.list(), authenticatedClient.listApiKeys, {
    select({ data }) {
      if (data.length === 0) {
        return null;
      } else {
        return data[0].key;
      }
    },
  });
}

// Mutations

export function useUpdateUser() {
  const { authenticatedClient } = useApi();

  const userQuery = useUser();

  const queryClient = useQueryClient();

  return useMutation(
    (config: UserConfig) => {
      invariant(
        userQuery.isSuccess,
        `User hasn't been successfully fetched yet. 
        Wait until the user is successfully fetched before calling this mutation`
      );

      return authenticatedClient.updateUserConfig(userQuery.data, config);
    },
    {
      onSuccess(data) {
        queryClient.setQueryData(userKeys.detail(), data);
      },
    }
  );
}

// Selectors

function userFromApi({ data }: FromApi<ApiUser>): User {
  let config: UserConfig;
  if (RTUserConfig.guard(data.config)) {
    // Note: runtypes won't strip extra properties from the config, so if
    // the user has anything else in their config it'll still be there, it
    // just won't be accessible to TS code. This is useful though since
    // updating the user merges config updates with the existing config,
    // so we shouldn't have to worry about breaking a user's config
    config = data.config;
  } else {
    config = {
      profiles: [],
    };
  }

  return {
    username: data.username,
    config,
  };
}

function selectGroups({ data }: GroupList): Group[] {
  return data.map((apiGroup) => ({
    id: apiGroup.id,
    name: apiGroup.name,
  }));
}
