import React from "react";
import { Clear } from "@mui/icons-material";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import prettyBytes from "pretty-bytes";
import Breakable from "../../../components/Breakable";
import { useCurrentLog } from "../../../hooks";
import { useDraftExtraction } from "../../../providers/DraftExtractionProvider";
import { formatTimeRange } from "../../../utils";

export default function ExtractionListView() {
  const logQuery = useCurrentLog();

  const { isInitialized, estimator, creator, extractionTopics, removeRange } =
    useDraftExtraction();

  let content;
  if (!isInitialized || !logQuery.isSuccess) {
    content = (
      <List disablePadding>
        <ListItem
          sx={{
            flexDirection: "column",
            alignItems: "stretch",
          }}
          disableGutters
        >
          <ListItemText>
            <Skeleton width="30ch" />
          </ListItemText>
          <List disablePadding>
            {[1, 2, 3].map((val) => (
              <ListItem key={val}>
                <ListItemText>
                  <Skeleton width="20ch" />
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </ListItem>
      </List>
    );
  } else if (extractionTopics.length === 0) {
    content = (
      <div>
        <Typography variant="h6" component="p" align="center" paragraph>
          No Topics
        </Typography>
        <Typography align="center" paragraph>
          No topics added for extraction yet.
        </Typography>
      </div>
    );
  } else {
    content = (
      <List
        disablePadding
        subheader={
          <ListSubheader disableGutters>
            Topics: {extractionTopics.length}
          </ListSubheader>
        }
      >
        {_.sortBy(extractionTopics, "topic.name").map(({ topic, ranges }) => {
          let estimationMessage;
          if (!estimator.isSuccess || estimator.data.isTopicEmpty(topic.id)) {
            estimationMessage = "-";
          } else {
            const prettyEstimatedSize = prettyBytes(
              estimator.data.topicSize(topic.id)!,
              {
                binary: true,
                maximumFractionDigits: 2,
              }
            );

            estimationMessage = `Estimated size: ${prettyEstimatedSize}`;
          }

          const sortedRanges = _.sortBy(
            ranges,
            (range) => range.startTimeMs ?? 0
          );

          return (
            <ListItem
              key={topic.id}
              sx={{
                flexDirection: "column",
                alignItems: "stretch",
              }}
              disableGutters
            >
              <ListItemText secondary={estimationMessage}>
                <Breakable text={topic.name} sepRegex={/(\/)/} />
              </ListItemText>
              <List disablePadding>
                {sortedRanges.map((range) => {
                  const removeButtonDisabled =
                    creator.isLoading || creator.isSuccess;

                  let removeButton = (
                    <IconButton
                      disabled={removeButtonDisabled}
                      edge="end"
                      aria-label="Remove this topic time range from the extraction"
                      onClick={() => removeRange({ topic, range })}
                      size="large"
                    >
                      <Clear />
                    </IconButton>
                  );

                  if (!removeButtonDisabled) {
                    removeButton = (
                      <Tooltip title="Remove range">{removeButton}</Tooltip>
                    );
                  }

                  const secondaryAction = (
                    <ListItemSecondaryAction>
                      {removeButton}
                    </ListItemSecondaryAction>
                  );

                  return (
                    <ListItem key={range.startTimeMs ?? 0}>
                      <ListItemText>
                        {formatTimeRange(
                          range.startTimeMs,
                          range.endTimeMs,
                          logQuery.data.startDate
                        )}
                      </ListItemText>
                      {secondaryAction}
                    </ListItem>
                  );
                })}
              </List>
            </ListItem>
          );
        })}
      </List>
    );
  }

  return <div>{content}</div>;
}
