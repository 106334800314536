import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { find, orderBy } from "lodash";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import DescriptionList from "../../../components/DescriptionList";
import DividedCardHeader from "../../../components/DividedCardHeader";
import TimeRange from "../../../components/labels/TimeRange";
import Tag from "../../../components/tags/Tag";
import { useFeature } from "../../../providers/Features";
import { useDeleteLabel } from "../../../queries";

export default function LabelDetailListCard({
  username,
  isLoading,
  disabled = false,
  disabledReason,
  logId,
  labels,
  cardElevation = 4,
}) {
  const [selectedLabel, setSelectedLabel] = useState(null);

  const deleteBehavior = useFeature("labels.delete");

  const { enqueueSnackbar } = useSnackbar();

  const confirm = useConfirm();

  const { isLoading: isSubmitting, mutate: deleteLabel } =
    useDeleteLabel(logId);

  async function handleDeleteButtonClick() {
    try {
      await confirm({
        title: "Delete label?",
        description: "This action is permanent",
        confirmationText: "Delete",
        cancellationButtonProps: { color: "primary" },
      });

      deleteLabel(selectedLabel, {
        onSuccess() {
          setSelectedLabel(null);
          enqueueSnackbar("Label deleted!", {
            variant: "success",
          });
        },
        onError() {
          enqueueSnackbar("Unable to delete label", {
            variant: "error",
          });
        },
      });
    } catch (e) {
      /* noop */
    }
  }

  function tagForLabel(label) {
    return (
      <Tag
        type={label.tag.type}
        name={label.tag.name}
        ChipProps={{
          sx: { mr: 0.5 },
          component: "span",
          size: "small",
        }}
      />
    );
  }

  function makeListDetailColumns(list, details) {
    return (
      <>
        <Grid item xs zeroMinWidth>
          {list}
        </Grid>
        <Grid item xs="auto">
          <Divider orientation="vertical" />
        </Grid>
        <Grid sx={{ position: "relative" }} item xs zeroMinWidth>
          {details}
        </Grid>
      </>
    );
  }

  let content;
  if (isLoading) {
    content = (
      <Grid container spacing={2}>
        {makeListDetailColumns(
          <List disablePadding>
            {[1, 2, 3].map((val) => (
              <ListItem key={val}>
                <ListItemText
                  secondary={
                    <Grid component="span" container spacing={2}>
                      <Grid component="span" item xs={3}>
                        <Skeleton width="100%" />
                      </Grid>
                      <Grid component="span" item xs={9}>
                        <Skeleton width="100%" />
                      </Grid>
                    </Grid>
                  }
                >
                  <Skeleton width="40%" />
                </ListItemText>
              </ListItem>
            ))}
          </List>,
          <DescriptionList
            items={[
              { key: "Start", value: <Skeleton width="20ch" /> },
              { key: "Tag", value: <Skeleton width="8ch" /> },
              {
                key: "Description",
                value: <Skeleton variant="rectangular" height={50} />,
              },
              { key: "Created at", value: <Skeleton width="25ch" /> },
            ]}
            layout="stacked"
          />
        )}
      </Grid>
    );
  } else if (disabled) {
    content = <Typography>{disabledReason}</Typography>;
  } else if (labels.length === 0) {
    content = (
      <>
        <Typography variant="h6" component="p" align="center" paragraph>
          No Labels
        </Typography>
        <Typography align="center" paragraph>
          Nobody has created a label for this log yet.
        </Typography>
      </>
    );
  } else {
    const sortedLabels = orderBy(
      labels,
      [
        (label) => label.startTimeMs,
        (label) => label.endTimeMs ?? label.startTimeMs,
      ],
      ["asc", "asc"]
    );

    const list = (
      <List
        disablePadding
        subheader={
          <ListSubheader disableSticky>
            Labels: {sortedLabels.length}
          </ListSubheader>
        }
      >
        {sortedLabels.map((label) => (
          <ListItem
            key={label.id}
            button
            selected={selectedLabel === label.id}
            onClick={() => setSelectedLabel(label.id)}
          >
            <ListItemText
              secondary={
                <>
                  {label.tag && tagForLabel(label)}
                  {label.description}
                </>
              }
              secondaryTypographyProps={{
                noWrap: true,
              }}
            >
              <TimeRange label={label} useTypography={false} />
            </ListItemText>
          </ListItem>
        ))}
      </List>
    );

    let details;
    const label = find(labels, { id: selectedLabel });
    if (selectedLabel != null && label !== undefined) {
      const canDelete = username === label.createdBy;

      details = (
        <>
          <DescriptionList
            items={[
              {
                key: "Time",
                value: <TimeRange label={label} asLink keepExistingSearch />,
              },
              {
                key: "Tag",
                value: label.tag ? tagForLabel(label) : "-",
              },
              { key: "Description", value: label.description ?? "-" },
              {
                key: "Created at",
                value: label.createdAt.toUTCString().replace("GMT", "UTC"),
              },
            ]}
            layout="stacked"
          />
          <Box mt={4}>
            <Tooltip
              title={
                deleteBehavior.disabled
                  ? deleteBehavior.reason
                  : !canDelete
                  ? "You can only delete labels you created"
                  : ""
              }
            >
              <span>
                <LoadingButton
                  disabled={deleteBehavior.disabled || !canDelete}
                  loading={isSubmitting}
                  color="error"
                  variant="outlined"
                  onClick={handleDeleteButtonClick}
                >
                  Delete
                </LoadingButton>
              </span>
            </Tooltip>
          </Box>
        </>
      );
    } else {
      details = <Typography>Nothing selected</Typography>;
    }

    content = (
      <Grid
        sx={{
          "& > .MuiGrid-item": {
            maxHeight: 350,
            overflowY: "auto",
          },
        }}
        container
        spacing={2}
      >
        {makeListDetailColumns(list, details)}
      </Grid>
    );
  }

  return (
    <Card elevation={cardElevation}>
      <DividedCardHeader
        height="2rem"
        title="Labels"
        titleTypographyProps={{
          variant: "h6",
          component: "h2",
        }}
      />
      <CardContent>{content}</CardContent>
    </Card>
  );
}
