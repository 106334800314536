import _ from "lodash";

const unit = ({ topic = "", path = "" }) => {
  const val = _.get(rosTopics, `${topic}.${path}`, "");
  return val;
};

export default unit;

const rosTopics = {
  "sensor_msgs/Image": {
    height: "px",
    width: "px",
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "sensor_msgs/CameraInfo": {
    height: "px",
    width: "px",
    roi: {
      x_offset: "px",
      y_offset: "px",
      height: "px",
      width: "px",
    },
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "dynamic_reconfigure/Config": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "dynamic_reconfigure/ConfigDescription": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "bond/Status": {
    heartbeat_timeout: "s",
    heartbeat_period: "s",
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "std_msgs/String": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "multisense_ros/Histogram": {
    height: "px",
    width: "px",
    fps: "fps",
    exposure_time: "s",
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "sensor_msgs/Imu": {
    linear_acceleration: {
      x: "m/s²",
      y: "m/s²",
      z: "m/s²",
    },
    angular_velocity: {
      x: "rad/sec",
      y: "rad/sec",
      z: "rad/sec",
    },
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "geometry_msgs/QuaternionStamped": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "racer_msg/CanFrame": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "sensor_msgs/NavSatFix": {
    latitude: "°",
    longitude: "°",
    altitude: "m",
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "sensor_msgs/MagneticField": {
    magnetic_field: "T",
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "sensor_msgs/Temperature": {
    temperature: "°C",
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "geometry_msgs/Vector3Stamped": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "sensor_msgs/TimeReference": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "diagnostic_msgs/DiagnosticArray": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "tf2_msgs/TFMessage": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "rosgraph_msgs/Log": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "diagnostic_msgs/DiagnosticStatus": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "racer_msg/PowerStatusArray": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "racer_msg/PpsStatus": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "nav_msgs/Odometry": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "dvs_msgs/EventArray": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "sensor_msgs/PointCloud2": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "std_msgs/Time": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "velodyne_msgs/VelodyneScan": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "racer_msg/ChronySourceStatus": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "racer_msg/RacerLogStats": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "racer_msg/VehicleSteer": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "racer_msg/VehicleSpeed": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "phoenix_vpp_interface/VehicleState": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "can_msgs/Frame": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
  "racer_msg/VehicleBrake": {
    header: {
      time_stamp: {
        secs: "s",
        nsecs: "ns",
      },
    },
  },
};
