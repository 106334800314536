import React, { useEffect, useState } from "react";
import { Signer } from "@aws-amplify/core";
import AWS from "aws-sdk";
import TrajectoryMap from "./TrajectoryMap";

const identityPoolId = process.env.REACT_APP_MAP_IDENTITY_POOL_ID;
const mapName = process.env.REACT_APP_MAP_NAME;

AWS.config.region = identityPoolId.split(":")[0];

// https://docs.aws.amazon.com/location/latest/developerguide/tutorial-maplibre-gl-js.html#tutorial-maplibre-js-writing-js

function transformRequest(credentials, url, resourceType) {
  if (resourceType === "Style" && !url.includes("://")) {
    // resolve to an AWS URL
    url = `https://maps.geo.${AWS.config.region}.amazonaws.com/maps/v0/maps/${url}/style-descriptor`;
  }

  if (url.includes("amazonaws.com")) {
    // only sign AWS requests (with the signature as part of the query string)
    return {
      url: Signer.signUrl(url, {
        access_key: credentials.accessKeyId,
        secret_key: credentials.secretAccessKey,
        session_token: credentials.sessionToken,
      }),
    };
  }

  // don't sign
  return { url };
}

export default function TrajectoryMapContainer({
  trajectoryData,
  height,
  width,
  errorElement,
  loadingElement = null,
  timestampMs,
}) {
  const [credentials, setCredentials] = useState(null);

  useEffect(() => {
    async function getCredentials() {
      const creds = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
      });

      await creds.getPromise();

      setCredentials(creds);
    }

    getCredentials();
  }, []);

  if (trajectoryData == null) {
    return errorElement;
  } else if (credentials == null) {
    return loadingElement;
  } else {
    return (
      <TrajectoryMap
        height={height}
        width={width}
        transformRequest={(url, resourceType) =>
          transformRequest(credentials, url, resourceType)
        }
        mapStyle={mapName}
        trajectoryData={trajectoryData}
        timestampMs={timestampMs}
      />
    );
  }
}
