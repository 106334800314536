import { Tag as ApiTag } from "racer-openapi-ts-sdk";
import { useQuery } from "react-query";
import { useApi } from "../providers/ApiProvider";
import { FromApi, Tag } from "./types";

// Query key factory

export const tagKeys = {
  all: ["tags"] as const,
};

// Queries

export function useTags() {
  const { authenticatedClient } = useApi();

  return useQuery(tagKeys.all, authenticatedClient.listTags, {
    select: fromApi,
  });
}

// Selectors

function fromApi({ data }: FromApi<ApiTag[]>): Tag[] {
  return data.map((tag) => ({
    name: tag.value,
    type: tag.type,
  }));
}
