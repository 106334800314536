import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePlayback } from "../contexts";
import { timestampFromSearch } from "../utils";

export default function TimestampUpdater() {
  const location = useLocation();

  const playback = usePlayback();

  const { setTimestampMs } = playback;

  useEffect(
    function updateTimestampFromUrlSearch() {
      if (playback.isLoading) {
        return;
      }

      const maybeTimestampMs = timestampFromSearch(location.search);

      if (maybeTimestampMs !== undefined) {
        setTimestampMs(maybeTimestampMs);
      }
    },
    [playback.isLoading, location, setTimestampMs]
  );

  return null;
}
