import React from "react";
import Demo from "./Demo";
import Racer from "./Racer";

let App: React.ComponentType;
if (process.env.REACT_APP_DEPLOY_ENV === "demo") {
  App = Demo;
} else if (process.env.REACT_APP_DEPLOY_ENV === "racer") {
  App = Racer;
} else if (process.env.REACT_APP_DEPLOY_ENV === "unset") {
  App = () => null;
} else {
  throw new Error('"REACT_APP_DEPLOY_ENV" env var not set');
}

export default App;
