import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import {
  anchorRef,
  bindFocus,
  bindPopper,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useLocalstorage } from "rooks";
import * as Yup from "yup";
import { renderAdminEmailLink } from "../../components/auth";
import { useRespondToChallenge } from "../../queries";

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min("8", "Must be at least 8 characters long")
    .matches(/\d/, "Must contain at least 1 number")
    .matches(/[A-Z]/, "Must contain at least 1 uppercase letter")
    .matches(/[a-z]/, "Must contain at least 1 lowercase letter")
    .matches(
      /[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/,
      "Must contain at least 1 special character"
    )
    .required("The password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "This password does not match")
    .required("Password confirmation is required"),
});

export default function ResetPasswordForm({ challenge }) {
  const [storedEmail] = useLocalstorage("email");
  const [showPasswords, setShowPasswords] = useState({
    password: false,
    confirmPassword: false,
  });

  const challengeMutation = useRespondToChallenge();

  const popupState = usePopupState({
    variant: "popper",
    popupId: "password-requirements-popover",
    disableAutoFocus: true,
  });

  async function handleRespondToChallenge(values) {
    try {
      await challengeMutation.mutateAsync({
        challenge,
        challengeArgs: {
          email: storedEmail,
          newPassword: values.password,
        },
      });
    } catch {
      /* noop */
    }
  }

  function togglePasswordField(field) {
    setShowPasswords({
      ...showPasswords,
      [field]: !showPasswords[field],
    });
  }

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      onSubmit={handleRespondToChallenge}
      validationSchema={resetPasswordSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <Typography variant="h6" component="h2" gutterBottom>
            Reset Password
          </Typography>
          <Typography paragraph>
            You must reset your password to continue
          </Typography>
          <div ref={anchorRef(popupState)}>
            <Field
              {...bindFocus(popupState)}
              component={TextField}
              id="password"
              name="password"
              type={showPasswords.password ? "text" : "password"}
              fullWidth
              margin="normal"
              variant="outlined"
              label="New password"
              InputProps={{
                autoComplete: "new-password",
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        showPasswords.password
                          ? "Hide password"
                          : "Show password"
                      }
                    >
                      <IconButton
                        aria-label={
                          showPasswords.password
                            ? "Hide password"
                            : "Show password"
                        }
                        onClick={() => togglePasswordField("password")}
                        size="large"
                      >
                        {showPasswords.password ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Popper placement="right" {...bindPopper(popupState)}>
            <Paper sx={{ p: 2 }} elevation={6}>
              <Typography>
                Passwords must meet the following criteria:
              </Typography>
              <Typography
                sx={{
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                }}
                component="ul"
              >
                <li>At least 8 characters long</li>
                <li>At least 1 uppercase character</li>
                <li>At least 1 lowercase character</li>
                <li>At least 1 number</li>
                <li>At least 1 special character</li>
              </Typography>
            </Paper>
          </Popper>
          <Field
            component={TextField}
            id="confirmPassword"
            name="confirmPassword"
            type={showPasswords.confirmPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            variant="outlined"
            label="Confirm new password"
            InputProps={{
              autoComplete: "new-password",
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      showPasswords.confirmPassword
                        ? "Hide password"
                        : "Show password"
                    }
                  >
                    <IconButton
                      aria-label={
                        showPasswords.confirmPassword
                          ? "Hide password"
                          : "Show password"
                      }
                      onClick={() => togglePasswordField("confirmPassword")}
                      size="large"
                    >
                      {showPasswords.confirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <Button
            sx={{ mt: 2 }}
            disabled={isSubmitting}
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
          >
            Reset Password
          </Button>
          {challengeMutation.isError && (
            <Alert sx={{ mt: 4 }} variant="filled" severity="error">
              Unable to reset your password. If this error continues, email the
              site admin at {renderAdminEmailLink()}
            </Alert>
          )}
        </Form>
      )}
    </Formik>
  );
}
