import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { find, map } from "lodash";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import NavigationLink from "../components/NavigationLink";
import StatusChip from "../components/StatusChip";
import config from "../config";
import { formatTimestamp } from "../new-utils";
import { Log, LogSearchOptions, useGroups, useLogs } from "../queries";
import {
  renderAttr,
  renderEmpty,
  renderError,
  renderFieldOptions,
  SearchList,
} from "../search-list";
import * as paths from "../views/paths";

interface LogListItemProps {
  log?: Log;
  searchOptions?: Required<LogSearchOptions>;
}

function LogSearch() {
  const groupsQuery = useGroups();

  return (
    <>
      <Helmet>
        <title>
          Search Logs &bull; {config.datastoreInstanceName} DataStore
        </title>
      </Helmet>
      <SearchList
        title="Logs"
        useSearchQuery={useLogs}
        filterSchema={yup.object().shape({
          nameLike: yup.string().ensure(),
          status: yup
            .string()
            .ensure()
            .oneOf(["", "error", "processed", "processing", "ready"]),
          groupId: yup.lazy((val) =>
            val === "" || val == null
              ? // Nothing provided or value is an empty string: just cast and pass along
                yup.string().ensure()
              : groupsQuery.data === undefined
              ? // A truthy string but groups aren't known yet: at least make sure it's a UUID
                yup.string().ensure().uuid()
              : // Otherwise: make sure it's one of the groups a user can filter by
                yup
                  .string()
                  .ensure()
                  .oneOf(["", ...map(groupsQuery.data, "id")])
          ),
        })}
        sortOptions={[
          { value: "startDate", name: "Date" },
          { value: "duration", name: "Duration" },
          { value: "size", name: "Size" },
          { value: "name", name: "Name" },
        ]}
        pageSizeOptions={[15, 25, 50]}
        render={{
          error() {
            return renderError("An error occurred searching for logs");
          },
          loading() {
            return (
              <List>
                {[1, 2, 3].map((index) => (
                  <LogListItem key={index} />
                ))}
              </List>
            );
          },
          items(logs, searchModel) {
            return logs.map((log) => (
              <LogListItem key={log.id} log={log} searchOptions={searchModel} />
            ));
          },
          empty() {
            return renderEmpty("No logs were found");
          },
          filters() {
            return (
              <>
                <Field
                  component={TextField}
                  id="log-name-filter"
                  name="nameLike"
                  label="Log name"
                  fullWidth
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  id="log-status-filter"
                  name="status"
                  label="Ingest status"
                  fullWidth
                  select
                >
                  {renderFieldOptions([
                    { value: "", name: <i>All</i> },
                    { value: "processed", name: "Processed" },
                    { value: "processing", name: "Processing" },
                    { value: "error", name: "Error" },
                    { value: "ready", name: "Ready" },
                  ])}
                </Field>
                <Field
                  select
                  fullWidth
                  component={TextField}
                  id="log-group-filter"
                  name="groupId"
                  label="Group"
                  disabled={groupsQuery.data === undefined}
                >
                  {renderFieldOptions([
                    { value: "", name: <i>All</i> },
                    ...map(groupsQuery.data ?? [], (group) => ({
                      value: group.id,
                      name: group.name,
                    })),
                  ])}
                </Field>
              </>
            );
          },
        }}
      />
    </>
  );
}

LogSearch.layoutOptions = SearchList.layoutOptions;

export default LogSearch;

function LogListItem({ log, searchOptions }: LogListItemProps) {
  const groupsQuery = useGroups();

  let date, name, duration, group, status;
  if (log === undefined || searchOptions === undefined) {
    date = <Skeleton width="25ch" />;

    name = <Skeleton width="45ch" />;

    duration = <Skeleton width="6ch" />;

    group = <Skeleton width="6ch" />;

    status = undefined;
  } else {
    date =
      log.startDate != null
        ? log.startDate.toUTCString().replace("GMT", "UTC")
        : "Date unavailable";

    name = (
      <NavigationLink to={paths.toLog(log.id)}>
        {log.metadata.name}
      </NavigationLink>
    );

    duration =
      log.duration != null
        ? formatTimestamp(log.duration, {
            keepDecimalsOnWholeSeconds: false,
            secondsDecimalDigits: 0,
          })
        : "-";

    status = <StatusChip status={log.status} />;

    // Reason for 'any' cast:
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/25758#issuecomment-389372249
    const groupName = find(groupsQuery.data ?? [], {
      id: log.groupId as any,
    })?.name;
    if (groupName === undefined) {
      group = <Skeleton width="6ch" />;
    } else {
      group = groupName;
    }
  }

  return (
    <ListItem sx={{ alignItems: "stretch" }} divider>
      <ListItemText disableTypography>
        <Typography>{date}</Typography>
        <Stack direction="row" alignItems="center">
          <Typography variant="h6" component="p" mr={2}>
            {name}
          </Typography>
          {status}
        </Stack>
        <List
          sx={{
            display: "flex",
            alignItems: "center",
            "& .MuiListItem-root": {
              width: "auto",
              "&:first-of-type": {
                pl: 0,
              },
            },
          }}
        >
          {renderAttr("Duration", duration)}
          {renderAttr("Group", group)}
        </List>
      </ListItemText>
    </ListItem>
  );
}
