import React from "react";
import { Card, CardContent, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import SignInForm from "../../components/auth/SignInForm";
import config from "../../config";
import { useAuth } from "../../providers/AuthProvider";
import { useSignIn } from "../../queries";
import * as paths from "../paths";
import ExternalSignIn from "./ExternalSignIn";
import ResetPasswordForm from "./ResetPasswordForm";

export default function SignIn() {
  const { authState } = useAuth();
  const signInMutation = useSignIn();

  let content;
  if (authState === "authenticated") {
    return <Redirect to={paths.toLogSearch({ status: "processed" })} />;
  } else if (signInMutation.data && "session" in signInMutation.data) {
    content = <ResetPasswordForm challenge={signInMutation.data} />;
  } else if (config.externalAuth === null) {
    content = <SignInForm signIn={signInMutation} />;
  } else {
    content = <ExternalSignIn />;
  }

  return (
    <>
      <Helmet>
        <title>Sign In &bull; {config.datastoreInstanceName} DataStore</title>
      </Helmet>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "75vh",
          "& .MuiCard-root": {
            m: "auto",
            width: 1,
          },
        }}
        maxWidth="sm"
      >
        <Card elevation={4}>
          <CardContent>{content}</CardContent>
        </Card>
      </Container>
    </>
  );
}
