import React, { FormEvent } from "react";
import { AccessTime, Clear, DoubleArrow } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import invariant from "invariant";
import _ from "lodash";
import { ClockEnd, ClockStart } from "mdi-material-ui";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { formatTimestamp } from "../../../new-utils";
import { useFeature } from "../../../providers/Features";
import { Tag, useCreateLabel, useTags } from "../../../queries";
import * as paths from "../../paths";
import { LabelFormState, LabelRange } from "../Records";
import { usePlayback } from "../contexts";

type LabelAction =
  | "from-start"
  | "from-now"
  | "from-end"
  | "shift-end"
  | "clear-end"
  | "to-now"
  | "to-end";

export interface LabelFormProps {
  labelFormState: LabelFormState;
  setLabelFormState: React.Dispatch<React.SetStateAction<LabelFormState>>;
}

export default function LabelForm({
  labelFormState,
  setLabelFormState,
}: LabelFormProps) {
  const { labelRange, selectedTag, content } = labelFormState;

  const createBehavior = useFeature("labels.create");

  const playback = usePlayback();

  const { logId } = useParams<paths.LogParams>();

  const labelMutation = useCreateLabel(logId);

  const tagsQuery = useTags();

  const { enqueueSnackbar } = useSnackbar();

  const computedLabelRange =
    labelRange ??
    (playback.isLoading ? undefined : [playback.boundsMs[0], null]);

  const actionsDisabled =
    computedLabelRange === undefined || playback.isLoading;

  function handleRangeChange(labelAction: LabelAction) {
    invariant(
      !actionsDisabled,
      "Attempting range change while actions are disabled"
    );

    const [currentStartMs, currentEndMs] = computedLabelRange;

    let newRange: LabelRange;
    switch (labelAction) {
      case "from-start":
        newRange = [playback.boundsMs[0], currentEndMs];
        break;
      case "from-now":
        newRange = [playback.timestampMs, currentEndMs];
        break;
      case "from-end":
        newRange = [playback.boundsMs[1], null];
        break;
      case "shift-end":
        invariant(
          currentEndMs !== null,
          "Attempting to set label start time to null. This shouldn't happen"
        );

        newRange = [currentEndMs, playback.boundsMs[1]];
        break;
      case "clear-end":
        newRange = [currentStartMs, null];
        break;
      case "to-now":
        newRange = [currentStartMs, playback.timestampMs];
        break;
      case "to-end":
        newRange = [currentStartMs, playback.boundsMs[1]];
        break;
      default:
        const _exhaustiveCheck: never = labelAction;
        throw new Error(`Unknown label action: ${_exhaustiveCheck}`);
    }

    setLabelFormState({
      ...labelFormState,
      labelRange: newRange,
    });
  }

  function handleTagChange(e: React.SyntheticEvent, tag: Tag | null) {
    setLabelFormState({
      ...labelFormState,
      selectedTag: tag,
    });
  }

  function handleDescriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    setLabelFormState({
      ...labelFormState,
      content: e.target.value,
    });
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    invariant(
      !actionsDisabled,
      "Attempting to submit form while actions are disabled"
    );

    const [startTimeMs, endTimeMs] = computedLabelRange;

    labelMutation.mutate(
      {
        startTimeMs,
        endTimeMs,
        tag: selectedTag,
        description: content || null, // No empty strings
      },
      {
        onSuccess() {
          setLabelFormState({
            ...labelFormState,
            selectedTag: null,
            content: null,
          });

          enqueueSnackbar("Label created!", {
            variant: "success",
          });
        },
        onError() {
          enqueueSnackbar("Unable to create label", {
            variant: "error",
          });
        },
      }
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid sx={{ position: "relative" }} container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={actionsDisabled}
                fullWidth
                label="At/From"
                variant="outlined"
                value={
                  actionsDisabled
                    ? ""
                    : formatTimestamp(computedLabelRange[0], {
                        precision: 1,
                        relativeToMs: playback.boundsMs[0],
                      })
                }
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <>
                      <Tooltip title="Log start">
                        <span>
                          <IconButton
                            disabled={actionsDisabled}
                            size="small"
                            aria-label="Start a label at the start of the log"
                            onClick={() => handleRangeChange("from-start")}
                          >
                            <ClockStart />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Current time">
                        <span>
                          <IconButton
                            // Disabled if the label end time is not null
                            // and the current timestamp is greater than or
                            // equal to the label end time as it would cause
                            // the ends to overlap
                            disabled={
                              actionsDisabled ||
                              (computedLabelRange[1] !== null &&
                                playback.timestampMs >= computedLabelRange[1])
                            }
                            size="small"
                            aria-label="Start a label at the current playback time"
                            onClick={() => handleRangeChange("from-now")}
                          >
                            <AccessTime />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Log end">
                        <span>
                          <IconButton
                            // Disabled if there's a label end time as it would
                            // cause the ends to overlap
                            disabled={
                              actionsDisabled || computedLabelRange[1] !== null
                            }
                            size="small"
                            aria-label="Start a label at the end of the log"
                            onClick={() => handleRangeChange("from-end")}
                          >
                            <ClockEnd />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  ),
                }}
              />
            </Grid>
            <Tooltip title="Shift end time">
              <Box
                sx={{
                  zIndex: 1,
                  backgroundColor: "background.paper",
                  borderRadius: "50%",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%) rotate(-90deg)",
                  "& .MuiIconButton-root": {
                    border: 1,
                    borderColor: "rgba(0, 0, 0, 0.23)",
                    "&:hover": {
                      borderColor: "initial",
                    },
                    "&:focus": {
                      borderColor: "primary.main",
                      borderWidth: 2,
                    },
                  },
                }}
              >
                <IconButton
                  disabled={
                    actionsDisabled ||
                    // Disabled if there is no label end time to shift or the
                    // end time is equal to the log's duration since that would
                    // cause the start to overlap with the end
                    computedLabelRange[1] === playback.boundsMs[1] ||
                    computedLabelRange[1] === null
                  }
                  aria-label="Shift end time to start time"
                  onClick={() => handleRangeChange("shift-end")}
                  size="large"
                >
                  <DoubleArrow />
                </IconButton>
              </Box>
            </Tooltip>
            <Grid item xs={12}>
              <TextField
                disabled={actionsDisabled}
                fullWidth
                label="To (optional)"
                variant="outlined"
                value={
                  actionsDisabled || computedLabelRange[1] === null
                    ? ""
                    : formatTimestamp(computedLabelRange[1], {
                        precision: 1,
                        relativeToMs: playback.boundsMs[0],
                      })
                }
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <>
                      <Tooltip title="Clear end selection">
                        <span>
                          <IconButton
                            // Disabled if there's no end time to clear
                            disabled={
                              actionsDisabled || computedLabelRange[1] === null
                            }
                            size="small"
                            aria-label="Clear label end time selection"
                            onClick={() => handleRangeChange("clear-end")}
                          >
                            <Clear />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Current time">
                        <span>
                          <IconButton
                            // Disabled if the current time is less than or
                            // equal to the label start time as it would cause
                            // an overlap
                            disabled={
                              actionsDisabled ||
                              playback.timestampMs <= computedLabelRange[0]
                            }
                            size="small"
                            aria-label="Optionally finish a label at the current playback time"
                            onClick={() => handleRangeChange("to-now")}
                          >
                            <AccessTime />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Log end">
                        <span>
                          <IconButton
                            // Disabled if the label's start time is set to
                            // the log's duration as it would cause an overlap
                            disabled={
                              actionsDisabled ||
                              computedLabelRange[0] === playback.boundsMs[1]
                            }
                            size="small"
                            aria-label="Optionally finish a label at the end of the log"
                            onClick={() => handleRangeChange("to-end")}
                          >
                            <ClockEnd />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={actionsDisabled || labelMutation.isLoading}
            fullWidth
            label="Description"
            variant="outlined"
            value={content ?? ""}
            onChange={handleDescriptionChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            sx={{ width: 1 }}
            disabled={actionsDisabled || labelMutation.isLoading}
            renderInput={(params) => (
              <TextField {...params} label="Tag" variant="outlined" />
            )}
            groupBy={(tag) => tag.type}
            options={_.sortBy(tagsQuery.data ?? [], "type")}
            getOptionLabel={(tag) => `${tag.type}:${tag.name}`}
            isOptionEqualToValue={(optionTag, valueTag) =>
              optionTag.name === valueTag.name &&
              optionTag.type === valueTag.type
            }
            renderOption={(props, tag) => <li {...props}>{tag.name}</li>}
            value={selectedTag}
            onChange={handleTagChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Tooltip title={createBehavior.disabled ? createBehavior.reason : ""}>
            <span>
              <LoadingButton
                disabled={
                  createBehavior.disabled ||
                  actionsDisabled ||
                  !(content || selectedTag)
                }
                loading={labelMutation.isLoading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Create Label
              </LoadingButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
}
