import { Topic, useTopicQueries, useTopics } from "../../queries";
import { topicFromApi } from "../../queries/selectors";
import { reduceTopicCompQueries } from "./utils";

export function useTopicComparison(name: Topic["name"] | null) {
  // First, fetch up to 3 topics from any log that have the same
  // name as the given topic. These will be used for comparisons.
  //
  // Note: Because the backend doesn't have a special method set up
  // for comparisons, the 3 topics returned are arbitrary, so it's
  // possible one of the returned topics is the same topic we're
  // comparing against
  const similarTopicsListQuery = useTopics({
    name,
    pageSize: 3,
  });

  const listedTopics = similarTopicsListQuery.data ?? [];

  // With the <= 3 topic we have, we need to fetch the stats individually
  // for each topic so they can be used for comparisons
  const similarTopicsQueries = useTopicQueries(
    listedTopics.map((topic) => ({
      logId: topic.logId,
      topicId: topic.id,
      queryOpts: {
        select: topicFromApi,
      },
    }))
  );

  return reduceTopicCompQueries(similarTopicsListQuery, similarTopicsQueries);
}
