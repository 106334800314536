import invariant from "invariant";
import { useFullscreen as useRooksFullscreen } from "rooks";

export default function useFullscreen(
  ...args: Parameters<typeof useRooksFullscreen>
) {
  const fullscreen = useRooksFullscreen(...args);

  // The types for useFullscreen from rooks are incorrect since they never
  // actually return undefined. Rather than unsafely asserting the return
  // type to be non-undefined though, this custom hook is still careful to
  // ensure it's **truly** not undefined
  invariant(
    fullscreen !== undefined,
    "Result of useFullscreen shouldn't be undefined"
  );

  return fullscreen;
}
