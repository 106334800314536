import { floor } from "lodash";
import prettyMilliseconds from "pretty-ms";
import { UseQueryResult } from "react-query";

export interface FormatTimestampOptions extends prettyMilliseconds.Options {
  precision?: number;
  relativeToMs?: number;
}

export function formatTimestamp(
  timestampMs: number,
  {
    precision,
    relativeToMs = 0,
    colonNotation = true,
    keepDecimalsOnWholeSeconds = true,
    ...prettyMsOptions
  }: FormatTimestampOptions = {}
): string {
  let relativeStampMs = timestampMs - relativeToMs;

  if (precision !== undefined) {
    relativeStampMs = floor(relativeStampMs, precision);
  }

  return prettyMilliseconds(relativeStampMs, {
    colonNotation,
    keepDecimalsOnWholeSeconds,
    ...prettyMsOptions,
  });
}

export function reduceQueries<TData = unknown>(
  queries: UseQueryResult<TData>[]
): Pick<
  UseQueryResult<ArrayOf<TData>>,
  "isIdle" | "isError" | "isLoading" | "isSuccess" | "data"
> {
  // Optimistic defaults useful for the boolean operations
  // performed in the forEach callback
  let isError = false;
  let isLoading = false;
  let isSuccess = true;
  let data: any[] = [];

  // The query reduction goes as follows: data will only be returned
  // if all required queries are successful, query success statuses
  // are only considered if no query is loading, and loading statuses
  // are only considered if no query is in an error state.
  queries.forEach((query) => {
    // Any query in an error state makes the overall error state true
    isError ||= query.isError;

    // Any loading query makes the overall loading state true
    isLoading ||= query.isLoading;

    // All queries must be successful for the overall loading state to be true
    isSuccess &&= query.isSuccess;

    // Go ahead and combine all the data we can from all the queries.
    // If the query isn't in a successful state it'll be ignored
    if (query.data) {
      if (Array.isArray(query.data)) {
        data.push(...query.data);
      } else {
        data.push(query.data);
      }
    }
  });

  if (isError) {
    return {
      isIdle: false,
      isError,
      isLoading: false,
      isSuccess: false,
      data: undefined,
    };
  } else if (isLoading) {
    return {
      isIdle: false,
      isError,
      isLoading,
      isSuccess: false,
      data: undefined,
    };
  } else {
    return {
      isIdle: false,
      isError,
      isLoading,
      isSuccess,
      data: isSuccess ? (data as ArrayOf<TData>) : undefined,
    };
  }
}

type ArrayOf<Type> = Type extends Array<infer Item> ? Array<Item> : Array<Type>;
