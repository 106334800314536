import React from "react";
import { Box, Typography } from "@mui/material";
import { secondsToMilliseconds } from "date-fns";
import _ from "lodash";
import { TimerSand } from "mdi-material-ui";
import { useParams } from "react-router-dom";
import Center from "../../../components/Center";
import Error from "../../../components/Error";
import Loading from "../../../components/Loading";
import NavigationLink from "../../../components/NavigationLink";
import { SampleFreq } from "../../../queries";
import * as paths from "../../paths";
import { usePlayback } from "../contexts";
import useFirstMessage from "../hooks/useFirstMessage";
import useWindow from "../hooks/useWindow";
import { Timestep } from "../types";

interface ImagePanelProps {
  topic: any;
}

export default function ImagePanel({ topic }: ImagePanelProps) {
  const { logId } = useParams<paths.LogParams>();

  const { timestampMs, timestep } = usePlayback();

  const firstMessageQuery = useFirstMessage(logId, topic.id);

  const imagesWindow = useWindow({
    logId,
    topicId: topic.id,
    sampleFrequency:
      timestep === Timestep.Second ? SampleFreq.Second : SampleFreq.Decisecond,
    includeImage: true,
    bufferBehindMs: secondsToMilliseconds(6),
    bufferAheadMs: secondsToMilliseconds(45),
    windowSizeMs: secondsToMilliseconds(3),
    chunkSizeMs: secondsToMilliseconds(3),
  });

  if (
    firstMessageQuery.isIdle ||
    firstMessageQuery.isLoading ||
    imagesWindow.query.isLoading ||
    timestampMs === undefined
  ) {
    return <Loading type="circular" />;
  } else if (imagesWindow.query.data) {
    // It's OK if the first message had an error while fetching,
    // we just won't use it and won't present that feature
    const firstMessageMs = firstMessageQuery.data;
    const staleTimeMs = timestep === Timestep.Second ? 2_000 : 200;

    let content;
    if (firstMessageMs != null && timestampMs < firstMessageMs) {
      content = (
        <Center>
          <TimerSand fontSize="large" />
          <Typography variant="h5" component="p">
            No recent image
          </Typography>
          <NavigationLink
            sx={{ mt: 4 }}
            variant="h6"
            to={paths.toLog(logId, "records", { t: firstMessageMs })}
          >
            Jump to first image
          </NavigationLink>
        </Center>
      );
    } else {
      const currentImg = _.findLast(
        imagesWindow.query.data,
        (point) =>
          point.timestampMs <= timestampMs &&
          timestampMs - point.timestampMs <= staleTimeMs
      );

      if (currentImg == null) {
        content = (
          <Center>
            <TimerSand fontSize="large" />
            <Typography variant="h5" component="p">
              No recent image
            </Typography>
          </Center>
        );
      } else {
        content = (
          <img alt="" src={`data:image/webp;base64,${currentImg.image}`} />
        );
      }
    }

    return (
      <Box
        sx={{
          height: 1,
          width: 1,
          overflow: "hidden",
          "& img": {
            height: "inherit",
            width: "inherit",
            objectFit: "contain",
          },
        }}
      >
        {content}
      </Box>
    );
  } else {
    return (
      <Error>
        <Typography variant="h5" component="p" color="error">
          An error occurred. Unable to get images
        </Typography>
      </Error>
    );
  }
}
