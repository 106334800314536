import React from "react";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { TreeView, TreeItem } from "@mui/lab";
import { Box, Typography } from "@mui/material";

const ShowBrackets = ({ src }) => {
  const brackets = Array.isArray(src) ? " [...]" : " {...}";
  return (
    <Typography component="span" variant="body2" color="textSecondary">
      {`${brackets}`}
    </Typography>
  );
};

function listItemType(val) {
  return (
    <Box
      px={0.5}
      fontSize=".75rem"
      fontStyle="oblique"
      color="text.secondary"
      component="span"
    >
      {val}
    </Box>
  );
}

const Items = ({ src, parentName = "", onSelect, parents = [] }) => {
  if (!src) {
    return null;
  }
  const content = Object.keys(src).map((key, idx) => {
    const type = typeof src[key];
    const kval = src[key];
    const isArray = Array.isArray(src[key]);
    const node = parents.concat([key]).join(".");
    const k = node === "" ? `${idx}` : node;
    if (isArray) {
      return (
        <TreeItem
          key={k}
          nodeId={node}
          label={
            <>
              <b>{key}</b> <ShowBrackets src={src[key]} />
            </>
          }
        >
          {src[key].map((val, idx) => {
            return typeof val === "object" ? (
              <Items
                src={val}
                parentName={idx}
                onSelect={onSelect}
                parents={parents.concat([key, idx])}
                key={parents.concat([key, idx]).join(".")}
              />
            ) : (
              <TreeItem
                nodeId={parents.concat([key, idx]).join(".")}
                key={parents.concat([key, idx]).join(".")}
                onClick={() => onSelect(node, type)}
                label={
                  <>
                    <span>{idx}</span>
                    <span>:</span>
                    {listItemType(typeof val)}
                    <span>
                      {val === null
                        ? "null"
                        : type === "string"
                        ? `"${val.toString()}"`
                        : val.toString()}
                    </span>
                  </>
                }
              />
            );
          })}
        </TreeItem>
      );
    } else if (kval !== null && type === "object") {
      return (
        <Items
          src={src[key]}
          parentName={Array.isArray(src) ? "" : key}
          onSelect={onSelect}
          parents={parents.concat([key])}
          key={node}
        />
      );
    } else {
      return (
        <TreeItem
          nodeId={node}
          key={node}
          onClick={() => onSelect(node, type)}
          label={
            <>
              <span>{key}</span>
              <span>:</span>
              {listItemType(type)}
              <span>
                {src[key] === null
                  ? "null"
                  : type === "string"
                  ? `"${src[key].toString()}"`
                  : src[key].toString()}
              </span>
            </>
          }
        />
      );
    }
  });
  return (
    <>
      {parentName !== "" ? (
        <TreeItem
          key={parents.join(".")}
          nodeId={parents.join(".")}
          label={
            <>
              <b>{parentName}</b> <ShowBrackets src={src} />
            </>
          }
        >
          {content}
        </TreeItem>
      ) : (
        content
      )}
    </>
  );
};

const JsonTree = ({ src, handleSelect }) => {
  const itms = <Items src={src} onSelect={handleSelect} key="root" />;
  return (
    <TreeView
      src={src}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
    >
      {itms}
    </TreeView>
  );
};

export default JsonTree;
