import React from "react";
import { BarChart, Error as ErrorIcon } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import _ from "lodash";
import { useParams } from "react-router-dom";
import Center from "../../../components/Center";
import type { Panel } from "../../../panel-layout";
import { EmptyPanelState, useLogTopics } from "../../../queries";
import * as paths from "../../paths";
import { TopicSelect, TopicTree } from "./index";

interface TopicSelectPanelProps {
  panelId: Panel["id"];
  selectedTopicName: EmptyPanelState["name"];
  onSelect: (
    name: EmptyPanelState["name"],
    messageType: EmptyPanelState["messageType"]
  ) => void;
  onConfirm: () => void;
}

export default function TopicSelectPanel({
  panelId,
  selectedTopicName,
  onSelect,
  onConfirm,
}: TopicSelectPanelProps) {
  const { logId } = useParams<paths.LogParams>();

  const topicsQuery = useLogTopics(logId);

  function handleSelect(topic: any) {
    onSelect(topic?.name ?? null, topic?.messageType ?? null);
  }

  if (topicsQuery.data) {
    const selectedTopic =
      _.find(topicsQuery.data, { name: selectedTopicName }) ?? null;

    return (
      <Box
        sx={{
          width: "min(100%, 60ch)",
          p: 2,
          boxSizing: "border-box",
        }}
      >
        <Typography paragraph>
          To view a topic, find it in the <b>Quick search</b> or select it in
          the topic tree below
        </Typography>
        <TopicSelect
          width="100%"
          isLoading={!topicsQuery.isSuccess}
          id={`topic-quick-search-${panelId}`}
          inputLabel="Quick search"
          topics={topicsQuery.data}
          selection={selectedTopic}
          onSelectionChange={handleSelect}
        />
        <Divider sx={{ my: 2 }} />
        <TopicTree topics={topicsQuery.data} onSelect={handleSelect} />
        <Button
          sx={{ mt: 2 }}
          disabled={selectedTopic === null}
          startIcon={<BarChart />}
          variant="outlined"
          onClick={onConfirm}
        >
          Visualize
        </Button>
      </Box>
    );
  } else if (topicsQuery.isError) {
    return (
      <Center>
        <ErrorIcon color="error" fontSize="large" />
        <Typography variant="h5" component="p" color="error">
          Unable to fetch topics
        </Typography>
      </Center>
    );
  } else {
    return (
      <Center>
        <Typography variant="h5" component="p">
          Loading topics...
        </Typography>
      </Center>
    );
  }
}
