import React from "react";
import { DoNotDisturb, Error as ErrorIcon } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { useParams } from "react-router-dom";
import useResizeObserver from "use-resize-observer";
import Center from "../../../components/Center";
import Error from "../../../components/Error";
import {
  useLog,
  useLogTopics,
  VisualizationPanelState,
} from "../../../queries";
import * as paths from "../../paths";
import { ChartPanel, ImagePanel, MapPanel, TimelinePanel } from "./index";

interface VisPanelProps {
  panelState: VisualizationPanelState;
  onChangeFields: (fields: VisualizationPanelState["fields"]) => void;
  onChangeTab: (tab: VisualizationPanelState["tab"]) => void;
}

export default function VisualizationPanel({
  panelState,
  onChangeFields,
  onChangeTab,
}: VisPanelProps) {
  const { logId } = useParams<paths.LogParams>();

  const logQuery = useLog(logId);

  const topicsQuery = useLogTopics(logId);

  const { ref, height, width } = useResizeObserver();

  if (panelState.tab !== undefined && logQuery.data && topicsQuery.data) {
    const topic = _.find(topicsQuery.data, { name: panelState.name });

    if (topic === undefined) {
      return (
        <Error icon={<DoNotDisturb fontSize="large" color="error" />}>
          <Typography variant="h5" component="p">
            <code>{panelState.name}</code> is not in this log
          </Typography>
        </Error>
      );
    } else {
      return (
        <Box
          sx={{
            height: 1,
            width: 1,
            "& .MuiTabPanel-root": {
              height: "inherit",
              width: "inherit",
              p: 0,
            },
          }}
        >
          <Box ref={ref} height={1} width={1}>
            {height !== undefined && width !== undefined ? (
              <>
                {panelState.tab === "timeline" && (
                  <TimelinePanel
                    topic={topic}
                    fields={panelState.fields}
                    onChangeFields={onChangeFields}
                    onGraph={() => onChangeTab("chart")}
                    width={width}
                  />
                )}
                {panelState.tab === "chart" && (
                  <ChartPanel
                    topic={topic}
                    fields={panelState.fields}
                    height={height}
                    width={width}
                  />
                )}
                {panelState.tab === "image" && <ImagePanel topic={topic} />}
                {panelState.tab === "map" && (
                  <MapPanel topic={topic} height={height} width={width} />
                )}
              </>
            ) : null}
          </Box>
        </Box>
      );
    }
  } else if (logQuery.isError || topicsQuery.isError) {
    return (
      <Center>
        <ErrorIcon color="error" fontSize="large" />
        <Typography variant="h5" component="p" color="error">
          Something went wrong. Unable to display topic
        </Typography>
      </Center>
    );
  } else {
    return (
      <Center>
        <Typography variant="h5" component="p">
          Loading topics...
        </Typography>
      </Center>
    );
  }
}
