import React from "react";
import { Box, Typography } from "@mui/material";
import { MapMarkerAlert } from "mdi-material-ui";
import { useParams } from "react-router-dom";
import Error from "../../../components/Error";
import Loading from "../../../components/Loading";
import TrajectoryMapContainer from "../../../components/TrajectoryMapContainer";
import { trajectoryMessagesToPath } from "../../../utils";
import * as paths from "../../paths";
import { usePlayback } from "../contexts";
import useOverview from "../hooks/useOverview";

interface MapPanelProps {
  topic: any;
  height: number;
  width: number;
}

export default function MapPanel({ topic, height, width }: MapPanelProps) {
  const { logId } = useParams<paths.LogParams>();

  const { boundsMs, timestampMs } = usePlayback();

  const mapOverview = useOverview({
    logId,
    topicId: topic.id,
    timeRangeMs: boundsMs,
  });

  if (
    mapOverview.isLoading ||
    timestampMs === undefined ||
    boundsMs === undefined
  ) {
    return <Loading type="circular" />;
  } else if (mapOverview.isError) {
    return (
      <Error>
        <Typography variant="h5" component="p">
          An error occurred. Unable to get map data
        </Typography>
      </Error>
    );
  } else {
    return (
      <Box height={1} width={1}>
        <TrajectoryMapContainer
          trajectoryData={trajectoryMessagesToPath(mapOverview.data!)}
          height={height}
          width={width}
          timestampMs={timestampMs}
          errorElement={
            <Error icon={<MapMarkerAlert fontSize="large" color="error" />}>
              <Typography variant="h5" component="p" align="center">
                Missing message data. Unable to display map
              </Typography>
            </Error>
          }
        />
      </Box>
    );
  }
}
