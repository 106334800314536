import React, { useState } from "react";
import { TabPanel } from "@mui/lab";
import { Card, CardContent, Container, Grid } from "@mui/material";
import { mapValues } from "lodash";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import DividedCardHeader from "../../components/DividedCardHeader";
import {
  LogMetadata,
  Topic,
  useLabels,
  useLog,
  useUpdateLog,
  useUser,
} from "../../queries";
import * as paths from "../paths";
import GpsCard from "./components/GpsCard";
import IngestionList from "./components/IngestionList";
import LabelDetailListCard from "./components/LabelDetailListCard";
import MetadataCard from "./components/MetadataCard";
import SummaryCard from "./components/SummaryCard";
import TopicListCard from "./components/TopicListCard";

export default function Details() {
  // For persisting GPS card selected topic ID between tab changes
  const [selectedTopicId, setSelectedTopicId] = useState<Topic["id"]>();

  // For persisting topic card filter between tab changes
  const [filter, setFilter] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const userQuery = useUser();

  let { logId } = useParams<paths.LogParams>();

  const logQuery = useLog(logId);

  const updateLog = useUpdateLog(logId);

  const labelsQuery = useLabels(logId);

  function onUpdate(newMetadata: Partial<LogMetadata>) {
    // Empty strings shouldn't make their way to the API. For every new metadata
    // value passed up from the form, any that are falsey should be explicitly
    // set to null
    const transformedMetadata = mapValues(newMetadata, (val) => val || null);

    return updateLog.mutateAsync(transformedMetadata, {
      onError() {
        enqueueSnackbar("Unable to update log metadata", {
          variant: "error",
        });
      },
      onSuccess() {
        enqueueSnackbar("Log metadata updated!", {
          variant: "success",
        });
      },
    });
  }

  return (
    <TabPanel value="details">
      <Container sx={{ py: 4 }}>
        <Grid
          sx={{
            "& .cardContainer": {
              display: "flex",
              "& > *": {
                flex: 1,
              },
            },
          }}
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <GpsCard
              selectedTopicId={selectedTopicId}
              setSelectedTopicId={setSelectedTopicId}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid className="cardContainer" item xs={12} lg={6}>
                <SummaryCard
                  log={logQuery.data}
                  isLoading={!logQuery.isSuccess}
                />
              </Grid>
              <Grid className="cardContainer" item xs={12} lg={6}>
                <MetadataCard
                  canUserEdit
                  log={logQuery.data}
                  isLoading={!logQuery.isSuccess}
                  onUpdate={onUpdate}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid className="cardContainer" item xs={12}>
                <LabelDetailListCard
                  username={userQuery.data?.username ?? ""}
                  isLoading={!labelsQuery.isSuccess}
                  disabled={logQuery.data?.status !== "processed"}
                  disabledReason={
                    "Labels will be available when the log is ingested and has finished processing."
                  }
                  logId={logId}
                  labels={labelsQuery.data}
                />
              </Grid>
              <Grid className="cardContainer" item xs={12}>
                <TopicListCard filter={filter} setFilter={setFilter} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={4}>
              <DividedCardHeader
                height="2rem"
                title="Ingestions"
                titleTypographyProps={{
                  variant: "h6",
                  component: "h2",
                }}
              />
              <CardContent>
                <IngestionList />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </TabPanel>
  );
}
