import { Log, Topic } from "../../../queries";
import useChunks from "./useChunks";

interface UseOverviewOptions {
  logId: Log["id"];
  topicId?: Topic["id"] | null;
  enabled?: boolean;
  timeRangeMs?: [number, number];
}

export default function useOverview({
  logId,
  topicId,
  enabled = topicId != null,
  timeRangeMs,
}: UseOverviewOptions) {
  // By limiting the chunk size to 900 seconds with a sampling
  // frequency of 1 Hz, we're effectively applying a limit of
  // 900 messages per chunk
  return useChunks({
    logId,
    topicId,
    enabled,
    chunkSizeMs: 1_000 * 900,
    timeRangeMs,
  });
}
