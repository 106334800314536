import React from "react";
import { QueryBuilder } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { filter, find, forEach, head } from "lodash";
import { EarthOff, MapMarkerAlert } from "mdi-material-ui";
import { useParams } from "react-router-dom";
import useResizeObserver from "use-resize-observer";
import Center from "../../../components/Center";
import DividedCardHeader from "../../../components/DividedCardHeader";
import TrajectoryMapContainer from "../../../components/TrajectoryMapContainer";
import { useLog, useLogTopics } from "../../../queries";
import { trajectoryMessagesToPath } from "../../../utils";
import useOverview from "../hooks/useOverview";

const priorityTopicPatterns = [
  /piksi\/pos_llh_cov.+$/i,
  /piksi\/pos_llh_cov$/i,
];

export default function GpsCard({ selectedTopicId, setSelectedTopicId }) {
  const { logId } = useParams();

  const logQuery = useLog(logId);

  const topicsQuery = useLogTopics(logId);

  const trajectoryTopics = filter(topicsQuery.data ?? [], {
    messageType: "sensor_msgs/NavSatFix",
  });

  let computedTopicId = selectedTopicId;
  if (topicsQuery.isSuccess && selectedTopicId === undefined) {
    // Auto-load selected topic ID until the user selects one themselves.
    // Prefer topics earlier in the list of priority topic name patterns.
    forEach(priorityTopicPatterns, (pattern) => {
      computedTopicId = find(trajectoryTopics, (topic) =>
        pattern.test(topic.name)
      )?.id;

      // If we found one, bail out of the loop so the ID isn't overwritten
      // by a lower-priority topic
      return computedTopicId === undefined;
    });

    if (computedTopicId === undefined) {
      // If no topic matched the priority list, just use the first
      // topic if there is one
      computedTopicId = head(trajectoryTopics)?.id;
    }
  }

  const mapOverview = useOverview({
    logId,
    topicId: computedTopicId,
    timeRangeMs: logQuery.data?.boundsMs ?? undefined,
  });

  const { ref, height, width } = useResizeObserver();

  function putCenterMessage(icon, message) {
    return (
      <Center>
        {icon}
        <Typography variant="h6" component="p" align="center">
          {message}
        </Typography>
      </Center>
    );
  }

  function putInGrid(map, controls) {
    return (
      <Grid sx={{ height: 1 }} container spacing={2}>
        <Grid item xs={9}>
          {map}
        </Grid>
        <Grid sx={{ height: 1 }} item xs="auto">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs zeroMinWidth>
          {controls}
        </Grid>
      </Grid>
    );
  }

  let content;
  if (logQuery.isLoading) {
    content = putInGrid(
      <Skeleton variant="rectangular" height="100%" />,
      <Skeleton variant="rectangular" height={56} />
    );
  } else if (logQuery.data?.status !== "processed") {
    content = putCenterMessage(
      <QueryBuilder fontSize="large" />,
      "Map unavailable until log is processed"
    );
  } else if (!topicsQuery.isLoading && trajectoryTopics.length === 0) {
    content = putCenterMessage(
      <EarthOff fontSize="large" />,
      "No GPS topics in this log"
    );
  } else {
    let map;
    if (computedTopicId == null || !mapOverview.isSuccess) {
      map = <Skeleton variant="rectangular" height="100%" />;
    } else {
      map = (
        <Box ref={ref} height={1} width={1}>
          {height !== undefined && width !== undefined ? (
            <TrajectoryMapContainer
              trajectoryData={trajectoryMessagesToPath(mapOverview.data)}
              height={height}
              width={width}
              errorElement={
                <Center>
                  <MapMarkerAlert fontSize="large" color="error" />
                  <Typography variant="h6" component="p" align="center">
                    Missing message data. Unable to display map
                  </Typography>
                </Center>
              }
            />
          ) : null}
        </Box>
      );
    }

    let controls;
    if (topicsQuery.isLoading) {
      controls = <Skeleton variant="rectangular" height={56} />;
    } else {
      controls = (
        <TextField
          id="log-trajectory-topic-select"
          select
          fullWidth
          label="GPS topic"
          value={computedTopicId ?? ""}
          onChange={(e) => setSelectedTopicId(e.target.value)}
          variant="outlined"
        >
          {trajectoryTopics.map((topic) => (
            <MenuItem key={topic.id} value={topic.id}>
              {topic.name}
            </MenuItem>
          ))}
        </TextField>
      );
    }

    content = putInGrid(map, controls);
  }

  return (
    <Card elevation={4}>
      <DividedCardHeader
        height="2rem"
        title="GPS Map"
        titleTypographyProps={{
          variant: "h6",
          component: "h2",
        }}
      />
      <CardContent sx={{ height: 500 }}>{content}</CardContent>
    </Card>
  );
}
