import React, { ReactNode, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  Typography,
} from "@mui/material";
import invariant from "invariant";
import _ from "lodash";
import prettyBytes from "pretty-bytes";
import { Link as RouterLink, useParams } from "react-router-dom";
import DividedCardHeader from "../../components/DividedCardHeader";
import { useDraftExtraction } from "../../providers/DraftExtractionProvider";
import { SampleRange, useLog } from "../../queries";
import { formatTimeRange, pluralize } from "../../utils";
import * as paths from "../paths";

export default function ExtractionListCard() {
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);

  const { logId, extractionId } = useParams<paths.ExtractionDetailsParams>();

  const logQuery = useLog(logId);

  const { isInitialized, estimator, extractionTopics } = useDraftExtraction();

  function makeListColumns(topicList: ReactNode, rangeList: ReactNode) {
    return (
      <>
        <Grid item xs zeroMinWidth>
          {topicList}
        </Grid>
        <Grid item xs="auto">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs zeroMinWidth>
          {rangeList}
        </Grid>
      </>
    );
  }

  let content;
  if (!isInitialized || !logQuery.isSuccess) {
    content = (
      <Grid container spacing={2}>
        {makeListColumns(
          <List disablePadding>
            {[1, 2, 3].map((val) => (
              <ListItem key={val}>
                <ListItemText secondary={<Skeleton width="10ch" />}>
                  <Skeleton width="25ch" />
                </ListItemText>
              </ListItem>
            ))}
          </List>,
          <List disablePadding>
            {[1, 2, 3].map((val) => (
              <ListItem key={val}>
                <ListItemText secondary={<Skeleton width="30ch" />}>
                  <Skeleton width="15ch" />
                </ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </Grid>
    );
  } else if (extractionTopics.length === 0) {
    content = (
      <div>
        <Typography variant="h6" component="p" align="center" paragraph>
          No Topics
        </Typography>
        <Typography align="center" paragraph>
          No topics are marked for extraction yet.
        </Typography>
      </div>
    );
  } else {
    let totalEstimationMessage;
    if (estimator.isError) {
      totalEstimationMessage = "Unable to get estimation";
    } else if (!estimator.isSuccess) {
      totalEstimationMessage = "Fetching estimation...";
    } else if (estimator.data.isEmpty) {
      totalEstimationMessage = "Estimation unknown";
    } else {
      totalEstimationMessage = (
        <>
          Estimated size:{" "}
          {prettyBytes(estimator.data.totalSize!, {
            binary: true,
            maximumFractionDigits: 2,
          })}
        </>
      );
    }

    const topicList = (
      <List
        sx={{
          maxHeight: 350,
          overflowY: "auto",
        }}
        disablePadding
        subheader={
          <ListSubheader>
            {pluralize(extractionTopics.length, "topic")} &bull;{" "}
            {totalEstimationMessage}
          </ListSubheader>
        }
      >
        {_.sortBy(extractionTopics, "topic.name").map(({ topic, ranges }) => {
          const numRanges = ranges.length;

          let estimationMessage;
          if (estimator.isError) {
            estimationMessage = "Unable to get estimation";
          } else if (!estimator.isSuccess) {
            estimationMessage = "Fetching estimation...";
          } else if (estimator.data.isTopicEmpty(topic.id)) {
            estimationMessage = "Estimation unknown";
          } else {
            estimationMessage = prettyBytes(
              estimator.data.topicSize(topic.id)!,
              {
                binary: true,
                maximumFractionDigits: 2,
              }
            );
          }

          return (
            <ListItem
              key={topic.id}
              button
              selected={selectedTopic === topic.id}
              onClick={() => setSelectedTopic(topic.id)}
            >
              <ListItemText
                primaryTypographyProps={{
                  noWrap: true,
                }}
                secondary={
                  <>
                    {pluralize(numRanges, "range")} &bull; {estimationMessage}
                  </>
                }
              >
                {topic.name}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    );

    let rangeList;
    if (selectedTopic != null) {
      const ranges: readonly SampleRange[] | undefined = _.find(
        extractionTopics,
        ["topic.id", selectedTopic]
      )?.ranges;

      invariant(
        ranges !== undefined,
        "Couldn't find ranges for selected topic. This shouldn't happen"
      );

      const sortedRanges = _.sortBy(ranges, (range) => range.startTimeMs ?? 0);

      rangeList = (
        <List
          sx={{
            maxHeight: 350,
            overflowY: "auto",
          }}
          disablePadding
        >
          {sortedRanges.map((range) => (
            <ListItem key={range.startTimeMs}>
              <ListItemText>
                {formatTimeRange(
                  range.startTimeMs,
                  range.endTimeMs,
                  logQuery.data.startDate
                )}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      );
    } else {
      rangeList = <Typography>Nothing selected</Typography>;
    }

    content = (
      <Grid container spacing={2}>
        {makeListColumns(topicList, rangeList)}
      </Grid>
    );
  }

  return (
    <Card elevation={4}>
      <DividedCardHeader
        height="2rem"
        title="Topics"
        titleTypographyProps={{
          variant: "h6",
          component: "h2",
        }}
        action={
          <Button
            component={RouterLink}
            disabled={!isInitialized || extractionTopics.length === 0}
            color="primary"
            variant="contained"
            to={paths.toLog(logId, "records", { templateId: extractionId })}
          >
            Use as Template
          </Button>
        }
      />
      <CardContent>{content}</CardContent>
    </Card>
  );
}
