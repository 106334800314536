import React, { useRef, useState } from "react";
import { Dashboard } from "@mui/icons-material";
import { TabPanel } from "@mui/lab";
import {
  Box,
  createTheme,
  IconButton,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { bindTrigger } from "material-ui-popup-state/hooks";
import { useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { usePanelLayout } from "../../panel-layout";
import DraftExtractionProvider from "../../providers/DraftExtractionProvider";
import {
  PanelState,
  Tag,
  Topic,
  useLog,
  useLogTopics,
  useUser,
} from "../../queries";
import * as paths from "../paths";
import {
  PlaybackController,
  ProfileManager,
  TimestampUpdater,
  TopicSection,
} from "./components";
import TaskDrawer from "./components/TaskDrawer";
import { PlaybackProvider } from "./contexts";
import useFullscreen from "./hooks/useFullscreen";

export type TfStaticState =
  | "unavailable"
  | "added-automatically"
  | "added-manually"
  | "removed-automatically"
  | "removed-manually";

export interface ExtractionFormState {
  extractionRange: [number, number] | undefined;
  tfStaticState: TfStaticState | undefined;
  selectedTopics: Topic[];
}

export interface ExtractionCheckoutFormValues {
  name: string;
}

export type LabelRange = [number, number] | [number, null];

export interface LabelFormState {
  labelRange: LabelRange | undefined;
  selectedTag: Tag | null;
  content: string | null;
}

// If menus and tooltips are contained in the <body> (default behavior), they
// won't be visible when the app is in fullscreen mode, so if there is a
// fullscreen element, it should be the container
function container() {
  return document.fullscreenElement ?? document.body;
}

const fullscreenTheme = createTheme({
  components: {
    MuiMenu: {
      defaultProps: {
        container,
      },
    },
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          container,
        },
      },
    },
  },
});

export default function Records() {
  const fullScreenRef = useRef<HTMLElement>();
  const { isFullscreen } = useFullscreen();

  const { logId } = useParams<paths.LogParams>();
  const [templateId] = useQueryParam("templateId", StringParam);

  const logQuery = useLog(logId);
  const topicsQuery = useLogTopics(logId);
  const userQuery = useUser();

  const [hasAutoloaded, setHasAutoloaded] = useState(false);
  const layoutState = usePanelLayout<PanelState>(
    {
      type: "empty",
      name: null,
      messageType: null,
    },
    true
  );

  return (
    <PlaybackProvider
      boundsMs={
        Array.isArray(logQuery.data?.boundsMs)
          ? logQuery.data!.boundsMs
          : undefined
      }
    >
      <DraftExtractionProvider logId={logId} templateId={templateId}>
        <TaskDrawerStateManager>
          <TabPanel value="records">
            <TimestampUpdater />
            <Box
              ref={fullScreenRef}
              sx={{
                height: 1,
                width: 1,
                display: "flex",
                flexDirection: "column",
                "&::backdrop": {
                  bgcolor: "background.paper",
                },
              }}
            >
              <ThemeProvider theme={fullscreenTheme}>
                <h1 style={visuallyHidden}>Records for log {logId}</h1>
                <TopicSection layoutState={layoutState} />
                <PlaybackController
                  fullscreenRef={fullScreenRef}
                  profileManager={
                    <ProfileManager
                      popupId="layout-profiles-menu"
                      createDescription={`Save your current layout for quick viewing later. 
            The profile will remember your panels, their size, the selected 
            tab, and any fields you were charting.`}
                      favoriteDescription={
                        "It will be automatically loaded whenever you view the records page."
                      }
                      profileType="layout"
                      onSelect={(layout, autoloaded) => {
                        if (autoloaded && hasAutoloaded) {
                          // Don't autoload again
                          return;
                        }

                        if (autoloaded && !hasAutoloaded) {
                          setHasAutoloaded(true);
                        }

                        layoutState.setLayout(layout);
                      }}
                      disabled={isFullscreen}
                      onCreate={() => layoutState.layout}
                    >
                      {(profiles, popupState) => (
                        <Tooltip title={`Layout profiles (${profiles.length})`}>
                          <span>
                            <IconButton
                              aria-label="Open layout profiles menu"
                              disabled={
                                !(
                                  logQuery.isSuccess &&
                                  userQuery.isSuccess &&
                                  topicsQuery.isSuccess
                                )
                              }
                              {...bindTrigger(popupState)}
                              size="large"
                            >
                              <Dashboard />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </ProfileManager>
                  }
                />
              </ThemeProvider>
            </Box>
          </TabPanel>
        </TaskDrawerStateManager>
      </DraftExtractionProvider>
    </PlaybackProvider>
  );
}

type TaskDrawerStateManagerProps = React.PropsWithChildren<{}>;

// A simple state manager being used to persist the task drawer state
// between tabs while keeping the expensive TopicSection component from
// re-rendering each time if the state were kept in the Records component.
// See reasoning here: https://overreacted.io/before-you-memo/#solution-2-lift-content-up
function TaskDrawerStateManager({ children }: TaskDrawerStateManagerProps) {
  // For persisting extraction form state between tabs
  const [extractionFormState, setExtractionFormState] =
    useState<ExtractionFormState>({
      extractionRange: undefined,
      tfStaticState: undefined,
      selectedTopics: [],
    });

  // For persisting checkout Formik state between tabs. Since Formik is
  // uncontrolled, we only store the values in a ref and use them for setting
  // the initial state each time the form mounts. As the form is updated though
  // this ref should also be updated with the current form values
  const extractionCheckoutFormValuesRef = useRef<ExtractionCheckoutFormValues>({
    name: "",
  });

  // For persisting label form state between tabs
  const [labelFormState, setLabelFormState] = useState<LabelFormState>({
    labelRange: undefined,
    selectedTag: null,
    content: null,
  });

  return (
    <>
      {children}
      <TaskDrawer
        extractionFormState={extractionFormState}
        setExtractionFormState={setExtractionFormState}
        extractionCheckoutFormValuesRef={extractionCheckoutFormValuesRef}
        labelFormState={labelFormState}
        setLabelFormState={setLabelFormState}
      />
    </>
  );
}
