import React from "react";
import { Chip } from "@mui/material";

export default function Tag({ type = null, name = null, ChipProps = {} }) {
  let label;
  if (type != null && name != null) {
    label = `${type}:${name}`;
  } else if (type != null) {
    label = type;
  } else {
    label = name;
  }

  return <Chip label={label} color="primary" {...ChipProps} />;
}
