import { Chip, ChipProps } from "@mui/material";
import { startCase } from "lodash";

export interface StatusChipProps {
  status: string;
}

export default function StatusChip({ status }: StatusChipProps) {
  const chipProps: ChipProps = {
    sx: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    label: startCase(status),
    color:
      status === "processed"
        ? "success"
        : status === "error"
        ? "error"
        : undefined,
  };

  return <Chip {...chipProps} />;
}
