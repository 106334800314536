import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useQueryClient } from "react-query";
import { Redirect, Route } from "react-router-dom";
import config from "../../config";
import { useApi } from "../../providers/ApiProvider";
import { useAuth } from "../../providers/AuthProvider";
import { groupKeys, tagKeys, userKeys, useSignIn } from "../../queries";
import * as paths from "../../views/paths";
import SignInForm from "./SignInForm";

export default function AuthenticatedRoute({ children, ...routeProps }) {
  const { authState } = useAuth();
  const signInMutation = useSignIn();

  const externalAuth = config.externalAuth;

  if (authState === "expired") {
    return (
      <>
        <Route {...routeProps}>{children}</Route>
        <Dialog open fullWidth aria-labelledby="sign-in-dialog-title">
          <DialogTitle id="sign-in-dialog-title">Session Expired</DialogTitle>
          <DialogContent>
            {externalAuth === null ? (
              <>
                <DialogContentText>
                  Your session has expired. You need to sign in again to
                  continue using the DataStore.
                </DialogContentText>
                <SignInForm
                  titleComponent={false}
                  dialogPresentation
                  showSignUp={false}
                  signIn={signInMutation}
                />
              </>
            ) : (
              <>
                <DialogContentText>
                  Your session has expired. You need to sign in again using your
                  external service. Select <b>Visit External Sign In</b> below
                  to do this. You will be redirected to the home page once
                  finished.
                </DialogContentText>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  href={externalAuth.signInUrl}
                >
                  Visit External Sign In
                </Button>
              </>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  } else if (authState === "authenticated") {
    return (
      <Route {...routeProps}>
        <Prefetch />
        {children}
      </Route>
    );
  } else {
    return <Redirect to={paths.signIn} />;
  }
}

function Prefetch() {
  const { authenticatedClient } = useApi();

  const queryClient = useQueryClient();

  useEffect(
    function prefetchCommonQueries() {
      queryClient.prefetchQuery(
        groupKeys.list(),
        authenticatedClient.listGroups
      );

      queryClient.prefetchQuery(userKeys.detail(), authenticatedClient.getUser);

      queryClient.prefetchQuery(tagKeys.all, authenticatedClient.listTags);
    },
    [queryClient, authenticatedClient]
  );

  return null;
}
