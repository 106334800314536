import React from "react";

export interface BreakableProps {
  text?: string | null;
  sepRegex: RegExp;
}

export default function Breakable({ text, sepRegex }: BreakableProps) {
  if (text == null) {
    return null;
  }

  return (
    <>
      {text.split(sepRegex).map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {sepRegex.test(part) && <wbr />}
        </React.Fragment>
      ))}
    </>
  );
}
