import { slice, sortedIndexBy, sortedLastIndexBy } from "lodash";
import { Log, Record, SampleFreq, Topic } from "../../../queries";
import { usePlayback } from "../contexts";
import useChunks from "./useChunks";

interface UseWindowOptions {
  logId: Log["id"];
  topicId?: Topic["id"] | null;
  enabled?: boolean;
  windowSizeMs: number;
  sampleFrequency?: SampleFreq.Second | SampleFreq.Decisecond;
  includeImage?: boolean;
  bufferBehindMs?: number;
  bufferAheadMs?: number;
  chunkSizeMs: number;
}

export default function useWindow({
  logId,
  topicId,
  enabled = true,
  windowSizeMs,
  sampleFrequency,
  includeImage = false,
  bufferBehindMs,
  bufferAheadMs,
  chunkSizeMs,
}: UseWindowOptions) {
  const playback = usePlayback();

  let windowBoundsMs: [number, number] | undefined = undefined;
  if (!playback.isLoading) {
    windowBoundsMs = calculateWindow(
      windowSizeMs,
      playback.timestampMs,
      playback.boundsMs
    );
  }

  const reducedQuery = useChunks({
    logId,
    topicId,
    enabled: enabled && windowBoundsMs !== undefined,
    timeRangeMs: windowBoundsMs,
    sampleFrequency,
    includeImage,
    bufferBehindMs,
    bufferAheadMs,
    chunkSizeMs,
  });

  if (reducedQuery.isSuccess && windowBoundsMs !== undefined) {
    reducedQuery.data = getWindowRecords(reducedQuery.data!, windowBoundsMs);
  }

  return {
    windowBoundsMs,
    query: reducedQuery,
  };
}

/**
 * Calculates the bounds of a window of at most `windowSizeMs` milliseconds,
 * clamped within the bounds described in `boundsMs`, and centered around
 * `timestampMs` to the degree possible.
 *
 * If the window size is greater than the duration specified by the bounds,
 * the returned window will match the bounds.
 *
 * If the timestamp is within `windowSizeMs / 2` milliseconds of either bound,
 * the window will extend away from the timestamp towards the opposite bound
 * in proportion to how close the timestamp is to that bound. This ensures
 * the window is always as wide as the provided size (assuming the window
 * size is not larger than the total duration specified by the bounds) while
 * remaining clamped within the bounds.
 *
 * @param windowSizeMs the desired window size
 * @param timestampMs the timestamp around which the window should attempt to
 * be centered
 * @param boundsMs the lower and higher bound within which the window is
 * guaranteed to be clamped
 */
function calculateWindow(
  windowSizeMs: number,
  timestampMs: number,
  boundsMs: [number, number]
): [number, number] {
  // Lower edge if the window is symmetric around the timestamp
  const symmetricUnclampedLowerWindowEdgeMs = timestampMs - windowSizeMs / 2;
  // Maximum value the lower edge can take to ensure window stays within
  // bounds while still respecting provided window size
  const maximumUnclampedLowerWindowEdgeMs = boundsMs[1] - windowSizeMs;

  const windowStartMs = Math.max(
    boundsMs[0],
    Math.min(
      maximumUnclampedLowerWindowEdgeMs,
      symmetricUnclampedLowerWindowEdgeMs
    )
  );

  const windowEndMs = Math.min(boundsMs[1], windowStartMs + windowSizeMs);

  return [windowStartMs, windowEndMs];
}

function getWindowRecords(records: Record[], windowBoundsMs: [number, number]) {
  const firstIndex = sortedIndexBy(
    records,
    { timestampMs: windowBoundsMs[0] } as Record,
    "timestampMs"
  );

  const lastIndex = sortedLastIndexBy(
    records,
    { timestampMs: windowBoundsMs[1] } as Record,
    "timestampMs"
  );

  return slice(records, firstIndex, lastIndex);
}
