import React, { useEffect, useState } from "react";
import { FileCopy, HelpOutline } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactEmbedGist from "react-embed-gist";

export default function MergeDialog({ extractionId }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCopyTTOpen, setIsCopyTTOpen] = useState(false);

  useEffect(() => {
    if (isCopyTTOpen) {
      const timeoutId = setTimeout(() => setIsCopyTTOpen(false), 3_000);

      return () => clearTimeout(timeoutId);
    }
  }, [isCopyTTOpen]);

  const command = `python merge.py -i ${extractionId || "<extractionId>"}`;

  const handleLinkClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Dialog
        sx={{
          "& .gistTitle": {
            display: "none",
          },
        }}
        onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        open={isDialogOpen}
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
          Merge Multiple Extractions
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            To ensure a scalable, efficient extraction process, extractions are
            broken up into variable sized chunks. Each chunk is monotonic over
            time, so that every message in the first chunk comes before every
            message in the second chunk, and so on.
          </Typography>
          <Typography gutterBottom>
            If a single ROS bag is required, download the extraction chunks and
            run the following python script in the directory they're located in
            using the appropriate prefix input argument, i.e.,
          </Typography>
          <p>
            <CopyToClipboard
              text={command}
              onCopy={() => setIsCopyTTOpen(true)}
            >
              <Tooltip
                title="Copied!"
                placement="top"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                open={isCopyTTOpen}
              >
                <IconButton size="large">
                  <FileCopy fontSize="small" />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
            <code>{command}</code>
          </p>
          <Typography gutterBottom>
            An optional output parameter (-o) can be used to set the merged bag
            name. The only dependency is <code>rosbag</code>, which can be
            installed following&nbsp;
            <a href="https://answers.ros.org/question/343260/install-of-the-rospyrosbag-python-libraries-standalone-not-on-ubuntu/">
              these instructions
            </a>
            &nbsp;if not already present.
          </Typography>
          <Link
            href="https://gist.githubusercontent.com/nathanmargaglio/8bd51718648804d4958f01a9e27198a4/raw/912841e3eef82735e45949ac0bdc09b99effcfb3/merge.py"
            download
            target="_blank"
          >
            Download (Save As...)
          </Link>
          <br />
          &nbsp;
          <ReactEmbedGist
            gist="nathanmargaglio/8bd51718648804d4958f01a9e27198a4"
            titleClass="gistTitle"
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Link href="#" onClick={handleLinkClick}>
        <Typography
          sx={{
            mt: 1.5,
            display: "flex",
            alignItems: "center",
            textDecoration: "underline",
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
          }}
          variant="body2"
          color="textSecondary"
        >
          <HelpOutline fontSize="inherit" />
          <span>Merge multiple extractions.</span>
        </Typography>
      </Link>
    </>
  );
}
