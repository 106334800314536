import React from "react";
import invariant from "invariant";

// Taken from https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/#extended-example
export function createSafeContext<T>(contextName: string) {
  const context = React.createContext<T | undefined>(undefined);

  function useSafeContext() {
    const contextVal = React.useContext(context);

    invariant(
      contextVal !== undefined,
      `${contextName} context used without parent provider`
    );

    return contextVal;
  }

  return [useSafeContext, context] as const;
}
