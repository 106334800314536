import { useCallback } from "react";
import { secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import { every, filter, find, sumBy } from "lodash";
import {
  Extraction as ApiExtraction,
  ExtractionFile as ApiExtractionFile,
  ExtractionList,
  ExtractionTopic as ApiExtractionTopic,
  ExtractionTopicEstimation,
} from "racer-openapi-ts-sdk";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { DeepReadonly } from "ts-essentials";
import { useApi } from "../providers/ApiProvider";
import { useLog } from "./logs";
import { useLogTopics } from "./topics";
import {
  Extraction,
  ExtractionFile,
  ExtractionSearchOptions,
  ExtractionTopic,
  ExtractionUpdates,
  FromApi,
  Log,
  NewExtraction,
  SearchResult,
  Topic,
} from "./types";

// Query key factories

export const extractionKeys = {
  all: ["extractions"] as const,
  lists: () => [...extractionKeys.all, "list"] as const,
  list: (filters: ExtractionSearchOptions) =>
    [...extractionKeys.lists(), filters] as const,
  details: () => [...extractionKeys.all, "details"] as const,
  detail: (logId: Log["id"], extractionId: Extraction["id"]) =>
    [...extractionKeys.details(), { logId, extractionId }] as const,
};

export const extractionTopicKeys = {
  all: ["extractionTopics"] as const,
  lists: () => [...extractionTopicKeys.all, "list"] as const,
  list: (logId: Log["id"], extractionId: Extraction["id"] | null) =>
    [...extractionTopicKeys.lists(), { logId, extractionId }] as const,
};

export const extractionFileKeys = {
  all: ["extractionFiles"] as const,
  lists: () => [...extractionFileKeys.all, "list"] as const,
  list: (logId: Log["id"], extractionId: Extraction["id"]) =>
    [...extractionFileKeys.lists(), { logId, extractionId }] as const,
};

// Queries

export function useExtraction(
  logId: Log["id"],
  extractionId: Extraction["id"],
  opts?: Pick<UseQueryOptions<unknown, Response, Extraction>, "onError">
) {
  const { authenticatedClient } = useApi();

  const queryClient = useQueryClient();

  return useQuery(
    extractionKeys.detail(logId, extractionId),
    () => authenticatedClient.getExtraction(logId, extractionId),
    {
      select: extractionFromApi,
      initialData() {
        const listQueries = queryClient.getQueriesData<ExtractionList>(
          extractionKeys.lists()
        );

        for (const [, query] of listQueries) {
          for (const extraction of query.data) {
            if (extraction.id === extractionId) {
              return { data: extraction };
            }
          }
        }

        return undefined;
      },
      refetchInterval(extraction) {
        // The extraction should refetch every 5 seconds unless it's in the
        // "processed" or "error" state because those are stopping states
        return ["processed", "error"].includes(extraction?.status ?? "")
          ? false
          : 5_000;
      },
      ...(Boolean(opts) && opts),
    }
  );
}

export function useExtractions(
  opts: ExtractionSearchOptions,
  queryOpts?: Pick<
    UseQueryOptions<ExtractionList, unknown, SearchResult<Extraction[]>>,
    "keepPreviousData" | "staleTime" | "cacheTime" | "enabled" | "onSuccess"
  >
) {
  const { authenticatedClient } = useApi();

  return useQuery(
    extractionKeys.list(opts),
    () => authenticatedClient.listExtractions(opts),
    {
      select: extractionsFromApi,
      ...queryOpts,
    }
  );
}

export function useExtractionTopics(
  logId: Log["id"],
  extractionId: Extraction["id"] | null,
  callbacks?: Pick<
    UseQueryOptions<unknown, Response, ExtractionTopic[]>,
    "onSuccess"
  >
) {
  const { authenticatedClient } = useApi();

  const logQuery = useLog(logId);
  const topicsQuery = useLogTopics(logId);

  const select = useCallback(
    (resp: FromApi<ApiExtractionTopic[]>) => {
      invariant(
        logQuery.data?.startDate instanceof Date &&
          logQuery.data?.endDate instanceof Date,
        "select being called without valid log start and end dates"
      );

      invariant(
        topicsQuery.data !== undefined,
        "select being called without successful topic query"
      );

      return extractionTopicsFromApi(
        logQuery.data.startDate,
        logQuery.data.endDate,
        topicsQuery.data,
        resp
      );
    },
    [logQuery.data, topicsQuery.data]
  );

  return useQuery(
    extractionTopicKeys.list(logId, extractionId),
    () => {
      invariant(
        // TODO: Maybe make this check stricter in the future. Currently
        //  not sure if any callers are passing in undefined as opposed to null
        extractionId != null,
        "Extraction ID cannot be null"
      );

      return authenticatedClient.listExtractionTopics(logId, extractionId);
    },
    {
      enabled:
        extractionId != null && logQuery.isSuccess && topicsQuery.isSuccess,
      select,
      ...(Boolean(callbacks) && callbacks),
    }
  );
}

export function useExtractionFiles(
  logId: Log["id"],
  extractionId: Extraction["id"]
) {
  const { authenticatedClient } = useApi();

  const extractionQuery = useExtraction(logId, extractionId);

  return useQuery(
    extractionFileKeys.list(logId, extractionId),
    () => authenticatedClient.listExtractionFiles(logId, extractionId),
    {
      select: extractionFilesFromApi,
      // If the extraction is not loaded OR it's not in a "happy" state
      // disable this dependent query
      enabled: ["processing", "postprocessing", "processed"].includes(
        extractionQuery.data?.status ?? ""
      ),
      refetchInterval(files) {
        // Refetching should stop if the extraction is in a finished state
        // AND all the files are in a finished state. Both of these checks
        // need to happen: we don't want a situation where all the
        // *available* files are finished but the extraction isn't done yet
        // and so more files can be expected. I'm not sure if that could happen
        // but I'd like to defend against it, so a check against the
        // extraction's status is needed to determine if *all* the files for
        // the extraction are finished.
        const isExtractionDone = ["processed", "error"].includes(
          extractionQuery.data?.status ?? ""
        );
        const areFilesDone = (files ?? []).every((file) =>
          ["uploaded", "error"].includes(file.status)
        );

        return isExtractionDone && areFilesDone ? false : 5_000;
      },
    }
  );
}

// Mutations

export function useEstimateExtraction(logId: Log["id"]) {
  const { authenticatedClient } = useApi();

  return useMutation(async (topics: ExtractionTopic[]) => {
    const resp = await authenticatedClient.estimateExtraction(logId, topics);

    return new ExtractionEstimation(resp.data);
  });
}

export function useCreateExtraction(logId: Log["id"]) {
  const { authenticatedClient } = useApi();

  return useMutation(async (newExtraction: DeepReadonly<NewExtraction>) => {
    const resp = await authenticatedClient.createExtraction(
      logId,
      newExtraction
    );

    return extractionFromApi(resp);
  });
}

export function useUpdateExtraction(
  logId: Log["id"],
  extractionId: Extraction["id"]
) {
  const { authenticatedClient } = useApi();

  const queryClient = useQueryClient();

  return useMutation(
    (updates: ExtractionUpdates) =>
      authenticatedClient.updateExtraction(logId, extractionId, updates),
    {
      onSuccess(data) {
        queryClient.setQueryData(
          extractionKeys.detail(logId, extractionId),
          data
        );
      },
    }
  );
}

// Selectors

// This class simplifies the repetitive operations for working with
// extraction estimations but was primarily made so I could get familiar
// with JS classes, so while being useful it's also a bit of a toy
class ExtractionEstimation {
  topicEstimations: ExtractionTopicEstimation[];

  constructor(topicEstimations: ExtractionTopicEstimation[]) {
    this.topicEstimations = topicEstimations;
  }

  private areEstimationsEmpty(estimations: ExtractionTopicEstimation[]) {
    return every(estimations, { data_length: null });
  }

  private sumEstimations(estimations: ExtractionTopicEstimation[]) {
    return sumBy(estimations, "data_length");
  }

  private estimationsForTopic(topicId: Topic["id"]) {
    return filter(this.topicEstimations, { topic_id: topicId });
  }

  get isEmpty() {
    return this.areEstimationsEmpty(this.topicEstimations);
  }

  isTopicEmpty(topicId: Topic["id"]) {
    return this.areEstimationsEmpty(this.estimationsForTopic(topicId));
  }

  get totalSize() {
    if (this.isEmpty) {
      return null;
    } else {
      return this.sumEstimations(this.topicEstimations);
    }
  }

  topicSize(topicId: Topic["id"]) {
    if (this.isTopicEmpty(topicId)) {
      return null;
    } else {
      return this.sumEstimations(this.estimationsForTopic(topicId));
    }
  }
}

function extractionFilesFromApi({
  data,
}: FromApi<ApiExtractionFile[]>): ExtractionFile[] {
  // This function is pretty useless right now. In the future it might
  // become less useless
  return data.map((apiFile) => ({
    id: apiFile.id,
    extraction_id: apiFile.extraction_id,
    url: apiFile.url,
    size: apiFile.size,
    status: apiFile.status,
  }));
}

function extractionTopicsFromApi(
  logStartDate: Date,
  logEndDate: Date,
  topics: Topic[],
  { data }: FromApi<ApiExtractionTopic[]>
): ExtractionTopic[] {
  const extractionTopics = new Map<Topic["id"], ExtractionTopic>();

  data.forEach((extractionTopic) => {
    if (!extractionTopics.has(extractionTopic.topic_id)) {
      const topic = find(topics, { id: extractionTopic.topic_id });

      invariant(
        topic !== undefined,
        `No topic with ID ${extractionTopic.topic_id} found in log`
      );

      extractionTopics.set(topic.id, {
        topic,
        ranges: [],
      });
    }

    // Non-null assertion is safe here because the previous step ensures
    // the topic was either inserted into the map or an error was thrown
    // in which case we'd never get to this point
    extractionTopics.get(extractionTopic.topic_id)!.ranges.push({
      startTimeMs:
        extractionTopic.start_time === null
          ? Number(logStartDate)
          : secondsToMilliseconds(extractionTopic.start_time),
      endTimeMs:
        extractionTopic.end_time === null
          ? Number(logEndDate)
          : secondsToMilliseconds(extractionTopic.end_time),
      sampleFrequency: extractionTopic.freq,
    });
  });

  return Array.from(extractionTopics.values());
}

function extractionsFromApi({
  count,
  data,
}: ExtractionList): SearchResult<Extraction[]> {
  return {
    count,
    data: data.map((extraction) => extractionFromApi({ data: extraction })),
  };
}

function extractionFromApi({ data }: FromApi<ApiExtraction>): Extraction {
  return {
    id: data.id,
    logId: data.log_id,
    name: data.name ?? data.id,
    createdAt: new Date(data.created_at),
    createdBy: data.created_by,
    lifespan: { weeks: 1 },
    status: data.status,
    error: data.error,
  };
}
