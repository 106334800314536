import { MOTD as ApiMOTD } from "racer-openapi-ts-sdk";
import { useQuery } from "react-query";
import { useApi } from "../providers/ApiProvider";
import { FromApi, MOTD } from "./types";

// Query key factory

export const motdKeys = {
  all: ["motd"] as const,
};

// Queries

export function useMotd() {
  const { authenticatedClient } = useApi();

  return useQuery(motdKeys.all, authenticatedClient.getMotd, {
    select: fromApi,
  });
}

// Selectors

function fromApi({ data }: FromApi<ApiMOTD>): MOTD {
  return {
    title: data.title,
    message: data.message,
    createdAt: data.created_at != null ? new Date(data.created_at) : null,
    expiresAt: data.expires_at != null ? new Date(data.expires_at) : null,
  };
}
