import { IngestionList } from "racer-openapi-ts-sdk";
import { useQuery, UseQueryOptions } from "react-query";
import { useApi } from "../providers/ApiProvider";
import {
  BaseListOptions,
  BaseSearchOptions,
  Ingestion,
  Log,
  SearchResult,
} from "./types";

// Types

export interface IngestionSearchOptions extends BaseSearchOptions {
  logId?: Log["id"];
  status?: NonNullable<Ingestion["status"]>;
}

export interface IngestionListOptions extends BaseListOptions {
  logId?: Log["id"];
  status?: NonNullable<Ingestion["status"]>;
}

export type UseIngestionsOptions =
  | IngestionSearchOptions
  | IngestionListOptions;

// Query key factory

export const ingestionKeys = {
  all: ["ingestions"] as const,
  list: (filters: UseIngestionsOptions) =>
    [...ingestionKeys.all, "list", filters] as const,
};

// Queries

export function useIngestions(
  opts: UseIngestionsOptions,
  queryOpts?: Pick<
    UseQueryOptions,
    "keepPreviousData" | "staleTime" | "cacheTime"
  >
) {
  const { authenticatedClient } = useApi();

  return useQuery(
    ingestionKeys.list(opts),
    () => authenticatedClient.listIngestions(opts),
    {
      select: ingestionsFromApi,
      ...queryOpts,
    }
  );
}

// Selectors

function ingestionsFromApi({
  count,
  data,
}: IngestionList): SearchResult<Ingestion[]> {
  return {
    count,
    data: data.map((apiIngestion) => ({
      id: apiIngestion.id,
      logId: apiIngestion.log_id,
      format: apiIngestion.format ?? null,
      s3: {
        bucket: apiIngestion.s3_bucket,
        key: apiIngestion.s3_key,
      },
      size: apiIngestion.size,
      status: apiIngestion.status,
      createdAt: new Date(apiIngestion.created_at),
    })),
  };
}
