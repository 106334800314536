import invariant from "invariant";
import {
  AuthenticateResponse1Data,
  RefreshTokenResponse2Data,
} from "racer-openapi-ts-sdk";
import { useMutation } from "react-query";
import config from "../config";
import { useApi } from "../providers/ApiProvider";
import {
  removeAuthCookie,
  setAuthCookie,
  useAuth,
} from "../providers/AuthProvider";
import { refreshedTokensFromApi } from "./selectors";
import {
  Credentials,
  FromApi,
  SignInResponse,
  Tokens,
  UserChallengeResponse,
} from "./types";

// Mutations

export function useRespondToChallenge() {
  const { setAuthState, tokenStore } = useAuth();
  const { unauthenticatedClient } = useApi();

  return useMutation(
    async (challengeResponse: UserChallengeResponse) => {
      const resp = await unauthenticatedClient.respondToChallenge(
        challengeResponse
      );

      return tokensFromApi(resp);
    },
    {
      onSuccess(newTokens) {
        tokenStore.set(newTokens);
        setAuthCookie(newTokens.idToken);
        setAuthState("authenticated");
      },
    }
  );
}

export function useSignIn() {
  const { setAuthState, tokenStore } = useAuth();
  const { unauthenticatedClient } = useApi();

  return useMutation(
    async (creds: Credentials) => {
      const resp = await unauthenticatedClient.signIn(creds);

      return signInFromApi(resp);
    },
    {
      onSuccess(signInResponse) {
        if ("expiresIn" in signInResponse) {
          tokenStore.set(signInResponse);
          setAuthCookie(signInResponse.idToken);
          setAuthState("authenticated");
        }
      },
    }
  );
}

export function useSignOut() {
  const { setAuthState, tokenStore } = useAuth();
  const { unauthenticatedClient } = useApi();

  return useMutation(
    async () => {
      const entry = tokenStore.get();

      if (entry === null) {
        return;
      }

      await unauthenticatedClient.signOut(entry.tokens.refreshToken);
    },
    {
      onSettled() {
        // Unconditionally log the user out and remove their tokens from storage
        tokenStore.remove();
        removeAuthCookie();
        setAuthState("unauthenticated");
      },
    }
  );
}

export function useSignUp() {
  const { unauthenticatedClient } = useApi();

  return useMutation(async (email: Credentials["email"]) => {
    // Same deal here as the useSignOut mutation function
    await unauthenticatedClient.signUp(email);
  });
}

export function useExternalSignIn() {
  const externalAuth = config.externalAuth;

  const { setAuthState, tokenStore } = useAuth();

  return useMutation(
    async (code: string): Promise<Tokens> => {
      invariant(externalAuth !== null, "External auth supported in this build");

      const resp = await fetch(externalAuth.tokenEndpoint, {
        method: "POST",
        body: new URLSearchParams({
          grant_type: "authorization_code",
          client_id: externalAuth.clientId,
          client_secret: externalAuth.clientSecret,
          redirect_uri: externalAuth.redirectUri,
          code,
        }),
      });

      if (!resp.ok) {
        throw resp;
      }

      const json = await resp.json();

      // Really not type safe. Should probably use runtypes at some point
      return {
        idToken: json.id_token,
        accessToken: json.access_token,
        refreshToken: json.refresh_token,
        expiresIn: json.expires_in,
      };
    },
    {
      onSuccess(newTokens) {
        tokenStore.set(newTokens);
        setAuthCookie(newTokens.idToken);
        setAuthState("authenticated");
      },
    }
  );
}

// Selectors

function signInFromApi({
  data,
}: FromApi<AuthenticateResponse1Data>): SignInResponse {
  if (data.challenge_name != null) {
    return {
      name: data.challenge_name,
      // When challenge name is present, session is not null
      session: data.session!,
    };
  } else {
    return tokensFromApi({
      data: {
        refresh_token: data.refresh_token!,
        id_token: data.id_token!,
        access_token: data.access_token!,
        expires_in: data.expires_in,
      },
    });
  }
}

function tokensFromApi({ data }: FromApi<RefreshTokenResponse2Data>): Tokens {
  return {
    ...refreshedTokensFromApi({ data }),
    refreshToken: data.refresh_token,
  };
}
