import React from "react";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";

export default function Panel({ node, getContent, onResize }) {
  function handleResize(node) {
    return ({ component }) => onResize(node.id, component.props.flex ?? 0.0);
  }

  if (node.type === "container") {
    return (
      <ReflexContainer orientation={node.orientation}>
        <ReflexElement
          flex={node.firstChild.flex}
          onResize={handleResize(node.firstChild)}
        >
          <Panel
            node={node.firstChild}
            getContent={getContent}
            onResize={onResize}
          />
        </ReflexElement>
        <ReflexSplitter />
        <ReflexElement
          flex={node.secondChild.flex}
          onResize={handleResize(node.secondChild)}
        >
          <Panel
            node={node.secondChild}
            getContent={getContent}
            onResize={onResize}
          />
        </ReflexElement>
      </ReflexContainer>
    );
  } else {
    return (
      <div style={{ height: "100%", width: "100%", position: "relative" }}>
        {getContent(node)}
      </div>
    );
  }
}
