import React, { MutableRefObject, useEffect } from "react";
import {
  FastRewind,
  Fullscreen,
  FullscreenExit,
  Pause,
  PlayArrow,
  Replay,
  Share,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Slider,
  Tooltip,
} from "@mui/material";
import invariant from "invariant";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { PlaySpeed, SquareWave } from "mdi-material-ui";
import { useSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatTimestamp } from "../../../new-utils";
import { usePlayback } from "../contexts";
import useFullscreen from "../hooks/useFullscreen";
import { PlaybackSpeed, Timestep } from "../types";

function offsetMsForTimestep(timestep: typeof Timestep[keyof typeof Timestep]) {
  if (timestep === Timestep.Second) {
    return {
      offsetMs: 1_000,
      intervalFrequency: 1,
    };
  } else {
    return {
      offsetMs: 100,
      intervalFrequency: 10,
    };
  }
}

export interface PlaybackControllerProps {
  fullscreenRef: MutableRefObject<HTMLElement | undefined>;
  profileManager: JSX.Element;
}

export default function PlaybackController({
  fullscreenRef,
  profileManager,
}: PlaybackControllerProps) {
  // TODO: Once TS 4.6 is out with good support for control flow analysis
  //  of discriminated union types, maybe go back to destructuring all of this
  const playback = usePlayback();

  const speedMenuState = usePopupState({
    variant: "popover",
    popupId: "speed-menu",
  });
  const stepMenuStep = usePopupState({
    variant: "popover",
    popupId: "step-menu",
  });

  const { enqueueSnackbar } = useSnackbar();

  const { isFullscreen, toggle } = useFullscreen();

  const { offsetMs, intervalFrequency } = offsetMsForTimestep(
    playback.timestep
  );

  // useEffect dependency array doesn't play well with object methods so
  // pull it out
  const { setTimestampMs } = playback;

  useEffect(() => {
    if (playback.isPlaying) {
      const playbackInterval = Math.floor(
        1_000 / playback.playbackSpeed / intervalFrequency
      );

      const intervalId = setInterval(
        () => setTimestampMs("next"),
        playbackInterval
      );

      return () => clearInterval(intervalId);
    }
  }, [
    playback.isPlaying,
    playback.playbackSpeed,
    setTimestampMs,
    intervalFrequency,
  ]);

  const isAtStart = playback.timestampMs === playback.boundsMs?.[0];
  const isAtEnd = playback.timestampMs === playback.boundsMs?.[1];

  // Reason for adding keys to to tooltips here:
  // These buttons are dynamic and change based on playback state.
  // Without the keys, a warning will appear in the console about
  // some invalid 'anchorEl' being provided to the tooltip's underlying
  // popover. My assumption is that when we change the tooltip's first
  // child from a <span> to an <IconButton>, the DOM node ref is lost
  // and causes the warning. The keys should prevent this by forcing
  // React not to reuse the tooltip's children and instead mount new
  // tooltips each time
  let playbackControl;
  if (playback.isPlaying) {
    playbackControl = (
      <Tooltip key={1} title="Pause">
        <IconButton
          aria-label="Pause log playback"
          onClick={() => playback.setIsPlaying(false)}
          size="large"
        >
          <Pause />
        </IconButton>
      </Tooltip>
    );
  } else if (!playback.isLoading && isAtEnd) {
    playbackControl = (
      <Tooltip key={2} title="Replay">
        <IconButton
          aria-label="Replay log from beginning"
          onClick={() => playback.setTimestampMs(playback.boundsMs[0], true)}
          size="large"
        >
          <Replay />
        </IconButton>
      </Tooltip>
    );
  } else {
    playbackControl = (
      <Tooltip key={3} title="Play">
        <span>
          <IconButton
            disabled={playback.isLoading}
            aria-label="Start or resume log playback"
            onClick={() => playback.setIsPlaying(true)}
            size="large"
          >
            <PlayArrow />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  let playbackDuration;
  if (playback.isLoading) {
    playbackDuration = (
      <span>
        <Skeleton width={50} /> / <Skeleton width={60} />
      </span>
    );
  } else {
    playbackDuration = (
      <span>
        <code>
          {formatTimestamp(playback.timestampMs, {
            precision: 1,
            relativeToMs: playback.boundsMs[0],
          })}
        </code>
        {" / "}
        <code>
          {formatTimestamp(playback.boundsMs[1], {
            precision: 1,
            relativeToMs: playback.boundsMs[0],
          })}
        </code>
      </span>
    );
  }

  let shareableLink: string = "";
  if (!playback.isLoading) {
    const currentUrl = new URL(window.location.href);

    // Note: this keeps existing search parameters. Maybe we don't want
    // that but currently there's nothing important kept in there
    currentUrl.searchParams.set("t", playback.timestampMs.toString());

    shareableLink = currentUrl.toString();
  }

  return (
    <Box
      sx={{
        mt: "auto",
        borderTop: 1,
        borderTopColor: "divider",
        p: 3,
        "& .MuiSkeleton-root": {
          display: "inline-block",
        },
      }}
    >
      <Slider
        disabled={playback.isLoading}
        min={playback.boundsMs?.[0] ?? 0}
        max={playback.boundsMs?.[1] ?? 0}
        step={offsetMs}
        onChange={(e, value) => playback.setTimestampMs(value as number, false)}
        value={playback.timestampMs ?? 0}
      />
      <Box display="flex" alignItems="center">
        <Tooltip title="Restart">
          <span>
            <IconButton
              disabled={isAtStart || playback.isLoading}
              aria-label="Restart playback"
              onClick={() => {
                invariant(
                  !playback.isLoading,
                  "Cannot set timestamp without defined playback bounds"
                );

                playback.setTimestampMs(playback.boundsMs[0], false);
              }}
              size="large"
            >
              <FastRewind />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Previous">
          <span>
            <IconButton
              disabled={isAtStart || playback.isLoading}
              aria-label="Move backward to previous timestamp"
              onClick={() => playback.setTimestampMs("prev", false)}
              size="large"
            >
              <SkipPrevious />
            </IconButton>
          </span>
        </Tooltip>
        {playbackControl}
        <Tooltip title="Next">
          <span>
            <IconButton
              disabled={isAtEnd || playback.isLoading}
              aria-label="Move forward to next timestamp"
              onClick={() => playback.setTimestampMs("next", false)}
              size="large"
            >
              <SkipNext />
            </IconButton>
          </span>
        </Tooltip>
        {playbackDuration}
        <Box display="inline-block" ml="auto">
          {profileManager}
          <Tooltip title="Copy shareable link">
            <span>
              <CopyToClipboard
                text={shareableLink}
                onCopy={(_, result) => {
                  if (result) {
                    enqueueSnackbar("Link copied", {
                      variant: "success",
                    });
                  } else {
                    enqueueSnackbar("Unable to copy link", {
                      variant: "error",
                    });
                  }
                }}
              >
                <IconButton
                  disabled={playback.isLoading}
                  aria-label="Copy shareable link"
                  size="large"
                >
                  <Share />
                </IconButton>
              </CopyToClipboard>
            </span>
          </Tooltip>
          <Tooltip title="Playback speed">
            <span>
              <IconButton
                disabled={playback.isLoading}
                aria-label="Open playback speed menu"
                size="large"
                {...bindTrigger(speedMenuState)}
              >
                <PlaySpeed />
              </IconButton>
            </span>
          </Tooltip>
          <Menu {...bindMenu(speedMenuState)}>
            <MenuItem
              selected={playback.playbackSpeed === PlaybackSpeed.TimesOne}
              onClick={() => playback.setPlaybackSpeed(PlaybackSpeed.TimesOne)}
            >
              1x
            </MenuItem>
            <MenuItem
              selected={playback.playbackSpeed === PlaybackSpeed.TimesTwo}
              onClick={() => playback.setPlaybackSpeed(PlaybackSpeed.TimesTwo)}
            >
              2x
            </MenuItem>
            <MenuItem
              selected={playback.playbackSpeed === PlaybackSpeed.TimesFive}
              onClick={() => playback.setPlaybackSpeed(PlaybackSpeed.TimesFive)}
            >
              5x
            </MenuItem>
            <MenuItem
              selected={playback.playbackSpeed === PlaybackSpeed.TimesTen}
              onClick={() => playback.setPlaybackSpeed(PlaybackSpeed.TimesTen)}
            >
              10x
            </MenuItem>
          </Menu>
          <Tooltip title="Timestep">
            <span>
              <IconButton
                disabled={playback.isLoading}
                aria-label="Open timestep control menu"
                size="large"
                {...bindTrigger(stepMenuStep)}
              >
                <SquareWave />
              </IconButton>
            </span>
          </Tooltip>
          <Menu {...bindMenu(stepMenuStep)}>
            <MenuItem
              selected={playback.timestep === Timestep.Second}
              onClick={() => playback.setTimestep(Timestep.Second)}
            >
              1 second
            </MenuItem>
            <MenuItem
              selected={playback.timestep === Timestep.Decisecond}
              onClick={() => playback.setTimestep(Timestep.Decisecond)}
            >
              0.1 seconds
            </MenuItem>
          </Menu>
          <Tooltip
            title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
          >
            <span>
              <IconButton
                size="large"
                onClick={() => toggle(fullscreenRef.current)}
              >
                {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}
