import { TokenEntry, Tokens } from "./types";

export interface TokenStore {
  get: () => TokenEntry | null;
  set: (tokens: Tokens) => void;
  remove: () => void;
}

export class MemoryTokenStore implements TokenStore {
  #entry: TokenEntry | null;

  constructor(initialEntry: TokenEntry | null = null) {
    this.#entry = initialEntry;
  }

  get() {
    return this.#entry;
  }

  set(tokens: Tokens) {
    this.#entry = {
      tokens,
      storedAt: Date.now(),
    };
  }

  remove() {
    this.#entry = null;
  }
}

export class StorageTokenStore implements TokenStore {
  #storage: Storage;
  readonly #key: string;

  constructor(storage: Storage, key: string) {
    this.#storage = storage;
    this.#key = key;
  }

  get() {
    const storedValue = this.#storage.getItem(this.#key);

    return storedValue === null ? null : JSON.parse(storedValue);
  }

  set(tokens: Tokens) {
    const entry: TokenEntry = {
      tokens,
      storedAt: Date.now(),
    };

    this.#storage.setItem(this.#key, JSON.stringify(entry));
  }

  remove() {
    this.#storage.removeItem(this.#key);
  }
}
