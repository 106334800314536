import { areIntervalsOverlapping, secondsToMilliseconds } from "date-fns";
import { floor, range } from "lodash";
import { RecordList } from "racer-openapi-ts-sdk";
import { UseQueryResult } from "react-query";
import { reduceQueries } from "../../../new-utils";
import {
  Log,
  Record,
  SampleFreq,
  Topic,
  useRecordsQueries,
  UseRecordsQueriesOptions,
} from "../../../queries";
import { Chunk, ChunkSettings } from "../types";

interface UseChunksOptions {
  logId: Log["id"];
  topicId?: Topic["id"] | null;
  enabled?: boolean;
  timeRangeMs?: [number, number];
  sampleFrequency?: SampleFreq.Second | SampleFreq.Decisecond;
  includeImage?: boolean;
  bufferBehindMs?: number;
  bufferAheadMs?: number;
  chunkSizeMs: number;
}

export default function useChunks({
  logId,
  topicId,
  enabled = true,
  timeRangeMs,
  sampleFrequency = SampleFreq.Second,
  includeImage = false,
  bufferBehindMs = 0,
  bufferAheadMs = 0,
  chunkSizeMs,
}: UseChunksOptions) {
  let settingsList: ChunkSettings[] = [];
  let useRecordsQueriesOpts: UseRecordsQueriesOptions<Record[]>[] = [];

  if (enabled && timeRangeMs !== undefined) {
    // If buffering amounts are given, those needed to be taken into account
    // when determining the time window to fetch chunks in
    const chunkWindow: [number, number] = [
      timeRangeMs[0] - bufferBehindMs,
      timeRangeMs[1] + bufferAheadMs,
    ];

    // Deterministically calculate the bounds of each chunk in the topic
    const chunks = calculateChunks(chunkSizeMs, chunkWindow);

    // Go through and mark which chunks are required to populate the window
    settingsList = calculateChunksSettings(chunks, timeRangeMs);

    useRecordsQueriesOpts = settingsList.map(
      (settings): typeof useRecordsQueriesOpts[number] => ({
        searchOpts: {
          logId,
          topicId,
          sort: "asc",
          field: "timestamp",
          pageSize: 1_000,
          startTimeMs: settings.chunk[0],
          endTimeMs: settings.chunk[1],
          freq: sampleFrequency,
          includeImage,
        },
        queryOpts: {
          staleTime: Infinity,
          cacheTime: secondsToMilliseconds(20),
          select,
        },
      })
    );
  }

  const queries = useRecordsQueries(useRecordsQueriesOpts);

  return reduceChunkQueries(settingsList, queries);
}

function select(records: RecordList): Record[] {
  return records.data.map((record) => ({
    ...record,
    timestampMs: floor(secondsToMilliseconds(record.timestamp), 1),
  }));
}

function calculateChunks(
  chunkSizeMs: number,
  chunkWindow: [number, number]
): Chunk[] {
  const startingChunkIdx = Math.floor(chunkWindow[0] / chunkSizeMs);
  const numChunks = Math.ceil((chunkWindow[1] - chunkWindow[0]) / chunkSizeMs);

  return range(startingChunkIdx, startingChunkIdx + numChunks).map(
    (chunkIdx) => {
      const chunkStartMs = chunkIdx * chunkSizeMs;
      const chunkEndS = chunkStartMs + chunkSizeMs;

      return [chunkStartMs, chunkEndS];
    }
  );
}

function calculateChunksSettings(
  chunks: Chunk[],
  timeRangeMs: [number, number]
): ChunkSettings[] {
  return chunks.map((chunk) => ({
    chunk,
    // A chunk is required for playback if it overlaps with the window
    // in any way as that means its data needs to be displayed
    required: areIntervalsOverlapping(
      { start: chunk[0], end: chunk[1] },
      { start: timeRangeMs[0], end: timeRangeMs[1] },
      { inclusive: true }
    ),
  }));
}

function reduceChunkQueries(
  settingsList: ChunkSettings[],
  queries: UseQueryResult<Record[]>[]
): Pick<
  UseQueryResult<Record[]>,
  "isIdle" | "isError" | "isLoading" | "isSuccess" | "data"
> {
  if (settingsList.length === 0) {
    // An empty settings list means we're still waiting on the log or
    // topic query to load and don't know how many chunks there are yet,
    // so the reduced result is just idle
    return {
      isIdle: true,
      isError: false,
      isLoading: false,
      isSuccess: false,
      data: undefined,
    };
  }

  const requiredQueries = queries.filter(
    (query, index) => settingsList[index].required
  );

  return reduceQueries(requiredQueries);
}
