import React, { useState } from "react";
import { InsertChart } from "@mui/icons-material";
import { Box, ToggleButton, Tooltip, Typography } from "@mui/material";
import { minutesToMilliseconds, secondsToMilliseconds } from "date-fns";
import _ from "lodash";
import { useParams } from "react-router-dom";
import {
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryClipContainer,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryZoomContainer,
} from "victory";
import Error from "../../../components/Error";
import Loading from "../../../components/Loading";
import { formatTimestamp } from "../../../new-utils";
import { useLog, VisualizationPanelState } from "../../../queries";
import unit from "../../../unit";
import * as paths from "../../paths";
import { usePlayback } from "../contexts";
import useOverview from "../hooks/useOverview";
import useWindow from "../hooks/useWindow";

interface ChartPanelProps {
  topic: any;
  fields: VisualizationPanelState["fields"];
  height: number;
  width: number;
}

const strokeStyles = ["#e6194B", "#4363d8", "#3cb44b"];

export default function ChartPanel({
  topic,
  fields,
  height,
  width,
}: ChartPanelProps) {
  const [showOverview, setShowOverview] = useState(false);

  const { boundsMs, timestampMs } = usePlayback();

  const { logId } = useParams<paths.LogParams>();

  const logQuery = useLog(logId);

  const recordsWindow = useWindow({
    logId,
    topicId: topic.id,
    bufferBehindMs: secondsToMilliseconds(30),
    bufferAheadMs: minutesToMilliseconds(1.5),
    windowSizeMs: secondsToMilliseconds(30),
    chunkSizeMs: secondsToMilliseconds(15),
  });

  const recordsOverview = useOverview({
    logId,
    topicId: topic.id,
    enabled: showOverview,
    timeRangeMs: boundsMs,
  });

  if (
    logQuery.isLoading ||
    recordsWindow.query.isLoading ||
    timestampMs === undefined ||
    boundsMs === undefined
  ) {
    return <Loading type="circular" />;
  } else if (logQuery.isError || recordsWindow.query.isLoading) {
    return (
      <Error>
        <Typography variant="h5" component="p" color="error">
          An error occurred. Can't get chart data
        </Typography>
      </Error>
    );
  } else {
    const windowData = recordsWindow.query.data!;

    const qualifiedFields = fields.map((field) => `message_data.${field}`);
    const [windowRangeLow, windowRangeHigh] = findExtremes(
      qualifiedFields,
      windowData
    );

    let overview: React.ReactNode = undefined;
    if (showOverview) {
      const overviewHeight = Math.round(height * 0.2);

      if (recordsOverview.isLoading) {
        overview = (
          <Box width="100%" height={overviewHeight}>
            <Loading type="circular" />
          </Box>
        );
      } else if (recordsOverview.isError) {
        overview = (
          <Box width="100%" height={overviewHeight}>
            <Error>
              <Typography variant="h5" component="p" color="error">
                An error occurred. Unable to show overview chart
              </Typography>
            </Error>
          </Box>
        );
      } else {
        overview = (
          <VictoryChart
            height={overviewHeight}
            width={width}
            domain={{ x: boundsMs }}
            domainPadding={{ y: 5 }}
            containerComponent={
              <VictoryBrushContainer
                allowDrag={false}
                allowDraw={false}
                allowResize={false}
                brushDimension="x"
                brushDomain={{ x: recordsWindow.windowBoundsMs }}
                responsive={false}
              />
            }
            padding={{ left: 75, top: 25, bottom: 25, right: 50 }}
            theme={VictoryTheme.material}
          >
            <VictoryAxis
              orientation="bottom"
              tickFormat={(t) =>
                formatTimestamp(t, { precision: 1, relativeToMs: boundsMs[0] })
              }
            />
            {qualifiedFields.map((field, idx) => (
              <VictoryLine
                data={recordsOverview.data}
                key={field}
                x="timestampMs"
                y={field}
                style={{
                  data: {
                    stroke: strokeStyles[idx],
                  },
                }}
              />
            ))}
          </VictoryChart>
        );
      }
    }

    return (
      <Box height={1} width={1} overflow="hidden">
        <VictoryChart
          height={showOverview ? Math.round(height * 0.8) : height}
          width={width}
          domain={{
            x: boundsMs,
            y: [windowRangeLow, windowRangeHigh],
          }}
          domainPadding={{ y: 5 }}
          containerComponent={
            <VictoryZoomContainer
              allowPan={false}
              allowZoom={false}
              zoomDimension="x"
              zoomDomain={{ x: recordsWindow.windowBoundsMs }}
              responsive={false}
              clipContainerComponent={
                <VictoryClipContainer clipPadding={{ left: 1, right: 1 }} />
              }
            />
          }
          padding={{ left: 75, top: 25, bottom: 75, right: 50 }}
          theme={VictoryTheme.material}
        >
          <VictoryLegend
            orientation="horizontal"
            data={fields.map((field, idx) => {
              const u = unit({ topic: topic.messageType, path: field });
              const label = u === "" ? field : `${field} (${u})`;
              return { name: label, symbol: { fill: strokeStyles[idx] } };
            })}
          />
          <VictoryAxis
            orientation="bottom"
            label="seconds"
            axisLabelComponent={<VictoryLabel dy={50} />}
            tickFormat={(t) =>
              formatTimestamp(t, { precision: 1, relativeToMs: boundsMs[0] })
            }
          />
          <VictoryAxis
            crossAxis
            dependentAxis
            axisLabelComponent={<VictoryLabel dy={-50} />}
            tickFormat={(t) => _.round(t, 3)}
          />
          {qualifiedFields.map((field, idx) => (
            <VictoryLine
              data={windowData}
              key={field}
              labels={({ datum }) => [
                `timestamp: ${formatTimestamp(datum._x, {
                  precision: 1,
                  relativeToMs: boundsMs[0],
                })}`,
                `value: ${datum._y}`,
              ]}
              labelComponent={<VictoryTooltip />}
              x="timestampMs"
              y={field}
              style={{
                data: {
                  stroke: strokeStyles[idx],
                },
              }}
            />
          ))}
          {qualifiedFields.map((field, idx) => {
            return (
              <VictoryScatter
                key={field}
                data={windowData}
                x="timestampMs"
                y={field}
                size={2}
                style={{
                  data: {
                    fill: strokeStyles[idx],
                  },
                }}
              />
            );
          })}
          <VictoryLine x={() => timestampMs} />
        </VictoryChart>
        {overview}
        <Tooltip title="Toggle overview chart">
          <ToggleButton
            sx={{
              position: "absolute",
              left: (theme) => theme.spacing(1),
              bottom: (theme) => theme.spacing(1),
            }}
            aria-label="Toggle overview chart"
            value={true}
            selected={showOverview}
            onChange={() => setShowOverview(!showOverview)}
          >
            <InsertChart />
          </ToggleButton>
        </Tooltip>
      </Box>
    );
  }
}

function findExtremes(
  fields: VisualizationPanelState["fields"],
  windowData: any[]
): [number, number] {
  // TODO: Consider smarter ways of handling empty windows than
  //  setting the range to [0, 0]
  if (windowData.length === 0) {
    return [0, 0];
  }

  let min = Infinity;
  let max = -Infinity;
  windowData.forEach((datum) => {
    const fieldVals = fields.map((field) => _.get(datum, field));
    min = Math.min(min, ...fieldVals);
    max = Math.max(max, ...fieldVals);
  });

  return [min, max];
}
