import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useLocalstorage } from "rooks";
import * as Yup from "yup";
import config from "../../config";
import * as paths from "../../views/paths";
import NavigationLink from "../NavigationLink";

const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email("The provided email is invalid")
    .required("An email is required"),
  password: Yup.string()
    .min("8", "The provided password is too short")
    .required("The password is required"),
});

export default function SignInForm({
  titleComponent = "h2",
  dialogPresentation = false,
  showSignUp = true,
  initialErrorMessage = null,
  signIn,
}) {
  const [storedEmail, setStoredEmail] = useLocalstorage("email", "");
  const [showPassword, setShowPassword] = useState(false);

  async function handleSignIn(values) {
    setStoredEmail(values.email);

    try {
      await signIn.mutateAsync({
        email: values.email,
        password: values.password,
      });
    } catch {
      /* noop */
    }
  }

  return (
    <Formik
      initialValues={{
        email: storedEmail,
        password: "",
      }}
      onSubmit={handleSignIn}
      validationSchema={signInSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          {titleComponent && (
            <Typography variant="h6" component={titleComponent} gutterBottom>
              Sign In to {config.datastoreInstanceName} DataStore
            </Typography>
          )}
          <Field
            component={TextField}
            id="email"
            name="email"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            label="Email"
            InputProps={{ autoComplete: "email" }}
          />
          <Field
            component={TextField}
            id="password"
            name="password"
            type={showPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            variant="outlined"
            label="Password"
            InputProps={{
              autoComplete: "current-password",
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={showPassword ? "Hide password" : "Show password"}
                  >
                    <IconButton
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                      onClick={() => setShowPassword(!showPassword)}
                      size="large"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          {!dialogPresentation && (
            <Button
              sx={{ my: 2 }}
              disabled={isSubmitting}
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
            >
              Sign In
            </Button>
          )}
          {showSignUp && (
            <Typography variant="body2" align="center">
              New user?{" "}
              <NavigationLink to={paths.signUp}>Sign up</NavigationLink>
            </Typography>
          )}
          {(signIn.isError || initialErrorMessage) && (
            <Alert sx={{ mt: 4 }} variant="filled" severity="error">
              {initialErrorMessage ||
                "Unable to sign in. Make sure the username and password you provided are correct."}
            </Alert>
          )}
          {dialogPresentation && (
            <Box justifyContent="flex-end">
              <Button
                sx={{ mt: 2 }}
                disabled={isSubmitting}
                type="submit"
                variant="text"
                color="primary"
              >
                Sign In
              </Button>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
}
