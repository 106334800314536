import React, { useReducer } from "react";
import { Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import DescriptionList from "../../../components/DescriptionList";
import DividedCardHeader from "../../../components/DividedCardHeader";
import { useFeature } from "../../../providers/Features";

export default function MetadataCard({
  canUserEdit,
  log,
  isLoading,
  onUpdate,
}) {
  const [editMode, toggleEditMode] = useReducer((x) => !x, false);

  const editBehavior = useFeature("logs.edit");

  async function handleSubmit(values) {
    try {
      await onUpdate(values);
    } catch (e) {
      /* noop */
    }
  }

  let content;
  if (isLoading) {
    content = (
      <DescriptionList
        items={[
          {
            key: "Name",
            value: <Skeleton width="25ch" />,
          },
          {
            key: "Operator",
            value: <Skeleton width="20ch" />,
          },
          {
            key: "Vehicle",
            value: <Skeleton width="15ch" />,
          },
          {
            key: "Test ID",
            value: <Skeleton width="15ch" />,
          },
          {
            key: "Sensors Online",
            value: <Skeleton width="45ch" />,
          },
          {
            key: "Software Config",
            value: <Skeleton width="45ch" />,
          },
          {
            key: "Comments",
            value: <Skeleton width="45ch" />,
          },
        ]}
        layout="stacked"
      />
    );
  } else if (editMode) {
    const { metadata } = log;

    content = (
      <Formik
        initialValues={{
          name: metadata.name || "",
          operator: metadata.operator || "",
          vehicle: metadata.vehicle || "",
          testId: metadata.testId || "",
          sensorsOnline: metadata.sensorsOnline || "",
          softwareConfig: metadata.softwareConfig || "",
          comments: metadata.comments || "",
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Grid container direction="column" spacing={2} component={Form}>
            <Grid item>
              <Field
                component={TextField}
                id="name"
                name="name"
                fullWidth
                variant="outlined"
                label="Name"
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item>
              <Field
                component={TextField}
                id="operator"
                name="operator"
                fullWidth
                variant="outlined"
                label="Operator"
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item>
              <Field
                component={TextField}
                id="vehicle"
                name="vehicle"
                fullWidth
                variant="outlined"
                label="Vehicle"
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item>
              <Field
                component={TextField}
                id="testId"
                name="testId"
                fullWidth
                variant="outlined"
                label="Test ID"
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item>
              <Field
                component={TextField}
                id="sensorsOnline"
                name="sensorsOnline"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                label="Sensors Online"
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item>
              <Field
                component={TextField}
                id="softwareConfig"
                name="softwareConfig"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                label="Software Config"
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item>
              <Field
                component={TextField}
                id="comments"
                name="comments"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                label="Comments"
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Tooltip
                    title={
                      editBehavior.disabled
                        ? editBehavior.reason
                        : !canUserEdit
                        ? "You don't have permission to edit logs"
                        : ""
                    }
                  >
                    <span>
                      <LoadingButton
                        disabled={editBehavior.disabled || !canUserEdit}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        loading={isSubmitting}
                      >
                        Save Changes
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Button
                    disabled={isSubmitting}
                    onClick={toggleEditMode}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    );
  } else {
    const { metadata } = log;

    content = (
      <DescriptionList
        items={[
          {
            key: "Name",
            value: metadata.name || "-",
          },
          {
            key: "Operator",
            value: metadata.operator || "-",
          },
          {
            key: "Vehicle",
            value: metadata.vehicle || "-",
          },
          {
            key: "Test ID",
            value: metadata.testId || "-",
          },
          {
            key: "Sensors Online",
            value: metadata.sensorsOnline || "-",
          },
          {
            key: "Software Config",
            value: metadata.softwareConfig || "-",
          },
          {
            key: "Comments",
            value: metadata.comments || "-",
          },
        ]}
        layout="stacked"
      />
    );
  }

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      elevation={4}
    >
      <DividedCardHeader
        height="2rem"
        title="Metadata"
        titleTypographyProps={{
          variant: "h6",
          component: "h2",
        }}
        action={
          <Tooltip title="Edit metadata">
            <span>
              <IconButton
                disabled={isLoading || editMode}
                aria-label="Edit metadata"
                onClick={toggleEditMode}
                size="large"
              >
                <Edit />
              </IconButton>
            </span>
          </Tooltip>
        }
      />
      <CardContent>{content}</CardContent>
    </Card>
  );
}
