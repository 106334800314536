import { useCallback } from "react";
import { secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import { Label as ApiLabel } from "racer-openapi-ts-sdk";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApi } from "../providers/ApiProvider";
import { useLog } from "./logs";
import { FromApi, Label, Log, NewLabel } from "./types";

// Query key factory

export const labelKeys = {
  all: ["labels"] as const,
  list: (logId: Log["id"]) => [...labelKeys.all, "list", { logId }] as const,
};

// Queries

export function useLabels(logId: Log["id"]) {
  const { authenticatedClient } = useApi();

  const logQuery = useLog(logId);

  const select = useCallback(
    ({ data }: FromApi<ApiLabel[]>): Label[] => {
      invariant(
        logQuery.isSuccess,
        "Log was not fetched successfully so labels cannot be retrieved for it."
      );

      invariant(
        logQuery.data.startDate !== null && logQuery.data.duration !== null,
        `Log ${logQuery.data.id} has no start date or duration
          labels cannot be retrieved for it.`
      );

      const logStartMs = Number(logQuery.data.startDate);
      const durationMs = logQuery.data.duration;

      return data.map((label) => {
        return labelFromApi(logStartMs, durationMs, { data: label });
      });
    },
    [logQuery.isSuccess, logQuery.data]
  );

  return useQuery(
    labelKeys.list(logId),
    () => authenticatedClient.listLogLabels(logId),
    {
      enabled: logQuery.isSuccess,
      select,
    }
  );
}

// Mutations

export function useCreateLabel(logId: Log["id"]) {
  const { authenticatedClient } = useApi();

  const queryClient = useQueryClient();

  const logQuery = useLog(logId);

  return useMutation(
    async (label: NewLabel) => {
      invariant(
        logQuery.isSuccess,
        `Log ${logId} was not fetched successfully so a label 
      cannot be created for it. Ensure the result of the useLog query 
      is successful before attempting to create a label`
      );

      invariant(
        logQuery.data.startDate !== null && logQuery.data.duration !== null,
        `Log ${logId} does not have a defined start and/or 
      end date so no label can be created for it`
      );

      const logStartMs = Number(logQuery.data.startDate);
      const durationMs = logQuery.data.duration;

      const resp = await authenticatedClient.createLogLabel(
        logQuery.data.id,
        label
      );

      return labelFromApi(logStartMs, durationMs, resp);
    },
    {
      onSuccess() {
        // Returning this result causes the mutation to stay in the loading
        // state until the refetching is *also* finished
        return queryClient.invalidateQueries(labelKeys.list(logId));
      },
    }
  );
}

export function useDeleteLabel(logId: Log["id"]) {
  const { authenticatedClient } = useApi();

  const queryClient = useQueryClient();

  return useMutation(
    async (labelId: Label["id"]) => {
      // Purposefully awaiting but doing nothing with the response
      // so this function will have no return value
      await authenticatedClient.deleteLogLabel(logId, labelId);
    },
    {
      onSuccess() {
        return queryClient.invalidateQueries(labelKeys.list(logId));
      },
    }
  );
}

// Selectors

function labelFromApi(
  logStartMs: number,
  logDurationMs: number,
  { data }: FromApi<ApiLabel>
): Label {
  return {
    id: data.id,
    logId: data.log_id,
    topicId: data.topic_id,
    startTimeMs:
      data.start_time === null
        ? logStartMs
        : secondsToMilliseconds(data.start_time),
    endTimeMs:
      data.start_time !== null && data.end_time === null
        ? null
        : data.end_time === null
        ? logStartMs + logDurationMs
        : secondsToMilliseconds(data.end_time),
    tag:
      data.tag === null || data.tag_type === null
        ? null
        : {
            type: data.tag_type,
            name: data.tag,
          },
    description: data.content,
    createdAt: new Date(data.created_at),
    createdBy: data.created_by,
  };
}
