import React, { ReactNode, useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Divider,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, FormikConfig } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import prettyBytes from "pretty-bytes";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import FormikEffect from "../../../components/FormikEffect";
import NavigationLink from "../../../components/NavigationLink";
import { useApi } from "../../../providers/ApiProvider";
import { useDraftExtraction } from "../../../providers/DraftExtractionProvider";
import { useFeature } from "../../../providers/Features";
import { extractionFileKeys, extractionKeys } from "../../../queries";
import * as paths from "../../paths";
import { ExtractionCheckoutFormValues } from "../Records";
import ExtractionListView from "./ExtractionListView";

const validationSchema = yup.object().shape({
  name: yup.string().trim().required("A name is required"),
});

export interface ExtractionCheckoutProps {
  extractionCheckoutFormValuesRef: React.MutableRefObject<ExtractionCheckoutFormValues>;
}

export default function ExtractionCheckout({
  extractionCheckoutFormValuesRef,
}: ExtractionCheckoutProps) {
  const [noticeAcked, setNoticeAcked] = useState(false);

  const createBehavior = useFeature("extractions.create");

  const { logId } = useParams<paths.LogParams>();

  const { isInitialized, templateId, estimator, creator, extractionTopics } =
    useDraftExtraction();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const { authenticatedClient } = useApi();

  useEffect(
    function prefetchTemplateExtraction() {
      if (templateId !== undefined) {
        // Don't need to prefetch the topics since those are already being
        // fetched by the draft provider at this point

        queryClient.prefetchQuery(
          extractionKeys.detail(logId, templateId),
          () => authenticatedClient.getExtraction(logId, templateId)
        );

        queryClient.prefetchQuery(
          extractionFileKeys.list(logId, templateId),
          () => authenticatedClient.listExtractionFiles(logId, templateId)
        );
      }
    },
    [templateId, queryClient, authenticatedClient, logId]
  );

  const handleSubmit: FormikConfig<ExtractionCheckoutFormValues>["onSubmit"] =
    function handleSubmit({ name }, { setFieldError, setSubmitting }) {
      creator.mutate(
        { name, topics: extractionTopics },
        {
          onSuccess(newExtraction) {
            enqueueSnackbar(
              <>
                Extraction created! &nbsp;
                <NavigationLink
                  color="inherit"
                  to={paths.toExtractionDetails(logId, newExtraction.id)}
                >
                  View it here
                </NavigationLink>
              </>,
              {
                variant: "success",
                persist: true,
                action(key) {
                  return (
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={() => closeSnackbar(key)}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  );
                },
              }
            );
          },
          async onError(e) {
            if (e instanceof Response && e.status === 422) {
              const { errorMessage } = await e.json();

              setFieldError(
                "name",
                errorMessage.replace("[Unprocessable Entity] ", "")
              );
            } else {
              enqueueSnackbar("Unable to create extraction", {
                variant: "error",
              });
            }
          },
          onSettled() {
            setSubmitting(false);
          },
        }
      );
    };

  let totalEstimation: ReactNode;
  if (extractionTopics.length > 0) {
    if (estimator.isError) {
      totalEstimation = (
        <Typography paragraph color="error">
          An error occurred trying to get estimate
        </Typography>
      );
    } else if (estimator.isLoading || estimator.isIdle) {
      totalEstimation = (
        <>
          <LinearProgress />
          <Typography paragraph>Updating estimation...</Typography>
        </>
      );
    } else if (estimator.data.isEmpty) {
      totalEstimation = (
        <Typography paragraph>
          <b>Error!</b> Selected topics would produce 0 messages. Adjust
          selections before creating extraction.
        </Typography>
      );
    } else {
      totalEstimation = (
        <Typography paragraph>
          <span>Total estimated size: </span>
          <b>
            {prettyBytes(estimator.data.totalSize!, {
              binary: true,
              maximumFractionDigits: 2,
            })}
          </b>
        </Typography>
      );
    }
  }

  const showNotice = isInitialized && templateId !== undefined && !noticeAcked;

  return (
    <>
      {showNotice && (
        <Alert
          severity="info"
          variant="filled"
          onClose={() => setNoticeAcked(true)}
        >
          Initial topics based on a{" "}
          <NavigationLink
            color="inherit"
            to={paths.toExtractionDetails(logId, templateId)}
          >
            template extraction
          </NavigationLink>
        </Alert>
      )}
      <ExtractionListView />
      <Divider sx={{ my: 2 }} />
      <Formik
        initialValues={extractionCheckoutFormValuesRef.current}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikEffect
              onChange={(values: ExtractionCheckoutFormValues) => {
                extractionCheckoutFormValuesRef.current = values;
              }}
            />
            <Field
              component={TextField}
              fullWidth
              name="name"
              margin="normal"
              variant="outlined"
              label="Extraction Name"
              InputProps={{ autoComplete: "off" }}
            />
            {totalEstimation}
            <Tooltip
              title={createBehavior.disabled ? createBehavior.reason : ""}
            >
              <span>
                <LoadingButton
                  type="submit"
                  disabled={
                    createBehavior.disabled ||
                    extractionTopics.length === 0 ||
                    creator.isSuccess
                  }
                  loading={isSubmitting}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Extract Topics
                </LoadingButton>
              </span>
            </Tooltip>
          </Form>
        )}
      </Formik>
    </>
  );
}
