import React from "react";
import { Box, Typography } from "@mui/material";
import invariant from "invariant";
import "react-reflex/styles.css";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import {
  Node,
  Orientation,
  Panel,
  UsePanelLayoutResult,
} from "../../../panel-layout";
import {
  EmptyPanelState,
  PanelState,
  useLogTopics,
  useUser,
  VisType,
  VisualizationPanelState,
} from "../../../queries";
import * as paths from "../../paths";
import { ControlPanel, TopicSelectPanel, VisualizationPanel } from "./index";
import { supportsImage, supportsMap } from "./panels";

interface TopicSectionProps {
  layoutState: UsePanelLayoutResult<PanelState>;
}

export default function TopicSection({ layoutState }: TopicSectionProps) {
  const { layout, ...layoutControllers } = layoutState;

  const { logId } = useParams<paths.LogParams>();

  const topicsQuery = useLogTopics(logId);

  const userQuery = useUser();

  function handleTopicSelect(panel: Panel<PanelState>) {
    return (
      name: EmptyPanelState["name"],
      messageType: EmptyPanelState["messageType"]
    ) => {
      invariant(
        panel.state?.type === "empty",
        "Can only set topic selection for empty panels"
      );

      layoutControllers.update(panel.id, {
        ...panel.state,
        name,
        messageType,
      });
    };
  }

  function handleTopicConfirm(panel: Panel<PanelState>) {
    return () => {
      invariant(
        panel.state?.type === "empty",
        "Can only confirm topic selection for empty panels"
      );

      invariant(
        panel.state.name !== null && panel.state.messageType !== null,
        "Attempting to confirm selection for panel with no selection"
      );

      let tab: VisType;
      if (supportsImage(panel.state.messageType)) {
        tab = "image";
      } else if (supportsMap(panel.state.messageType)) {
        tab = "map";
      } else {
        tab = "timeline";
      }

      layoutControllers.update(panel.id, {
        type: "vis",
        name: panel.state.name,
        messageType: panel.state.messageType,
        fields: [],
        tab,
      });
    };
  }

  function handleClearTopicSelection(panel: Panel<PanelState>) {
    return () => {
      invariant(
        panel.state?.type === "vis",
        "Can only clear topic selection for vis panels"
      );

      layoutControllers.update(panel.id, {
        type: "empty",
        name: panel.state.name,
        messageType: panel.state.messageType,
      });
    };
  }

  function handleFieldChange(panel: Panel<PanelState>) {
    return (fields: VisualizationPanelState["fields"]) => {
      invariant(
        panel.state?.type === "vis",
        "Can only change fields for vis panel"
      );

      layoutControllers.update(panel.id, {
        ...panel.state,
        fields,
      });
    };
  }

  function handleTabChange(panel: Panel<PanelState>) {
    return (tab: VisType) => {
      invariant(
        panel.state?.type === "vis",
        "Can only change fields for vis panel"
      );

      layoutControllers.update(panel.id, {
        ...panel.state,
        tab,
      });
    };
  }

  function handleSplit(panel: Panel<PanelState>) {
    return (orientation: Orientation) => {
      layoutControllers.split(panel.id, orientation, {
        type: "empty",
        name: null,
        messageType: null,
      });
    };
  }

  function handleRemove(panel: Panel<PanelState>) {
    return () => {
      layoutControllers.remove(panel.id, {
        type: "empty",
        name: null,
        messageType: null,
      });
    };
  }

  function handleResize(panelId: Node["id"], flex: Node["flex"]) {
    layoutControllers.resize(panelId, flex);
  }

  function getTopicContent(panelNode: Panel<PanelState>) {
    // It's easier for TS if you store the state separately and pass
    // *it* through type guards instead of passing the entire node
    // through a type guard in which case it has trouble narrowing
    // the state object's type
    const nodeState = panelNode.state;

    if (nodeState === null) {
      // TODO: Handle this better?
      return null;
    }

    let content;
    if (isEmptyState(nodeState)) {
      content = (
        <TopicSelectPanel
          panelId={panelNode.id}
          selectedTopicName={nodeState.name}
          onSelect={handleTopicSelect(panelNode)}
          onConfirm={handleTopicConfirm(panelNode)}
        />
      );
    } else {
      content = (
        <VisualizationPanel
          panelState={nodeState}
          onChangeFields={handleFieldChange(panelNode)}
          onChangeTab={handleTabChange(panelNode)}
        />
      );
    }

    return (
      <ControlPanel
        id={panelNode.id}
        panelState={nodeState}
        onClearTopic={handleClearTopicSelection(panelNode)}
        onSplit={handleSplit(panelNode)}
        onClose={handleRemove(panelNode)}
        onChangeTab={handleTabChange(panelNode)}
      >
        {content}
      </ControlPanel>
    );
  }

  return (
    <Box flex={1} minHeight={0}>
      {!topicsQuery.isSuccess || !userQuery.isSuccess ? (
        <Loading type="circular">
          <Typography variant="h6" component="p">
            Loading topics and profiles...
          </Typography>
        </Loading>
      ) : (
        <Panel
          node={layout}
          getContent={getTopicContent}
          onResize={handleResize}
        />
      )}
    </Box>
  );
}

function isEmptyState(state: PanelState): state is EmptyPanelState {
  return state.type === "empty";
}
