export const PlaybackSpeed = {
  TimesOne: 1,
  TimesTwo: 2,
  TimesFive: 5,
  TimesTen: 10,
} as const;

export const Timestep = {
  Second: "second",
  Decisecond: "decisecond",
} as const;

export const SampleFrequency = {
  Second: "second",
  Decisecond: "decisecond",
} as const;

export type Chunk = [number, number];

export interface ChunkSettings {
  chunk: Chunk;
  required: boolean;
}
