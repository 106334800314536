import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import { getUnixTime, isValid } from "date-fns";
import { find, startCase } from "lodash";
import DescriptionList from "../../../components/DescriptionList";
import DividedCardHeader from "../../../components/DividedCardHeader";
import { formatTimestamp } from "../../../new-utils";
import { useGroups } from "../../../queries";
import { logDistanceFormatter } from "../../../utils";

function formattedDateString(maybeDate) {
  if (isValid(maybeDate)) {
    let utcDate = maybeDate.toUTCString().replace("GMT", "UTC");
    let unixTime = getUnixTime(maybeDate);
    return `${utcDate} (${unixTime})`;
  }
}

export default function SummaryCard({ log, isLoading }) {
  const groupsQuery = useGroups();

  let items;
  if (isLoading) {
    items = [
      {
        key: "ID",
        value: <Skeleton width="34ch" />,
      },
      {
        key: "Status",
        value: <Skeleton width="15%" />,
      },
      {
        key: "Group",
        value: <Skeleton width="6ch" />,
      },
      {
        key: "Start Date",
        value: <Skeleton width="30%" />,
      },
      {
        key: "End Date",
        value: <Skeleton width="30%" />,
      },
      {
        key: "Duration",
        value: <Skeleton width="20%" />,
      },
      {
        key: "Distance",
        value: <Skeleton width="10%" />,
      },
    ];
  } else {
    const groupName = find(groupsQuery.data ?? [], { id: log.groupId })?.name;

    items = [
      {
        key: "ID",
        value: log.id,
      },
      {
        key: "Status",
        value: startCase(log.status),
      },
      {
        key: "Group",
        value: groupName === undefined ? <Skeleton width="6ch" /> : groupName,
      },
      {
        key: "Start Date",
        value: formattedDateString(log.startDate) ?? "-",
      },
      {
        key: "End Date",
        value: formattedDateString(log.endDate) ?? "-",
      },
      {
        key: "Duration",
        value:
          log.duration != null
            ? formatTimestamp(log.duration, {
                keepDecimalsOnWholeSeconds: false,
                secondsDecimalDigits: 0,
              })
            : "-",
      },
      {
        key: "Distance",
        value:
          log.distance != null
            ? logDistanceFormatter.format(log.distance)
            : "-",
      },
    ];
  }

  return (
    <Card elevation={4}>
      <DividedCardHeader
        height="2rem"
        title="Summary"
        titleTypographyProps={{
          variant: "h6",
          component: "h2",
        }}
      />
      <CardContent>
        <DescriptionList items={items} layout="stacked" />
      </CardContent>
    </Card>
  );
}
